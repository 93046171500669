import { GoogleMapsView } from "@/components/GoogleMapsView";
import { MultiSelect } from "@/components/MultiSelect";
import { PhoneInput } from "@/components/PhoneInput";
import { SearchAddress } from "@/components/SearchAddress";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datePicker";
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { FormInput } from "@/components/ui/form-input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch.tsx";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { buyerType } from "@/constants/buyerType";
import { languages } from "@/constants/languages";
import { extractAddressComponents } from "@/lib/countriesHelpers";
import { CreatePropertyType } from "@/schemas/createPropertySchema";
import { ProductTypeType } from "@/schemas/productTypeSchema";
import { ProjectDetails } from "@/schemas/projectDetailsSchema";
import { parseISO } from "date-fns";
import { ChevronDown, ChevronUp, Info, PlusIcon, Trash } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSection } from "./FormSection";

interface CreatePropertyFormProps {
    ActiveProjects: ProjectDetails[];
    homeType: ProductTypeType[] | undefined;
    isEditing?: boolean;
}

export const PropertyForm = ({
    ActiveProjects,
    homeType,
    isEditing = false,
}: CreatePropertyFormProps) => {
    const { t } = useTranslation("property");
    const { control, setValue, getValues, watch } =
        useFormContext<CreatePropertyType>();

    const {
        fields: additionalUsers,
        append,
        remove,
        update,
    } = useFieldArray({
        control,
        name: "additional_users",
    });

    const [isManualEntry, setIsManualEntry] = useState(false);

    const handleManualAddressClick = () => {
        const manualAddress = !isManualEntry;
        setIsManualEntry(manualAddress);
        setValue("user_properties.0.property.is_manual_address", manualAddress);
    };

    const asterisk = isEditing ? " *" : "";

    return (
        <div className="flex flex-col gap-20">
            <FormSection
                title={t("SectionTitleFirst")}
                description={t("SectionDescriptionFirst")}
            >
                <div className="flex flex-col gap-5">
                    <div className="flex gap-5 w-full justify-between">
                        <FormInput
                            control={control}
                            name="user.first_name"
                            label={t("FirstName") + asterisk}
                            placeholder={t("FirstName")}
                        />
                        <FormInput
                            required={isEditing}
                            control={control}
                            name="user.last_name"
                            label={t("LastName") + asterisk}
                            placeholder={t("LastName")}
                        />
                    </div>
                    <FormInput
                        control={control}
                        name="user.alias_name"
                        label={t("AliasName")}
                        placeholder={t("AliasName")}
                    />
                    <FormInput
                        control={control}
                        name="user.customer_id"
                        label={t("CustomerId") + asterisk}
                        placeholder={t("CustomerId")}
                    />
                    <FormInput
                        control={control}
                        name="user.email"
                        label={t("Email")}
                        placeholder={t("Email")}
                    />
                    <FormField
                        control={control}
                        name="user.phone_number"
                        render={({ field, fieldState: { error } }) => (
                            <FormItem>
                                <FormLabel>
                                    {t("PrimaryPhoneNumber") + asterisk}
                                </FormLabel>
                                <FormControl>
                                    <PhoneInput
                                        className="w-full"
                                        isError={!!error}
                                        value={field.value}
                                        defaultValue={field.value}
                                        defaultCountry="CA"
                                        onChange={({ nativeEvent }) => {
                                            const target =
                                                nativeEvent.target as HTMLInputElement;
                                            setValue(
                                                "user.phone_number",
                                                target.value
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="col-span-1 md:col-span-2">
                        {additionalUsers.map(function (user, index) {
                            // if user is not yet created
                            const canDelete = !user.additional_user_id;
                            // if user is already created
                            const canEdit = !!user.additional_user_id;
                            const canToggleEnabled = !!user.additional_user_id;
                            function toggleEdit() {
                                update(index, {
                                    ...user,
                                    edited: !user.edited,
                                });
                            }
                            return (
                                <div
                                    className="border p-4 rounded-md mb-4 w-full"
                                    key={index}
                                >
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-row items-center mb-4">
                                            <h3 className="text-lg font-bold inline mb-0 mr-4">{`${t("CoBuyer")} ${index + 1}`}</h3>
                                        </div>
                                        {canEdit && (
                                            <div
                                                className="border rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer"
                                                onClick={toggleEdit}
                                            >
                                                {user.edited ? (
                                                    <ChevronUp />
                                                ) : (
                                                    <ChevronDown />
                                                )}
                                            </div>
                                        )}
                                        {canDelete && (
                                            <Trash
                                                className="text-secondary hover:text-secondary/80 cursor-pointer"
                                                onClick={() => remove(index)}
                                            />
                                        )}
                                    </div>
                                    {canToggleEnabled && (
                                        <div className="flex items-center gap-2 mb-4">
                                            <FormField
                                                name={`additional_users.${index}.enabled`}
                                                render={({ field }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                    ></Switch>
                                                )}
                                            />
                                            <span className="font-bold">
                                                {t("AllowPropertyAccess")}
                                            </span>
                                        </div>
                                    )}
                                    <div className="flex flex-row">
                                        {user.edited ? (
                                            <div className="flex flex-col gap-5 w-full">
                                                <div className="flex gap-6 w-full justify-between">
                                                    <FormInput
                                                        control={control}
                                                        name={`additional_users.${index}.first_name`}
                                                        label={t("FirstName")}
                                                        placeholder={t(
                                                            "FirstName"
                                                        )}
                                                    />
                                                    <FormInput
                                                        control={control}
                                                        name={`additional_users.${index}.last_name`}
                                                        label={t("LastName")}
                                                        placeholder={t(
                                                            "LastName"
                                                        )}
                                                    />
                                                </div>
                                                <FormInput
                                                    control={control}
                                                    name={`additional_users.${index}.alias_name`}
                                                    label={t("AliasName")}
                                                    placeholder={t("AliasName")}
                                                />
                                                <FormInput
                                                    control={control}
                                                    name={`additional_users.${index}.email`}
                                                    label={t("Email")}
                                                    placeholder={t("Email")}
                                                />
                                                <FormField
                                                    control={control}
                                                    name={`additional_users.${index}.phone_number`}
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormLabel>
                                                                {t(
                                                                    "PhoneNumber"
                                                                )}
                                                            </FormLabel>
                                                            <FormControl>
                                                                <PhoneInput
                                                                    {...field}
                                                                    placeholder="test"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex flex-col grow">
                                                <div>
                                                    {user.first_name}{" "}
                                                    {user.last_name}{" "}
                                                    {user.alias_name
                                                        ? `(${user.alias_name})`
                                                        : ""}
                                                </div>
                                                <div className="grow">
                                                    {user.email}
                                                </div>
                                                <div>{user.phone_number}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        onClick={() =>
                            append({
                                enabled: true,
                                edited: true,
                                first_name: "",
                                last_name: "",
                                alias_name: "",
                                email: "",
                                phone_number: "",
                            })
                        }
                        className="border-2 border-dashed border-grey-text/30 p-4 rounded-md flex justify-center space-x-3 items-center hover:bg-gray-100 hover:cursor-pointer"
                    >
                        <span className="bg-secondary text-white rounded-full p-2">
                            <PlusIcon className="h-4 w-4" />
                        </span>
                        <span>{t("AddCoBuyer")}</span>
                    </div>
                </div>
            </FormSection>

            <FormSection
                title={t("SectionTitleSecond")}
                description={t("SectionDescriptionSecond")}
            >
                <div className="flex flex-col gap-5">
                    <FormField
                        control={control}
                        name="user.languages"
                        render={({ field }) => {
                            const selectedLanguages =
                                field.value?.map((language) => ({
                                    label: language,
                                    value: language,
                                })) ?? [];
                            return (
                                <FormItem>
                                    <FormLabel>{t("Languages")}</FormLabel>
                                    <FormControl>
                                        <MultiSelect
                                            options={languages.map(
                                                (language) => ({
                                                    label: language,
                                                    value: language,
                                                })
                                            )}
                                            defaultValue={selectedLanguages.map(
                                                (language) => language.value
                                            )}
                                            onValueChange={(newValue) => {
                                                field.onChange(
                                                    newValue.map(
                                                        (option) => option
                                                    )
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={control}
                        name="user.preferred_languages"
                        render={({ field }) => {
                            const selectedLanguages =
                                field.value?.map((language) => ({
                                    label: language,
                                    value: language,
                                })) ?? [];

                            return (
                                <FormItem>
                                    <FormLabel>
                                        {t("PreferredLanguages")}
                                    </FormLabel>
                                    <FormControl>
                                        <MultiSelect
                                            onValueChange={(newValue) => {
                                                field.onChange(
                                                    newValue.map(
                                                        (option) => option
                                                    )
                                                );
                                            }}
                                            defaultValue={
                                                selectedLanguages.map(
                                                    (option) => option.value
                                                ) ?? []
                                            }
                                            options={languages.map(
                                                (language) => ({
                                                    label: language,
                                                    value: language,
                                                })
                                            )}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />

                    <div className="flex gap-6 w-full">
                        <FormInput
                            control={control}
                            type="number"
                            min={0}
                            name="user.no_of_adults"
                            label={t("NumberOfAdults")}
                            placeholder={t("NumberOfAdults")}
                        />
                        <FormInput
                            control={control}
                            type="number"
                            min={0}
                            name="user.no_of_children"
                            label={t("NumberOfChildren")}
                            placeholder={t("NumberOfChildren")}
                        />
                    </div>
                </div>
            </FormSection>

            <FormSection
                title={t("SectionTitleThird")}
                description={t("SectionDescriptionThird")}
            >
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-5 items-center">
                    <FormField
                        control={control}
                        name="user.project"
                        render={({ field, fieldState: { error } }) => {
                            return (
                                <FormItem>
                                    <FormLabel>{t("Project")}</FormLabel>
                                    <FormControl>
                                        <Select
                                            onValueChange={(value) => {
                                                field.onChange(value);
                                                setValue(
                                                    "user_properties.0.property.project_id",
                                                    Number(value)
                                                );
                                            }}
                                            value={field.value}
                                            defaultValue={field.value}
                                        >
                                            <SelectTrigger isError={!!error}>
                                                {field.value ? (
                                                    <SelectValue />
                                                ) : (
                                                    <span className="text-grey-text/30">
                                                        Select a project
                                                    </span>
                                                )}
                                            </SelectTrigger>
                                            <SelectContent>
                                                {ActiveProjects?.map(
                                                    (project: {
                                                        id: number;
                                                        name: string;
                                                    }) => (
                                                        <SelectItem
                                                            key={project.id}
                                                            value={project.id.toString()}
                                                        >
                                                            {project.name}
                                                        </SelectItem>
                                                    )
                                                )}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={control}
                        name="user.product_type"
                        render={({ field, fieldState: { error } }) => (
                            <FormItem>
                                <FormLabel>{t("ProductType")}</FormLabel>
                                <FormControl>
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                            setValue(
                                                "user_properties.0.property.product_type_id",
                                                Number(value)
                                            );
                                        }}
                                        value={field.value}
                                        defaultValue={field.value}
                                    >
                                        <SelectTrigger isError={!!error}>
                                            {field.value ? (
                                                <SelectValue />
                                            ) : (
                                                <span className="text-grey-text/30">
                                                    Select a product type
                                                </span>
                                            )}
                                        </SelectTrigger>
                                        <SelectContent>
                                            {homeType?.length === 0 && (
                                                <SelectItem value="0">
                                                    Select a project first
                                                </SelectItem>
                                            )}
                                            {homeType?.map(
                                                ({ id, product_type }) => (
                                                    <SelectItem
                                                        key={id}
                                                        value={id.toString()}
                                                    >
                                                        {product_type}
                                                    </SelectItem>
                                                )
                                            )}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="user.buyer_type"
                        render={({ field, fieldState: { error } }) => (
                            <FormItem>
                                <FormLabel>
                                    {t("BuyerType") + asterisk}
                                </FormLabel>
                                <FormControl>
                                    <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value || undefined}
                                        value={field.value || undefined}
                                    >
                                        <SelectTrigger isError={!!error}>
                                            {field.value ? (
                                                <SelectValue />
                                            ) : (
                                                <span className="text-grey-text/30">
                                                    Select a buyer type
                                                </span>
                                            )}
                                        </SelectTrigger>
                                        <SelectContent>
                                            {buyerType.map((buyerType) => (
                                                <SelectItem
                                                    key={buyerType}
                                                    value={buyerType}
                                                >
                                                    {buyerType}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormInput
                        control={control}
                        name="user.community"
                        label={t("Community")}
                        placeholder={t("Community")}
                    />

                    <FormField
                        control={control}
                        name="user_properties.0.key_dates.possession_date"
                        render={({ field }) => {
                            const parsedDate =
                                typeof field.value === "string"
                                    ? parseISO(field.value)
                                    : field.value;
                            const localDate = parsedDate
                                ? new Date(
                                      parsedDate.getTime() +
                                          parsedDate.getTimezoneOffset() * 60000
                                  )
                                : undefined;
                            return (
                                <FormItem>
                                    <FormLabel>{t("PossessionDate")}</FormLabel>
                                    <FormControl>
                                        <DatePicker
                                            date={localDate || undefined}
                                            setDate={(date) =>
                                                field.onChange(
                                                    date ?? undefined
                                                )
                                            }
                                            className="w-full"
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormDescription>
                        {t("PossessionDateDescription")}
                    </FormDescription>
                </div>
            </FormSection>

            <FormSection
                title={t("SectionTitleFourth")}
                description={t("SectionDescriptionFourth")}
            >
                <div className="grid grid-cols-1 gap-5  md:gap-5">
                    <FormField
                        control={control}
                        name="user_properties.0.property.place_details"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <div>
                                        <div className="flex">
                                            <FormLabel>
                                                {t("Address")}
                                            </FormLabel>
                                            <TooltipProvider>
                                                <Tooltip delayDuration={0}>
                                                    <TooltipTrigger asChild>
                                                        <span>
                                                            <Info className="text-grey h-4" />
                                                        </span>
                                                    </TooltipTrigger>
                                                    <TooltipContent side="bottom">
                                                        <p>
                                                            {t(
                                                                "ManualAddressDescriptionTooltip"
                                                            )
                                                                .split("\n")
                                                                .map(
                                                                    (
                                                                        line,
                                                                        index
                                                                    ) => (
                                                                        <p
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                line
                                                                            }
                                                                        </p>
                                                                    )
                                                                )}
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                        <div className="text-xs float-right flex">
                                            <Button
                                                type="button"
                                                size={"xs"}
                                                variant="default"
                                                className="text-xs -mt-7 mb-2"
                                                onClick={
                                                    handleManualAddressClick
                                                }
                                            >
                                                {isManualEntry
                                                    ? t("SearchAddress")
                                                    : t("EnterAddressManually")}
                                            </Button>
                                        </div>
                                    </div>
                                    <FormControl>
                                        <>
                                            <SearchAddress
                                                initialValue={getValues(
                                                    "user_properties.0.property.formatted_address"
                                                )}
                                                onSelectLocation={(
                                                    position,
                                                    _,
                                                    places
                                                ) => {
                                                    if (position) {
                                                        setValue(
                                                            "user_properties.0.property.place_details.latitude",
                                                            position.lat
                                                        );
                                                        setValue(
                                                            "user_properties.0.property.place_details.longitude",
                                                            position.lng
                                                        );
                                                    }

                                                    if (places) {
                                                        const addressComponents =
                                                            extractAddressComponents(
                                                                places
                                                            );

                                                        if (
                                                            addressComponents.city
                                                        ) {
                                                            setValue(
                                                                "user_properties.0.property.place_details.city",
                                                                addressComponents.city
                                                            );
                                                        }

                                                        if (
                                                            addressComponents.country
                                                        ) {
                                                            setValue(
                                                                "user_properties.0.property.place_details.country",
                                                                addressComponents.country
                                                            );
                                                        }
                                                        if (
                                                            addressComponents.streetNumber ||
                                                            addressComponents.streetName
                                                        ) {
                                                            setValue(
                                                                "user_properties.0.property.formatted_address",
                                                                `${addressComponents.streetNumber} ${addressComponents.streetName}`
                                                            );
                                                        }
                                                        if (
                                                            addressComponents.streetNumber
                                                        ) {
                                                            setValue(
                                                                "user_properties.0.property.place_details.street_number",
                                                                addressComponents.streetNumber
                                                            );
                                                        }
                                                        if (
                                                            addressComponents.postalCode
                                                        ) {
                                                            setValue(
                                                                "user_properties.0.property.place_details.postal_code",
                                                                addressComponents.postalCode
                                                            );
                                                        }
                                                    }
                                                }}
                                                {...field}
                                            />
                                            {isManualEntry && (
                                                <FormInput
                                                    control={control}
                                                    name="user_properties.0.property.formatted_address"
                                                    placeholder={t(
                                                        "EnterManualAddress"
                                                    )}
                                                />
                                            )}
                                        </>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormInput
                        control={control}
                        name="user_properties.0.property.unit_no"
                        label={t("UnitNo")}
                        placeholder={t("UnitNoPlaceHolder")}
                    />
                    <FormInput
                        control={control}
                        name="user_properties.0.property.place_details.city"
                        label={t("City")}
                        placeholder={t("City")}
                    />
                    <FormInput
                        control={control}
                        name="user_properties.0.property.place_details.country"
                        label={t("Country")}
                        placeholder={t("Country")}
                    />
                </div>

                <div className="mt-10">
                    <FormField
                        control={control}
                        name="user_properties.0.property.formatted_address"
                        render={() => (
                            <FormItem>
                                <FormControl>
                                    <GoogleMapsView
                                        center={{
                                            lat:
                                                watch(
                                                    "user_properties.0.property.place_details.latitude"
                                                ) ?? 51.0461806,
                                            lng:
                                                watch(
                                                    "user_properties.0.property.place_details.longitude"
                                                ) ?? -114.0782319,
                                        }}
                                        markerPosition={{
                                            lat:
                                                watch(
                                                    "user_properties.0.property.place_details.latitude"
                                                ) ?? 51.0461806,
                                            lng:
                                                watch(
                                                    "user_properties.0.property.place_details.longitude"
                                                ) ?? -114.0782319,
                                        }}
                                        className="w-full h-32"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </FormSection>
        </div>
    );
};
