import {
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";

interface TimePickerProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    control: Control<any>;
    name: string;
}

export function TimePicker({ control, name }: TimePickerProps) {
    const [time, setTime] = useState<string>("05:00");

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className="flex flex-col">
                    <FormLabel>Time</FormLabel>
                    <FormControl>
                        <Select
                            defaultValue={time}
                            onValueChange={(e) => {
                                setTime(e);
                                field.onChange(e);
                            }}
                        >
                            <SelectTrigger className="font-normal focus:ring-0 w-[120px]">
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <ScrollArea className="h-[15rem]">
                                    {Array.from({ length: 96 }).map((_, i) => {
                                        const hour = Math.floor(i / 4)
                                            .toString()
                                            .padStart(2, "0");
                                        const minute = ((i % 4) * 15)
                                            .toString()
                                            .padStart(2, "0");
                                        return (
                                            <SelectItem
                                                key={i}
                                                value={`${hour}:${minute}`}
                                            >
                                                {hour}:{minute}
                                            </SelectItem>
                                        );
                                    })}
                                </ScrollArea>
                            </SelectContent>
                        </Select>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}
