import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

interface UpdateClientHcBody {
    hc_id: number;
    user_ids: number[];
}

export const useUpdateClientHc = () => {
    const { toast } = useToast();
    const { t } = useTranslation("hooks");

    return useMutation({
        mutationFn: async (body: UpdateClientHcBody) => {
            const { data } = await api.post("v1/users/change_hc", body);
            return data;
        },
        onSuccess() {
            toast({ title: t("Success.Update.ClientHc"), variant: "success" });
        },
        onError(error) {
            toast({
                title: t("Error.Update.ClientHc"),
                description: error.message,
                variant: "destructive",
            });
        },

        retry: false,
    });
};
