export const HOME_TRANSITION_CATEGORIES = [
    { value: "1", label: "Move-In" },
    { value: "2", label: "Move-Out" },
    { value: "3", label: "Home-Services" },
    { value: "4", label: "Buy" },
    { value: "5", label: "Sell" },
    { value: "6", label: "Conditional" },
    { value: "7", label: "Unconditional" },
    { value: "8", label: "Sale" },
    { value: "9", label: "Home Concierge" },
];
