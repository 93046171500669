import { ProductTypeType } from "@/schemas/productTypeSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchProjectTypeProps {
    projectId: number[];
}

export const useFetchProjectType = ({ projectId }: FetchProjectTypeProps) => {
    return useQuery({
        queryKey: ["projectType", projectId],
        queryFn: async () => {
            const results = await Promise.all(
                projectId.map(async (id) => {
                    const { data } = await api.get<ProductTypeType[]>(
                        "v1/projects/product_types",
                        {
                            params: {
                                project_id: id,
                            },
                        }
                    );
                    return data;
                })
            );
            return results.flat();
        },
        enabled: !!projectId && projectId.length > 0,
    });
};
