import { dealsArraySchemaType } from "@/schemas/dealSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchDeals = () => {
    return useQuery({
        queryKey: ["deals-categories"],
        queryFn: async () => {
            const { data } =
                await api.get<dealsArraySchemaType>("v1/deal_categories");
            return data;
        },
    });
};
