import z from "zod";

export const ProductTypesSchema = z.object({
    id: z.number().int(),
    productType: z.string().optional(),
    createdAt: z.coerce.date().optional(),
});

export const ProductTypesCreateSchema = ProductTypesSchema.omit({
    id: true,
});

export type CreateProductTypes = z.infer<typeof ProductTypesCreateSchema>;
