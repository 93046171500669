import { Switch } from "@/components/ui/switch";
import { useUpdateStaffRotation } from "@/hooks/staff/useUpdateStaffRotation";

import { useState } from "react";

interface StaffRotationToggleProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    user_id: number;
    initial: boolean;
    staffType: "me" | "csr";
    disabled?: boolean;
    className?: string;
}

export const StaffRotationToggle = ({
    user_id,
    initial,
    staffType,
    disabled,
    ...rest
}: StaffRotationToggleProps) => {
    const { mutateAsync } = useUpdateStaffRotation();

    const [value, setValue] = useState(initial);

    return (
        <Switch
            checked={value}
            onClick={async () => {
                await mutateAsync({
                    user_id: user_id,
                    include_in_rotation: !initial,
                    type_of_user: staffType,
                });

                setValue(!value);
            }}
            disabled={disabled}
            {...rest}
        />
    );
};
