import i18n from "i18next";
import { z } from "zod";

export const forgotPasswordSchema = z.object({
    email: z.string().email({
        message: i18n.t("general:Password.Email"),
    }),
    password: z
        .string()
        .min(2, { message: i18n.t("general:Password.PasswordRequired") }),
    confirm_password: z
        .string()
        .min(2, { message: i18n.t("general:Password.PasswordRequired") }),
    reset_token: z
        .string()
        .min(2, { message: i18n.t("general:Password.PasswordRequired") }),
});

export type ForgotPasswordSchemaType = z.infer<typeof forgotPasswordSchema>;
