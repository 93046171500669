import { queryClient } from "@/lib/queryClient";
import { VirtuoLibraryResourcesSchema } from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { nestApi } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

export const useDeleteBuilderResource = () => {
    const { toast } = useToast();
    const { t } = useTranslation("hooks");

    return useMutation({
        mutationFn: async (id: number) => {
            await nestApi.delete(`resources/${id}`);

            await queryClient.cancelQueries({
                queryKey: ["resources"],
            });
            const previousResources = queryClient.getQueryData<
                VirtuoLibraryResourcesSchema[]
            >(["resources"]);

            queryClient.setQueryData(
                ["resources"],
                previousResources?.filter((resource) => resource.id !== id)
            );
            return { previousResources };
        },
        onSuccess() {
            toast({
                title: t("Success.Delete.Resource"),
                variant: "success",
                duration: 3000,
            });
        },
        onError(error) {
            toast({
                title: t("Error.Delete.Resource"),
                description: error.message,
                variant: "destructive",
                duration: 3000,
            });
        },
        retry: false,
    });
};
