import { z } from "zod";

export const hcResponseSchema = z.object({
    id: z.number(),
    hc_id: z.number(),
    role_id: z.number(),
    admin_id: z.number().nullable(),
    fullname: z.string(),
});

export type hcResponseType = z.infer<typeof hcResponseSchema>;

export const hsArraySchema = z.array(hcResponseSchema);

export type hsArraySchemaType = z.infer<typeof hsArraySchema>;
