import { AddressSchemaType } from "@/schemas/addressSchema";
import { StaffUserSchemaType } from "@/schemas/staff/staffUserSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

interface CreateStaffParams {
    user: StaffUserSchemaType;
    staff_details: {
        work_locations: AddressSchemaType[];
    };
}

export const useCreateStaff = () => {
    const { toast } = useToast();
    const { t } = useTranslation("hooks");

    return useMutation({
        mutationFn: ({ user, staff_details }: CreateStaffParams) =>
            api.post<StaffUserSchemaType>("v1/users/staff/", {
                user,
                staff_details,
            }),
        onSuccess() {
            toast({ title: t("Success.Create.Staff"), variant: "success" });
        },
        onError(error) {
            toast({
                title: t("Error.Create.Staff"),
                description: error.message,
                variant: "destructive",
            });
        },
    });
};
