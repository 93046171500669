import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

interface FormSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    title: string;
    rightContent?: React.ReactNode;
    description: string;
}
export const FormSection = ({
    children,
    title,
    description,
    rightContent,
    className,
    ...rest
}: FormSectionProps) => {
    return (
        <section
            className={cn("flex flex-col gap-5 rounded-lg", className)}
            {...rest}
        >
            <div className="flex justify-between">
                <div>
                    <h1 className="text-base font-bold ">{title}</h1>
                    <span className="text-gray-500 text-sm">{description}</span>
                </div>
                {rightContent && (
                    <div className="flex items-center justify-end">
                        {rightContent}
                    </div>
                )}
            </div>
            <Separator />
            <div>{children}</div>
        </section>
    );
};
