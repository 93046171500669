import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { useFetchBuilderAccountDetails } from "@/hooks/builder/useFetchBuilderAccountDetails";
import { useAuth } from "@/hooks/login/useAuth";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getBuilderUserTableColumns } from "./BuilderUserTableColumn";

export const UserTable = () => {
    const { t: ut } = useTranslation("users");
    const { user } = useAuth();
    const { data, isLoading } = useFetchBuilderAccountDetails({
        builder_account_id: user.builder_accounts[0].id,
    });

    const primaryUserId = data ? data.paginatedUsers[0].id : null;
    const columns = getBuilderUserTableColumns(primaryUserId);
    const { table } = useDataTable({
        data: data?.paginatedUsers ?? [],
        columns,
        totalItems: data?.totalItems ?? 0,
    });
    const navigate = useNavigate();

    return (
        <div className="space-y-5">
            <DataTableToolbar table={table}>
                <Button
                    className="flex flex-row gap-1"
                    onClick={() => {
                        navigate("create");
                    }}
                >
                    <CirclePlus color="white" size={18} />
                    {ut("Builder.AddUser")}
                </Button>
            </DataTableToolbar>
            {isLoading ? (
                <DataTableSkeleton
                    columnCount={6}
                    cellWidths={[
                        "16rem",
                        "11rem",
                        "8rem",
                        "8rem",
                        "8rem",
                        "8rem",
                    ]}
                    shrinkZero
                />
            ) : (
                <DataTable table={table} />
            )}
        </div>
    );
};
