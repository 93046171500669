import { Switch } from "@/components/ui/switch";
import { useUpdateStaffRestriction } from "@/hooks/staff/useUpdateStaffRestriction";
import React, { useState } from "react";

interface StaffRestrictToggleProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    hc_id: string | number;
    initial: boolean;
}

export const StaffRestrictToggle = ({
    hc_id,
    initial,
    ...rest
}: StaffRestrictToggleProps) => {
    const [value, setValue] = useState(initial);

    const { mutateAsync } = useUpdateStaffRestriction();

    return (
        <Switch
            checked={value}
            onCheckedChange={async () => {
                await mutateAsync({
                    hc_id,
                });
                setValue(!value);
            }}
            {...rest}
        />
    );
};
