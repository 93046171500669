import { getAccountRoutes } from "@/routes/accountRoutes";
import { getCustomerRoutes } from "@/routes/customerRoutes";
import { getStaffRoutes } from "@/routes/staffRoutes";
import { RouteObject } from "react-router-dom";
import { accountMenuList } from "./menuList/accountMenuList";
import { customerMenuList } from "./menuList/customerMenuList";
import { MenuGroup } from "./menuList/menuList";
import { staffMenuList } from "./menuList/staffMenuList";

export enum AppUserType {
    Staff = "Staff",
    Customer = "Customer",
    Builder = "Builder",
}

type AppUserSpec = {
    menuFunction: (pathname: string) => MenuGroup[];
    routesFunction: () => RouteObject[];
};

export const AppUserSpecs: Record<AppUserType, AppUserSpec> = {
    [AppUserType.Staff]: {
        menuFunction: staffMenuList,
        routesFunction: getStaffRoutes,
    },
    [AppUserType.Customer]: {
        menuFunction: customerMenuList,
        routesFunction: getCustomerRoutes,
    },
    [AppUserType.Builder]: {
        menuFunction: accountMenuList,
        routesFunction: getAccountRoutes,
    },
};
