import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

export interface Option {
    label: string;
    value: string;
}

export interface ComboboxProps extends React.HTMLAttributes<HTMLButtonElement> {
    value: string;
    setValue: (value: string) => void;
    options?: Option[];
    placeholder?: string;
    searchable?: boolean;
    className?: string;
}

export function Combobox({
    value,
    setValue,
    options,
    placeholder,
    searchable = true,
    className,
    ...rest
}: ComboboxProps) {
    const [open, setOpen] = React.useState(false);
    const [input, setInput] = React.useState("");

    let filteredOptions: Option[] = options ? options : [];

    React.useEffect(() => {
        if (options && options.length > 0) {
            filteredOptions = options.filter(
                (option) =>
                    option.label?.toLowerCase().includes(input.toLowerCase()) ||
                    option.value?.toLowerCase().includes(input.toLowerCase())
            );
        }
    }, [options]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn("flex w-full justify-between", className)}
                    {...rest}
                >
                    {value
                        ? options?.find((option) => option.value === value)
                              ?.label
                        : `${placeholder ? placeholder : "Select an option"}`}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0">
                <Command
                    filter={(value, search) => {
                        if (!options) return 0;
                        const option = options.find(
                            (option) => option.value === value
                        );
                        if (option) {
                            return option.label
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                                option.value
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                ? 1
                                : 0;
                        }
                        return 0;
                    }}
                >
                    {searchable && (
                        <CommandInput
                            placeholder="Search ..."
                            value={input}
                            onValueChange={setInput}
                        />
                    )}
                    <CommandList>
                        <CommandEmpty>No option found.</CommandEmpty>
                        <CommandGroup>
                            {filteredOptions?.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={(currentValue) => {
                                        setValue(
                                            currentValue === value
                                                ? ""
                                                : currentValue
                                        );
                                        setOpen(false);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            value === option.value
                                                ? "opacity-100"
                                                : "opacity-0"
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
