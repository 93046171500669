import {
    DropdownMenu,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAuth } from "@/hooks/login/useAuth";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserAvatar } from "../ui/avatar";
import { Button } from "../ui/button";
import { CustomerNavDropdown } from "./CustomerNavDropdown";
import { UserNavDropdown } from "./UserNavDropdown";

export function UserNav() {
    const { t } = useTranslation("general");
    const { user, handleLogout, isCustomer } = useAuth();
    const navigate = useNavigate();

    return (
        <DropdownMenu>
            <TooltipProvider disableHoverableContent>
                <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant={isCustomer ? "outlineNew" : "outline"}
                                className={cn(
                                    "relative rounded-full",
                                    isCustomer ? "h-14 w-14" : "h-11 w-11"
                                )}
                            >
                                <UserAvatar
                                    firstName={user?.first_name ?? ""}
                                    lastName={user?.last_name ?? ""}
                                    isCustomer={isCustomer}
                                />
                            </Button>
                        </DropdownMenuTrigger>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">
                        {t("Navbar.Profile")}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            {isCustomer ? (
                <CustomerNavDropdown
                    navigate={navigate}
                    handleLogout={handleLogout}
                    user={user}
                />
            ) : (
                <UserNavDropdown
                    navigate={navigate}
                    handleLogout={handleLogout}
                    user={user}
                />
            )}
        </DropdownMenu>
    );
}
