import { MultiSelect } from "@/components/MultiSelect";

import Editor from "@/components/RichText/Editor";
import { SearchAddress } from "@/components/SearchAddress";
import { DatePicker } from "@/components/ui/datePicker";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { FileWithPreview, ImageCropper } from "@/components/ui/image-cropper";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { imageFileType } from "@/constants/acceptFileType";
import { useFetchProjectType } from "@/hooks/builder/useFetchProjectType";
import { useFetchProjectCommunities } from "@/hooks/useFetchProjectCommunities";
import { extractAddressComponents } from "@/lib/countriesHelpers";
import {
    CreateCampaignSchema,
    campaignType,
} from "@/schemas/builders/campaignSchema";
import { dealsArraySchemaType } from "@/schemas/dealSchema";
import { ProjectDetailsArray } from "@/schemas/projectDetailsSchema";
import { Loader, UploadIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
interface CampaignFormProps {
    deals?: dealsArraySchemaType;
    projects?: ProjectDetailsArray;
}

interface Option {
    label: string;
    value: string;
}

export const CampaignForm = ({ deals, projects }: CampaignFormProps) => {
    const { t } = useTranslation("campaign");
    const { control, setValue, getValues, watch } =
        useFormContext<CreateCampaignSchema>();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(
        null
    );
    const projectIds = watch("campaign_project_preference_ids");

    const { data: projectCommunities, isLoading: isProjectCommunitiesLoading } =
        useFetchProjectCommunities({ id: projectIds ?? [] });
    const { data: projectType, isLoading: isProjectTypeLoading } =
        useFetchProjectType({ projectId: projectIds ?? [] });

    const handleCropComplete = (croppedFile: File) => {
        setValue("files", [croppedFile]);
    };

    const formData = useWatch({ control });

    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        const file = acceptedFiles[0];
        if (!file) {
            alert("Selected image is too large!");
            return;
        }

        const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });

        setSelectedFile(fileWithPreview);
        setDialogOpen(true);
    }, []);

    const { getRootProps } = useDropzone({
        onDrop,
        accept: imageFileType,
    });

    return (
        <>
            <div className="col-span-1 md:col-span-2">
                <FormField
                    control={control}
                    name="title"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("Title")}</FormLabel>
                            <FormControl>
                                <Input type="text" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-1 md:col-span-2">
                <div>
                    <FormField
                        control={control}
                        name="date"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t("StartDate")}</FormLabel>
                                <FormControl>
                                    <DatePicker
                                        date={
                                            field.value
                                                ? field.value
                                                : undefined
                                        }
                                        setDate={field.onChange}
                                        className="w-full"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div>
                    <FormField
                        control={control}
                        name="until_date"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t("EndDate")}</FormLabel>
                                <FormControl>
                                    <DatePicker
                                        date={
                                            field.value
                                                ? field.value
                                                : undefined
                                        }
                                        setDate={field.onChange}
                                        className="w-full"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>

            <div className="col-span-1 md:col-span-2">
                <FormField
                    control={control}
                    name="url"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("Url")}</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="col-span-1 md:col-span-2">
                <FormField
                    control={control}
                    name="details"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("Description")}</FormLabel>
                            <FormControl>
                                <Editor
                                    content={field.value}
                                    className="w-full"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <hr className="col-span-1 md:col-span-2 my-6" />

            <div className="col-span-1 md:col-span-2 rounded-md border border-dashed p-3">
                <FormField
                    control={control}
                    name="files"
                    render={() => (
                        <FormItem>
                            <FormControl>
                                <div
                                    className="w-full p-10 rounded-lg flex flex-col"
                                    {...getRootProps()}
                                >
                                    <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                                        <div className="rounded-full border border-dashed p-3">
                                            <UploadIcon
                                                className="size-7 text-muted-foreground"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-px">
                                            <p className="font-medium text-muted-foreground">
                                                {t("UploadFile")}
                                            </p>
                                            <p className="text-sm text-muted-foreground/70">
                                                {t("UploadFileDescription")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <hr className="col-span-1 md:col-span-2 my-6" />

            <div className="col-span-1">
                <FormField
                    control={control}
                    name="deal_type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("CampaignType")}</FormLabel>
                            <FormControl>
                                <Select
                                    onValueChange={(value: campaignType) => {
                                        field.onChange(value);
                                        setValue("deal_type", value);
                                    }}
                                    value={field.value}
                                    defaultValue={field.value}
                                >
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder={t("SelectProject")}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {campaignType.options?.map(
                                            (option, index) => (
                                                <SelectItem
                                                    key={index}
                                                    value={option}
                                                >
                                                    {option}
                                                </SelectItem>
                                            )
                                        )}
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className="col-span-1">
                <FormField
                    control={control}
                    name="deal_category_id"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("DealCategory")}</FormLabel>
                            <FormControl>
                                <Select
                                    onValueChange={(value) => {
                                        field.onChange(value);
                                    }}
                                    value={
                                        field.value
                                            ? field.value.toString()
                                            : ""
                                    }
                                    defaultValue={
                                        field.value
                                            ? field.value.toString()
                                            : ""
                                    }
                                >
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder={t(
                                                "SelectDealCategory"
                                            )}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {deals?.map((option, index) => (
                                            <SelectItem
                                                key={index}
                                                value={option.id.toString()}
                                            >
                                                {option.category}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            {projects && projects.length > 0 && (
                <div className="col-span-1 md:col-span-2">
                    <FormField
                        control={control}
                        name="campaign_project_preference_ids"
                        render={({ field }) => {
                            const selectedProjects =
                                field.value?.reduce(
                                    (accumulator: Option[], projectId) => {
                                        const project = projects.find(
                                            (project) =>
                                                project.id.toString() ===
                                                projectId.toString()
                                        );
                                        if (project) {
                                            accumulator.push({
                                                value: project.id.toString(),
                                                label: project.name,
                                            });
                                        }
                                        return accumulator;
                                    },
                                    []
                                ) ?? [];

                            return (
                                <FormItem>
                                    <FormLabel>
                                        {t("ProjectSelection")}
                                    </FormLabel>
                                    <FormControl>
                                        <MultiSelect
                                            options={projects.map(
                                                (project) => ({
                                                    label: project.name,
                                                    value: project.id.toString(),
                                                })
                                            )}
                                            value={selectedProjects.map(
                                                (option) => option.value
                                            )}
                                            onValueChange={(value) => {
                                                field.onChange(
                                                    value.map(
                                                        (option) => option
                                                    )
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                </div>
            )}

            {projectIds && projectIds?.length > 0 && (
                <>
                    {isProjectCommunitiesLoading || isProjectTypeLoading ? (
                        <div className="col-span-1 md:col-span-2 flex items-center justify-center mt-10">
                            <Loader className="animate-spin h-5 w-5" />
                        </div>
                    ) : (
                        <>
                            {projectCommunities && (
                                <div className="col-span-1 md:col-span-2">
                                    <FormField
                                        control={control}
                                        name="campaign_project_community_ids"
                                        render={({ field }) => {
                                            const selectedCommunities =
                                                field.value?.reduce(
                                                    (
                                                        accumulator: Option[],
                                                        communityId
                                                    ) => {
                                                        const community =
                                                            projectCommunities.find(
                                                                (project) =>
                                                                    project.id.toString() ===
                                                                    communityId.toString()
                                                            );
                                                        if (community) {
                                                            accumulator.push({
                                                                value: community.id.toString(),
                                                                label: community.community,
                                                            });
                                                        }
                                                        return accumulator;
                                                    },
                                                    []
                                                ) ?? [];

                                            return (
                                                <FormItem>
                                                    <FormLabel>
                                                        {t("Communities")}
                                                    </FormLabel>
                                                    <FormControl>
                                                        <MultiSelect
                                                            options={projectCommunities.map(
                                                                (project) => ({
                                                                    label: project.community,
                                                                    value: project.id.toString(),
                                                                })
                                                            )}
                                                            value={selectedCommunities.map(
                                                                (option) =>
                                                                    option.value
                                                            )}
                                                            onValueChange={(
                                                                newValue
                                                            ) => {
                                                                field.onChange(
                                                                    newValue.map(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            );
                                        }}
                                    />
                                </div>
                            )}

                            {projectType && (
                                <div className="col-span-1 md:col-span-2">
                                    <FormField
                                        control={control}
                                        name="campaign_product_types_ids"
                                        render={({ field }) => {
                                            const selectedProductTypes =
                                                field.value?.reduce(
                                                    (
                                                        accumulator: Option[],
                                                        productTypeId
                                                    ) => {
                                                        const productType =
                                                            projectType.find(
                                                                (type) =>
                                                                    type.id.toString() ===
                                                                    productTypeId?.toString()
                                                            );
                                                        if (productType) {
                                                            accumulator.push({
                                                                value: productType.id.toString(),
                                                                label: productType.product_type,
                                                            });
                                                        }
                                                        return accumulator;
                                                    },
                                                    []
                                                ) ?? [];

                                            return (
                                                <FormItem>
                                                    <FormLabel>
                                                        {t("ProductTypes")}
                                                    </FormLabel>
                                                    <FormControl>
                                                        <MultiSelect
                                                            options={projectType.map(
                                                                (project) => ({
                                                                    label: project.product_type,
                                                                    value: project.id.toString(),
                                                                })
                                                            )}
                                                            defaultValue={selectedProductTypes.map(
                                                                (option) =>
                                                                    option.value
                                                            )}
                                                            onValueChange={(
                                                                newValue
                                                            ) => {
                                                                field.onChange(
                                                                    newValue.map(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            {formData.deal_type === "Event" && (
                <>
                    <FormField
                        control={control}
                        name="campaign_locations.country"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>{t("Country")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={t("Country")}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={control}
                        name="campaign_locations"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t("Address")}</FormLabel>
                                <FormControl>
                                    <SearchAddress
                                        initialValue={
                                            getValues(
                                                "campaign_locations.route"
                                            ) || undefined
                                        }
                                        onSelectLocation={(
                                            position,
                                            _,
                                            places
                                        ) => {
                                            if (position) {
                                                setValue(
                                                    "campaign_locations.latitude",
                                                    position.lat
                                                );
                                                setValue(
                                                    "campaign_locations.longitude",
                                                    position.lng
                                                );
                                            }

                                            if (places) {
                                                const addressComponents =
                                                    extractAddressComponents(
                                                        places
                                                    );

                                                if (addressComponents.city) {
                                                    setValue(
                                                        "campaign_locations.city",
                                                        addressComponents.city
                                                    );
                                                }

                                                if (addressComponents.country) {
                                                    setValue(
                                                        "campaign_locations.country",
                                                        addressComponents.country
                                                    );
                                                }

                                                if (
                                                    addressComponents.streetNumber &&
                                                    addressComponents.streetName
                                                ) {
                                                    setValue(
                                                        "campaign_locations.route",
                                                        `${addressComponents.streetNumber} ${addressComponents.streetName}`
                                                    );
                                                }
                                                if (
                                                    addressComponents.streetNumber
                                                ) {
                                                    setValue(
                                                        "campaign_locations.street_number",
                                                        addressComponents.streetNumber
                                                    );
                                                }
                                                if (
                                                    addressComponents.postalCode
                                                ) {
                                                    setValue(
                                                        "campaign_locations.postal_code",
                                                        addressComponents.postalCode
                                                    );
                                                }
                                            }
                                        }}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="campaign_locations.city"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>{t("City")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={t("City")}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                </>
            )}
            <ImageCropper
                dialogOpen={isDialogOpen}
                setDialogOpen={setDialogOpen}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                onCropComplete={handleCropComplete}
            />
        </>
    );
};
