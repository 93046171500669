export interface IconProps {
    color?: string;
    className?: string;
}

// Note: please do not set both height and width in the className
// it will break the aspect ratio of the logo and go against brand guidelines

export const Logo = ({ color, className }: IconProps) => {
    return (
        <svg
            className={"flex-shrink-0 " + className}
            viewBox="0 0 314 77"
            fill={color ?? "black"}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
        >
            <path d="M85.8155 75.2463H71.3783V22.7963H85.8155V75.2463ZM86.3822 14.9331H70.9391V0H86.3822V14.9331Z" />
            <path d="M111.743 29.5542C112.877 27.1173 114.52 25.2046 116.702 23.8019C118.884 22.3993 121.519 21.7051 124.593 21.7051C129.411 21.7051 133.165 23.1644 135.871 26.083C138.577 29.0016 139.923 32.6994 139.923 37.2049C139.923 39.4859 139.654 41.6678 139.13 43.7363H125.486C125.784 42.4754 125.939 41.1577 125.939 39.7551C125.939 37.8424 125.316 36.2698 124.083 35.0513C122.851 33.8329 121.207 33.2237 119.181 33.2237C116.929 33.2237 115.129 33.9462 113.769 35.3772C112.423 36.8223 111.743 38.8625 111.743 41.5261V75.2743H97.3059V22.796H111.743V29.54V29.5542Z" />
            <path d="M154.247 57.5518V34.4296H143.196V22.8118H154.247V5.54102H168.571V22.8118H188.633V34.4296H168.571V57.5518C168.571 59.5494 169.137 61.0938 170.257 62.1989C171.39 63.304 172.849 63.8565 174.649 63.8565C178.403 63.8565 180.514 61.4196 180.968 56.5458L194.271 58.9827C193.521 64.4374 191.41 68.702 187.953 71.7623C184.496 74.8225 179.948 76.3527 174.309 76.3527C167.848 76.3527 162.889 74.6525 159.432 71.2664C155.975 67.8661 154.247 63.304 154.247 57.5376" />
            <path d="M251.539 75.2449H237.215V67.495C235.784 70.2294 233.659 72.3829 230.839 73.9697C228.02 75.5566 224.733 76.35 220.978 76.35C214.886 76.35 210.012 74.6356 206.329 71.207C202.645 67.7783 200.803 62.7345 200.803 56.1039V22.7949H215.24V52.5619C215.24 56.3306 216.09 59.2067 217.776 61.1902C219.462 63.1879 221.885 64.1797 225.044 64.1797C228.884 64.1797 231.859 62.8904 234.013 60.3118C236.152 57.7332 237.229 54.2196 237.229 49.7991V22.7949H251.553V75.2449H251.539Z" />
            <path d="M299.568 49.0215C299.568 44.6719 298.251 41.1441 295.615 38.4522C292.98 35.7602 289.679 34.4143 285.698 34.4143C281.717 34.4143 278.401 35.7602 275.78 38.4522C273.145 41.1441 271.842 44.6719 271.842 49.0215C271.842 53.3711 273.159 56.8989 275.78 59.5908C278.415 62.2827 281.717 63.6287 285.698 63.6287C289.679 63.6287 292.994 62.2827 295.615 59.5908C298.251 56.8989 299.568 53.3711 299.568 49.0215ZM261.343 63.0195C258.935 58.8541 257.73 54.1786 257.73 49.0215C257.73 43.8643 258.92 39.1039 261.286 34.9668C263.652 30.8298 266.982 27.5853 271.261 25.2334C275.539 22.8673 280.357 21.6914 285.698 21.6914C291.039 21.6914 295.842 22.8673 300.135 25.2334C304.414 27.5995 307.743 30.8439 310.109 34.9668C312.475 39.1039 313.665 43.7793 313.665 49.0215C313.665 54.2636 312.461 58.8541 310.053 63.0195C307.644 67.1849 304.329 70.4577 300.078 72.8096C295.828 75.1756 291.039 76.3516 285.698 76.3516C280.357 76.3516 275.568 75.1756 271.317 72.8096C267.067 70.4435 263.752 67.1849 261.343 63.0195Z" />
            <path d="M40.8464 75.2463L66.2071 0H50.0556L30.0078 59.4915L16.3215 22.7963H0L19.5518 75.2463H40.8464Z" />
        </svg>
    );
};
