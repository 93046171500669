import { Button } from "@/components/ui/button";
import { Calendar, CalendarProps } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { PopoverClose } from "@radix-ui/react-popover";
import { addYears, getYear, subYears } from "date-fns";
import { LoaderCircle } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface DatePickerLinkProps {
    loading?: boolean;
    date?: Date;
    setDate: (date: Date | null) => void;
    startYear?: number | Date;
    endYear?: number | Date;
    className?: string;
    clearable?: boolean;
    trigger: React.ReactNode;
    header?: React.ReactNode;
}

export const DatePickerLink = React.forwardRef<
    HTMLButtonElement,
    DatePickerLinkProps & CalendarProps
>(
    (
        {
            date,
            setDate,
            className,
            startYear = subYears(getYear(new Date()), 100),
            endYear = addYears(getYear(new Date()), 100),
            loading = false,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation("general");
        const [newDate, setNewDate] = useState(date);

        return (
            <Popover>
                <PopoverTrigger asChild className="pa-2" ref={ref}>
                    {props.trigger}
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                    {props.header}
                    <Calendar
                        mode="single"
                        captionLayout="dropdown-buttons"
                        initialFocus
                        selected={newDate}
                        onSelect={setNewDate}
                        fromYear={getYear(startYear)}
                        toYear={getYear(endYear)}
                    />
                    <div className="flex flex-row w-full items-center justify-center p-2 pt-0">
                        <PopoverClose>
                            <Button variant="ghost" size="xs">
                                {t("Close")}
                            </Button>
                        </PopoverClose>
                        {props.clearable && (
                            <Button
                                variant="ghost"
                                size="xs"
                                onClick={() => setDate(null)}
                                disabled={loading}
                            >
                                {t("Clear")}
                            </Button>
                        )}
                        <Button
                            variant="ghost"
                            size="xs"
                            onClick={() => setDate(newDate || null)}
                            disabled={loading}
                        >
                            {loading ? (
                                <LoaderCircle
                                    size={16}
                                    className="animate-spin"
                                />
                            ) : (
                                t("Save")
                            )}
                        </Button>
                    </div>
                </PopoverContent>
            </Popover>
        );
    }
);
