import type { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { staffRolesList } from "@/constants/general";
import { useFetchStaff } from "@/hooks/staff/useFetchStaff";
import { useStaffSearchParams } from "@/hooks/staff/useStaffSearchParams";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StaffFormatted, getStaffTableColumns } from "./StaffTableColumns";

export const StaffTable = () => {
    const navigate = useNavigate();
    const { page, perPage, search, role } = useStaffSearchParams();
    const { t: gt } = useTranslation("general");
    const { t: st } = useTranslation("staff");
    const { t: consts } = useTranslation("consts");

    const { data, isLoading } = useFetchStaff({
        per_page: perPage,
        page,
        search,
        role,
    });

    const filterFields: DataTableFilterField<StaffFormatted>[] = [
        {
            label: gt("Search.Search"),
            value: "name",
            placeholder: gt("SearchBy.Name"),
        },
        {
            label: st("Role"),
            value: "role_string",
            disableMultiSelect: true,
            options: staffRolesList.map((staffRole) => {
                return { label: consts(staffRole.key), value: staffRole.value };
            }),
        },
    ];

    const columns = getStaffTableColumns();
    const { table } = useDataTable({
        data: data?.data ?? [],
        columns,
        totalItems: data?.total ?? 0,
        defaultPerPage: 10,
        filterFields,
    });

    return (
        <div className="space-y-5">
            <DataTableToolbar table={table} filterFields={filterFields}>
                <Button
                    className="flex flex-row gap-1 "
                    onClick={() => {
                        navigate("form");
                    }}
                >
                    <CirclePlus color="white" size={18} />
                    {st("NewStaff")}
                </Button>
            </DataTableToolbar>
            {isLoading ? (
                <DataTableSkeleton
                    columnCount={8}
                    cellWidths={[
                        "11rem",
                        "8rem",
                        "16rem",
                        "5rem",
                        "5rem",
                        "5rem",
                        "4rem",
                        "2rem",
                    ]}
                    shrinkZero
                />
            ) : (
                <DataTable table={table} />
            )}
        </div>
    );
};
