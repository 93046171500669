export const staffUserRoles = [
    "csr",
    "gm",
    "move_expert",
    "lead_concierge",
    "sale",
    "administrator",
    "project_manager",
];

export const customerUserRoles = ["customer_user", "customer_user_additional"];

export const builderUserRoles = ["builder", "additional_builder"];
