import i18n, { loadNamespaces } from "i18next";
import parsePhoneNumber from "libphonenumber-js";
import { z } from "zod";

loadNamespaces("general");

export const phoneNumberSchema = z
    .string({
        message: i18n.t("general:PhoneNumber.PhoneRequired"),
    })
    .transform((value, ctx) => {
        const phoneNumber = parsePhoneNumber(value, {
            defaultCountry: "CA",
        });

        if (!phoneNumber?.isValid()) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("general:PhoneNumber.InvalidPhone"),
            });
            return z.NEVER;
        }

        return phoneNumber.formatInternational();
    });
