import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { uploadFileToS3 } from "@/helpers/uploads";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchCampaignById } from "@/hooks/useFetchCampaignById";
import { useFetchDeals } from "@/hooks/useFetchDeals";
import { useFetchProjects } from "@/hooks/useFetchProjects";
import { useUpdateCampaignsById } from "@/hooks/useUpdateCampaignsById";

import { useToast } from "@/hooks/utils/useToast";
import { useUploadUrlPresigned } from "@/hooks/utils/useUploadUrlPresigned";
import { extractFileName } from "@/lib/utils";
import { CreateCampaignSchema } from "@/schemas/builders/campaignSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { add } from "date-fns";
import { Check, Loader } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignForm } from "./CreateCampaignsPage/components/CampaignForm";
import { CampaignPreview } from "./CreateCampaignsPage/components/CampaignPreview";

export const EditCampaignPage = () => {
    const { campaignId } = useParams();
    const { data, isLoading: isCampaignLoading } = useFetchCampaignById({
        id: Number(campaignId),
    });
    const { data: deals, isLoading: isDealsLoading } = useFetchDeals();
    const { data: projects, isLoading: isProjectsLoading } = useFetchProjects();
    const { mutateAsync: updateCampaignsById } = useUpdateCampaignsById({
        id: Number(campaignId),
    });
    const { t } = useTranslation("campaign");
    const { user } = useAuth();
    const { mutateAsync: mutateFetchAsync, isPending } =
        useUploadUrlPresigned();
    const navigation = useNavigate();
    const isLoading = isDealsLoading || isProjectsLoading || isCampaignLoading;
    const { toast } = useToast();

    const form = useForm<CreateCampaignSchema>({
        resolver: zodResolver(CreateCampaignSchema),
        defaultValues: {
            builder_account_id: user?.builder_accounts[0].id,
        },
    });

    const { handleSubmit, control, reset } = form;

    useEffect(() => {
        if (data) {
            const parsedDate = data.date.replace(/\//g, "-");
            const parsedUntilDate = data.until_date.replace(/\//g, "-");

            reset({
                ...data,
                campaign_locations: {
                    city: data.place_details?.city,
                    country: data.place_details?.country,
                    place_id: data.place_details?.place_id,
                    latitude: data.place_details?.latitude,
                    longitude: data.place_details?.longitude,
                },

                campaign_project_preference_ids: data.projects,
                campaign_project_community_ids: data.communities,
                builder_account_id: user?.builder_accounts[0].id,
                date: add(new Date(parsedDate), { days: 1 }),
                until_date: add(new Date(parsedUntilDate), { days: 1 }),
            });
        }
    }, [data]);

    const handleUpdateCampaign = async (data: CreateCampaignSchema) => {
        // had to hack it since or backend does not store it properly
        const existingFileName = extractFileName(data.display_image ?? "");
        const link: string[] = [];
        if (data.files && data.files[0].name !== existingFileName) {
            if (data.files && data.files.length > 0) {
                const presigned_url = await mutateFetchAsync({
                    file_size: data.files[0].size,
                    filename: data.files[0].name,
                    object_id: 1,
                    upload_type:
                        data.deal_type === "Deal"
                            ? "DEAL_IMAGE"
                            : "EVENT_IMAGE",
                });

                link.push(presigned_url.public_url);
                await uploadFileToS3(
                    data.files[0],
                    presigned_url.presigned_url
                );
            }
        }
        await updateCampaignsById(
            {
                ...data,
                display_image: link[0] ?? data.display_image,
            },
            {
                onSuccess: () => {
                    toast({
                        title: t("Toast.CampaignUpdated"),
                        description: t("Toast.CampaignUpdated"),
                        duration: 3000,
                    });

                    navigation("/campaigns");
                },
                onError: (error) => {
                    toast({
                        title: t("Toast.Error"),
                        description: error.message,
                    });
                },
            }
        );
    };

    const formData = useWatch({ control });

    return (
        <ContentLayout
            title={t("UpdateCampaign")}
            subTitle={t("UpdateCampaignsDescription")}
        >
            <ContentWrapper isLoading={isLoading}>
                <FormProvider {...form}>
                    <TwoColumnLayout
                        leftContent={
                            <Form {...form}>
                                <form
                                    onSubmit={handleSubmit(
                                        handleUpdateCampaign
                                    )}
                                    className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-white p-5 rounded-lg"
                                >
                                    <CampaignForm
                                        deals={deals}
                                        projects={projects}
                                    />
                                    <div className="col-span-1 md:col-span-2 flex justify-end mt-6">
                                        <Button
                                            disabled={isPending}
                                            type="submit"
                                        >
                                            {isPending ? (
                                                <Loader className="animate-spin h-5 w-5" />
                                            ) : (
                                                <>
                                                    {t("UpdateCampaign")}
                                                    <Check className="ml-2" />
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </Form>
                        }
                        rightContent={
                            <CampaignPreview
                                data={{
                                    deal_type: formData.deal_type,
                                    details: formData.details,
                                    files: formData.files,
                                    title: formData.title,
                                    campaign_locations:
                                        formData.campaign_locations,
                                }}
                            />
                        }
                    />
                </FormProvider>
            </ContentWrapper>
        </ContentLayout>
    );
};
