import { FileTextIcon, Image, VideoIcon } from "lucide-react";
import PdfIcon from "../assets/pdfIcon.svg?react";

export const getFileIcon = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
            return <Image className="h-6 w-6" />;
        case "mp4":
        case "avi":
        case "mkv":
        case "mov":
            return <VideoIcon className="h-6 w-6" />;
        case "pdf":
            return <PdfIcon className="h-6 w-6" />;
        case "xls":
        case "xlsx":
        case "doc":
        case "docx":
        case "ppt":
        case "pptx":
        case "txt":
            return <FileTextIcon className="h-6 w-6" />;
        default:
            return <FileTextIcon className="h-6 w-6" />;
    }
};
