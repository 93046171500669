import { VirtuoLibraryResourcesSchema } from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { nestApi } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchResources = () => {
    return useQuery({
        queryKey: ["resources"],
        queryFn: async () => {
            const { data } =
                await nestApi.get<VirtuoLibraryResourcesSchema[]>("resources");
            return data;
        },
    });
};
