import {
    CreateVirtuoLibraryResources,
    VirtuoLibraryResourcesSchema,
} from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { nestApi } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateResource = () => {
    return useMutation({
        mutationFn: async (body: CreateVirtuoLibraryResources) => {
            const { files, ...rest } = body;
            const formData = new FormData();
            if (files) {
                formData.append("file", files[0]);
            }

            formData.append("data", JSON.stringify(rest));

            const { data } = await nestApi.post<VirtuoLibraryResourcesSchema>(
                "resources",
                formData
            );

            return data;
        },
    });
};
