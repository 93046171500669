import { UserSchemaType } from "@/schemas/user/userSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import { filterData } from "./useFetchSearchedCustomers";

interface FetchSuggestedCustomersProps {
    user_id: number;
    isEnabled?: boolean;
}

//TODO: Ticket #6570 refine query
export const useFetchSuggestedCustomers = (
    params: FetchSuggestedCustomersProps
) => {
    return useQuery({
        queryKey: ["searchSuggestionCustomers", params],
        queryFn: async () => {
            const { data } = await api.get<UserSchemaType[]>(
                "v1/common/recently_viewed_users",
                { params }
            );

            return filterData(data);
        },
        enabled: params.isEnabled,
    });
};
