import { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import { useFetchAccounts } from "@/hooks/account/useFetchAccounts";
import { useFetchCompanies } from "@/hooks/options/useFetchCompanies";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { AccountType } from "@/schemas/accountSchema";
import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAccountColumns } from "./AccountTableColumns";

// TODO: Ticket #6679 refine query, add in pagination, search and sorting. use useStaffSearchParams

export const AccountTable = () => {
    const { t: gt } = useTranslation("general");
    const { t } = useTranslation("account");
    const { data, isLoading: accountsLoading } = useFetchAccounts();
    const { data: companies, isLoading: companiesLoading } =
        useFetchCompanies();

    const navigate = useNavigate();
    const filterFields: DataTableFilterField<AccountType>[] = [
        {
            label: gt("Search.Search"),
            value: "builder_name",
            placeholder: gt("SearchBy.Builder"),
        },
        {
            label: t("Company"),
            value: "company_name",
            disableMultiSelect: true,
            options: companies?.map((company) => ({
                label: company.company_name,
                value: company.id.toString(),
            })),
        },
    ];

    const columns = getAccountColumns();
    const { table } = useDataTable({
        data: data ?? [],
        columns,
        totalItems: data?.length,
        defaultPerPage: 10,
        filterFields,
        manual: false,
    });
    return (
        <div className="space-y-5">
            <DataTableToolbar table={table} filterFields={filterFields}>
                <Button
                    className="flex flex-row gap-1 "
                    onClick={() => {
                        navigate("create");
                    }}
                >
                    <CirclePlus color="white" size={18} />
                    {t("AddAccount")}
                </Button>
            </DataTableToolbar>
            {accountsLoading || companiesLoading || data?.length === 0 ? (
                <DataTableSkeleton
                    columnCount={6}
                    cellWidths={[
                        "10rem",
                        "10rem",
                        "5rem",
                        "5rem",
                        "6rem",
                        "6rem",
                    ]}
                    shrinkZero
                />
            ) : (
                <DataTable table={table} />
            )}
        </div>
    );
};
