import { Shell } from "@/components/Shell";
import { PropertyTable } from "@/components/table/PropertyTable/PropertyTable";
import { useTranslation } from "react-i18next";

const PropertyPage = () => {
    const { t } = useTranslation("property");

    return (
        <Shell>
            <div className="flex flex-col gap-1 mb-4">
                <h1 className="text-3xl font-semibold text-gray-text/2">
                    {t("Title")}
                </h1>
                <h2 className="text-sm text-muted-foreground">
                    {t("SubTitle")}
                </h2>
            </div>
            <PropertyTable />
        </Shell>
    );
};

export default PropertyPage;
