import { ChevronRight, Headset } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";

export const SupportCard = () => {
    const { t: upt } = useTranslation("userProfile");
    const handleButtonClick = () => {
        window.location.href = "mailto:hello@virtuo.com";
    };
    return (
        <Card className="w-full p-6 rounded-lg shadow-none">
            <CardContent className="flex flex-col items-center text-center justify-center">
                <div className="bg-grey-bg w-96 h-96 max-w-[7.5rem] max-h-[7.5rem] rounded-full flex items-center justify-center mx-auto mb-4">
                    <Headset size={48} className="text-grey-70/30" />
                </div>
                <div className="flex flex-col items-center justify-center mt-2 font-medium text-lg">
                    <span className="text-gray-600">
                        {upt("UserProfile.Support")}
                    </span>
                    <Button
                        onClick={handleButtonClick}
                        variant="link"
                        className="text-destructive flex items-center p-0 ml-4 font-medium text-lg"
                    >
                        {upt("UserProfile.GetSupport")}
                        <ChevronRight className="font-bold h-4 w-4 " />
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};
