import { useAuth } from "@/hooks/login/useAuth";
import { useSetupColors } from "@/hooks/useSetupColors";
import React from "react";
import { Navigate } from "react-router-dom";
import { SkeletonLayout } from "./layout/SideBar/SkeletonLayout";

interface PrivateRouteProps {
    element: React.ElementType;
    roles: string[];
    [key: string]: unknown;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    element: Component,
    roles,
    ...rest
}) => {
    const { token, user } = useAuth();
    const userRoles = user?.roles || [];
    const { isLoading } = useSetupColors();

    if (isLoading) {
        return <SkeletonLayout />;
    }

    if (!token) {
        return <Navigate to="/" replace />;
    }

    if (roles && !roles.some((role) => userRoles.includes(role))) {
        return <Navigate to="/not-authorized" replace />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;
