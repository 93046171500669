import { MainSchemaType } from "@/schemas/fileHistorySchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import Fuse from "fuse.js";
interface FetchFileHistoryProps {
    userId: number;
    searchTerm?: string;
}

export const useFetchFileHistory = ({
    userId,
    searchTerm,
}: FetchFileHistoryProps) => {
    return useQuery({
        queryKey: ["file-history", userId],
        queryFn: async () => {
            const { data } = await api.get<MainSchemaType>(
                `v1/users/file_history`,
                {
                    params: {
                        user_id: userId,
                    },
                }
            );
            return data;
        },
        select: (data) => {
            if (searchTerm) {
                const fuse = new Fuse(Object.values(data.documents).flat(), {
                    keys: ["title", "description", "file_name"],
                });
                const result = fuse.search(searchTerm);
                const filteredDocuments = result.reduce(
                    (acc: Record<string, (typeof item)[]>, { item }) => {
                        const category = item.category;
                        if (!acc[category]) acc[category] = [];
                        acc[category].push(item);
                        return acc;
                    },
                    {}
                );

                return { ...data, documents: filteredDocuments };
            }
            return data;
        },
    });
};
