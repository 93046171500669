import { AddressSchema } from "@/schemas/addressSchema";
import i18n, { loadNamespaces } from "i18next";
import { z } from "zod";

loadNamespaces("validation");

export const StaffFormSchema = z.object({
    first_name: z
        .string({
            message: i18n.t("validation:FirstName"),
        })
        .min(1, i18n.t("validation:FirstName")),
    last_name: z
        .string({
            message: i18n.t("validation:LastName"),
        })
        .min(1, i18n.t("validation:LastName")),
    birthday: z.date(),
    languages: z.array(z.string()).nonempty(i18n.t("validation:Language")),
    email: z
        .string({
            message: i18n.t("validation:Email"),
        })
        .email({
            message: i18n.t("validation:Email"),
        }),
    phone_number: z.object({
        number: z.string().min(6, i18n.t("validation:PhoneNumber")),
        type: z.enum(["mobile", "home", "work"]).default("mobile"), // Default to mobile
    }),
    roles: z.array(z.string()).nonempty(i18n.t("validation:Role")),
    me_capacity: z.coerce
        .number()
        .int()
        .gte(0, i18n.t("validation:MeCapacityPercent"))
        .lte(100, i18n.t("validation:MeCapacityPercent"))
        .default(0),
    user_status_id: z.string(), // New user active by default
    mc_booking_link: z.string().optional(),
    work_location: AddressSchema.optional(),
});

export type StaffFormSchemaType = z.infer<typeof StaffFormSchema>;
