import { LoginResponseSchema, LoginSchemaType } from "@/schemas";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useLogin = () => {
    return useMutation({
        mutationFn: (user: LoginSchemaType) =>
            api.post<LoginResponseSchema>("v1/sessions/login/", user),
    });
};
