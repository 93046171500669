import type { BuilderAccountArraySchemaType } from "@/schemas";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilders = () => {
    return useQuery({
        queryKey: ["builders"],
        queryFn: async () => {
            const { data } = await api.get<BuilderAccountArraySchemaType>(
                "v1/builder_accounts/list_builder_accounts"
            );
            return data;
        },
    });
};
