import { Logo } from "@/assets/logo";
import { cn } from "@/lib/utils";
import { X } from "lucide-react";

interface AppLogoProps {
    builderLogo?: string;
    isCustomer?: boolean;
}

export const AppLogo = ({ builderLogo, isCustomer }: AppLogoProps) => (
    <div
        className={cn("flex items-center", isCustomer ? "gap-2" : "space-x-2")}
    >
        {builderLogo && (
            <img
                src={builderLogo}
                alt="builder-logo"
                width={isCustomer ? 150 : 129}
                height={isCustomer ? 64 : 40}
            />
        )}
        {builderLogo && (
            <X
                color={isCustomer ? "rgba(0, 0, 0, 0.50)" : "white"}
                className={isCustomer ? "w-8 h-8" : "w-6 h-6"}
            />
        )}
        <div className={isCustomer ? "h-8" : "w-6 h-6 "}>
            <Logo
                color={isCustomer ? "#3B8FFF" : "white"}
                className={isCustomer ? "h-8" : "h-6"}
            />
        </div>
    </div>
);
