import { z } from "zod";

export const BroadcastFormSchema = z.object({
    id: z.number().optional(),
    title: z.string(),
    selected_notification_types: z.array(z.number()),
    broadcast_body: z.string(),
    description: z.string().optional(),
    builder_account_id: z.number(),
    schedule_broadcast_trigger: z.boolean(),
    url: z.string().optional(),
    broadcast_date: z.coerce.string(),
    broadcast_time: z.coerce.string(),
    broadcast_project_preference_ids: z.array(z.number()).optional(),
    broadcast_project_community_ids: z.array(z.number()).optional(),
    broadcast_product_types_ids: z.array(z.number()).optional(),
    are_project_included: z.boolean().optional(),
    is_approved: z.boolean().optional(),
    publish: z.boolean().optional(),
    default_trigger_date: z.coerce.string(),
    date: z.coerce.string(),
});

export type BroadcastFormSchemaType = z.infer<typeof BroadcastFormSchema>;

export const CreateBroadcastSchema = z.object({
    are_project_included: z.boolean().default(false),
    broadcast_body: z.string(),
    broadcast_project_community_ids: z.array(z.coerce.number()).optional(),
    broadcast_project_preference_ids: z.array(z.coerce.number()).optional(),
    broadcast_product_types_ids: z.array(z.coerce.number()).optional(),
    broadcast_recipients_ids: z.array(z.coerce.number()).optional(),
    broadcast_time: z.coerce.string(),
    builder_account_id: z.number(),
    date: z.coerce.string(),
    days_before: z.string().optional(),
    default_trigger_date: z.coerce.string(),
    is_approved: z.boolean().default(false),
    phase_ids: z.array(z.number()).optional(),
    publish: z.boolean().default(false),
    schedule_broadcast_trigger: z.boolean().default(false),
    selected_notification_types: z.array(z.number()),
    title: z.string(),
});

export type CreateBroadcastSchemaType = z.infer<typeof CreateBroadcastSchema>;
