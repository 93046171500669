import { number, object, string, type z } from "zod";

export const clientResponseSchema = object({
    email: string(),
    fullname: string(),
    id: string(),
    builder_name: string(),
    project_name: string(),
    created_at: string(),
    mc_name: string(),
    status: string(),
});

export const genericResponsesSchema = object({
    total_items: number(),
    users: clientResponseSchema.array(),
});

export type clientResponseType = z.infer<typeof genericResponsesSchema>;

export type clientType = z.infer<typeof clientResponseSchema>;
