import { CustomerBannerMessage } from "@/components/CustomerBannerMessage";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchPrimaryUserProperty } from "@/hooks/useFetchPrimaryUserProperty";
import { useSetupColors } from "@/hooks/useSetupColors";
import { useMediaQuery } from "@/hooks/utils/useMediaQuery";
import { cn } from "@/lib/utils";
import { SheetMenu } from "../../SheetMenu";
import { UserNav } from "../../UserNav/UserNav";
import { AppLogo } from "./AppLogo";
import { StaffSearch } from "./StaffSearch";

interface NavbarProps {
    title?: string;
}

export function Navbar({ title }: NavbarProps) {
    const { builder } = useSetupColors();
    const { isStaff, isCustomer, user } = useAuth();
    const showMessage = useMediaQuery("(min-width: 840px)");

    const { data: primaryUserPropertyDetails } =
        useFetchPrimaryUserProperty(isCustomer);

    return (
        <header
            data-testid="navbar"
            className={cn(
                "sticky top-0 z-10 w-full",
                isCustomer
                    ? " bg-white shadow-custom h-[96px]"
                    : "bg-primary shadow backdrop-blue"
            )}
        >
            <div
                className={cn(
                    "mx-4 sm:mx-8 flex items-center",
                    isCustomer ? "h-[96px] gap-4" : "h-[68px]"
                )}
            >
                <div
                    className={cn(
                        "flex items-center space-x-4",
                        showMessage && "flex-shrink-0"
                    )}
                >
                    <SheetMenu />
                    {title && (
                        <h1 className="hidden font-bold sm:block">{title}</h1>
                    )}
                    {isStaff && <StaffSearch />}
                    <AppLogo
                        builderLogo={builder?.logo}
                        isCustomer={isCustomer}
                    />
                </div>

                <div className="flex items-center ml-auto gap-5 flex-shrink min-w-0">
                    {user && isCustomer && showMessage && (
                        <CustomerBannerMessage
                            firstName={user.first_name}
                            lastName={user.last_name}
                            formattedAddress={
                                primaryUserPropertyDetails?.address
                            }
                        />
                    )}
                    <UserNav />
                </div>
            </div>
        </header>
    );
}
