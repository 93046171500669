import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APIProvider } from "@vis.gl/react-google-maps";
import { queryClient } from "./lib/queryClient";
import { Routes } from "./routes/routes";

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <APIProvider
                apiKey={import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY}
                i18nIsDynamicList
                libraries={["places", "drawing"]}
            >
                <Routes />

                <ReactQueryDevtools initialIsOpen={false} />
            </APIProvider>
        </QueryClientProvider>
    );
};
