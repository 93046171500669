import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchOptions = () => {
    return useQuery({
        queryKey: ["options"],
        queryFn: async () => {
            const { data } = await api.get("v1/common/options_list");
            return data;
        },
        retry: false,
    });
};
