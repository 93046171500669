import { FileUploader } from "@/components/FileUpload";
import {
    Credenza,
    CredenzaBody,
    CredenzaClose,
    CredenzaContent,
    CredenzaFooter,
    CredenzaHeader,
    CredenzaTitle,
} from "@/components/ui/credenza";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { acceptFileType } from "@/constants/acceptFileType";
import { folderListOptions } from "@/constants/folder";
import { uploadFileToS3 } from "@/helpers/uploads";
import { useAuth } from "@/hooks/login/useAuth";
import { useUploadDocumentFileCabinet } from "@/hooks/useUploadDocumentFileCabinet";
import { useToast } from "@/hooks/utils/useToast";
import { queryClient } from "@/lib/queryClient";
import {
    AddNewFileSchema,
    AddNewFileSchemaType,
} from "@/schemas/addNewFileSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderCircle } from "lucide-react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";

interface AddNewFileModalProps {
    open: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    propertyId: number;
    userId: number;
    upload_type?: string;
}

export const AddNewFileModal = ({
    open,
    setIsOpen,
    propertyId,
    upload_type = "USER_DOCUMENT",
    userId,
}: AddNewFileModalProps) => {
    const { mutateAsync: uploadFileAsync, isPending } =
        useUploadDocumentFileCabinet();

    const form = useForm<AddNewFileSchemaType>({
        resolver: zodResolver(AddNewFileSchema),
    });
    const { handleSubmit, control, setValue } = form;
    const { toast } = useToast();
    const { t } = useTranslation("file");
    const { t: tFileImporter } = useTranslation("fileImporter");
    const { user } = useAuth();

    user.builder_accounts[0].id;

    useEffect(() => {
        form.reset({
            object_id: userId,
            upload_type: upload_type,
            user_document: {
                user_property_id: propertyId,
            },
        });
    }, [userId, propertyId]);

    const handleUpload = async (data: AddNewFileSchemaType) => {
        const signedUrls = await Promise.all(
            data.files?.map((file) => {
                return uploadFileAsync({
                    filename: file.name ?? "",
                    file_size: file.size,
                    object_id: userId,
                    upload_type: "USER_DOCUMENT",
                    user_document: {
                        user_property_id:
                            data.user_document?.user_property_id ?? 1,
                        category: data.user_document?.category || "1",
                        title: data.user_document?.title || "",
                        description: data.user_document?.description || "",
                    },
                });
            }) ?? []
        );

        queryClient.removeQueries({
            queryKey: ["file-history", userId],
        });

        await Promise.all(
            signedUrls.map(async (signedUrl) => {
                await Promise.all(
                    data.files?.map((file) =>
                        uploadFileToS3(file, signedUrl)
                    ) ?? []
                );
            })
        );
        toast({
            title: tFileImporter("Toast.Title"),
            description: tFileImporter("Toast.Description"),
            duration: 3000,
        });
        setIsOpen(false);

        form.reset();
    };

    const handleOnOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <Credenza open={open} onOpenChange={handleOnOpenChange}>
            <CredenzaContent>
                <FormProvider {...form}>
                    <Form {...form}>
                        <form onSubmit={handleSubmit(handleUpload)}>
                            <CredenzaHeader className="flex justify-between items-center p-4">
                                <CredenzaTitle className="text-lg font-bold">
                                    {t("UploadFile")}
                                </CredenzaTitle>
                            </CredenzaHeader>
                            <CredenzaBody className="bg-white p-10 rounded-lg flex flex-col">
                                <div className="flex flex-col gap-2 p-3">
                                    <FormField
                                        control={control}
                                        name="user_document.title"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {t("Title")}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        placeholder={t(
                                                            "EnterTitle"
                                                        )}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={control}
                                        name="user_document.category"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {t("Folder")}
                                                </FormLabel>
                                                <FormControl>
                                                    <Select
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue
                                                                placeholder={t(
                                                                    "SelectFolder"
                                                                )}
                                                            />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>
                                                                    {t(
                                                                        "Folders"
                                                                    )}
                                                                </SelectLabel>
                                                                {folderListOptions.map(
                                                                    (
                                                                        folder
                                                                    ) => (
                                                                        <SelectItem
                                                                            key={
                                                                                folder.value
                                                                            }
                                                                            value={folder.value.toString()}
                                                                        >
                                                                            {
                                                                                folder.label
                                                                            }
                                                                        </SelectItem>
                                                                    )
                                                                )}
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={control}
                                        name="user_document.description"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {t("Description")}
                                                </FormLabel>
                                                <FormControl>
                                                    <Textarea
                                                        placeholder={t(
                                                            "EnterDescription"
                                                        )}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={control}
                                        name="files"
                                        render={({ field }) => {
                                            return (
                                                <FormItem>
                                                    <FormControl>
                                                        <FileUploader
                                                            multiple={true}
                                                            onValueChange={(
                                                                value
                                                            ) => {
                                                                setValue(
                                                                    "files",
                                                                    value
                                                                );
                                                            }}
                                                            maxFileCount={10}
                                                            maxSize={
                                                                50 * 1024 * 1024
                                                            } // 50 MB
                                                            accept={
                                                                acceptFileType
                                                            }
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            );
                                        }}
                                    />
                                </div>
                            </CredenzaBody>
                            <CredenzaFooter className="flex justify-end items-end p-4">
                                <CredenzaClose className="space-x-2">
                                    <Button>Cancel</Button>
                                    <Button type="submit" disabled={isPending}>
                                        {isPending ? (
                                            <LoaderCircle size={16} />
                                        ) : (
                                            t("Save")
                                        )}
                                    </Button>
                                </CredenzaClose>
                            </CredenzaFooter>
                        </form>
                    </Form>
                </FormProvider>
            </CredenzaContent>
        </Credenza>
    );
};
