import { queryClient } from "@/lib/queryClient";
import { AdditionalUsersType } from "@/schemas/additionalUsersSchema";
import { BuilderAccountDetailsType } from "@/schemas/builders/builderAccountDetailsSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/useAuth";
import { useToast } from "../utils/useToast";

interface UpdateBuilderUserStatusProps {
    user_id: number;
    is_active: boolean;
}

export const updateBuilderUserStatus = () => {
    const { toast } = useToast();
    const { t } = useTranslation("hooks");
    const { user } = useAuth();
    return useMutation({
        mutationFn: async (body: UpdateBuilderUserStatusProps) => {
            const { data } = await api.patch<AdditionalUsersType>(
                "v1/builder_accounts/toggle_additional_builder",
                body
            );
            await queryClient.cancelQueries({
                queryKey: [
                    "builderAccountDetails",
                    user.builder_accounts[0].id,
                ],
            });
            const previousUsers =
                queryClient.getQueryData<BuilderAccountDetailsType>([
                    "builderAccountDetails",
                    user.builder_accounts[0].id,
                ]);
            queryClient.setQueryData(
                ["builderAccountDetails", user.builder_accounts[0].id],
                {
                    ...previousUsers,
                    user: {
                        ...previousUsers?.user,
                        additional_users:
                            previousUsers?.user.additional_users.map((user) =>
                                user.id === body.user_id
                                    ? {
                                          ...user,
                                          user_status_id: body.is_active
                                              ? 2
                                              : 3,
                                      }
                                    : user
                            ),
                    },
                }
            );
            return data;
        },
        onSuccess() {
            toast({
                title: t("Success.Update.BuilderUserStatus"),
                variant: "success",
            });
        },
        onError(error) {
            toast({
                title: t("Error.Update.BuilderUserStatus"),
                description: error.message,
                variant: "destructive",
            });
        },
        retry: false,
    });
};
