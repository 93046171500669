import { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

interface UseSearchAddressResult {
    query: string;
    results: google.maps.places.AutocompletePrediction[];
    loading: boolean;
    handleSearch: (value: string) => void;
    selectedItem: google.maps.places.AutocompletePrediction | null;
    setSelectedItem: (
        item: google.maps.places.AutocompletePrediction | null
    ) => void;
    placeDetails: google.maps.places.PlaceResult | null;
}

export const useSearchAddress = (): UseSearchAddressResult => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<
        google.maps.places.AutocompletePrediction[]
    >([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] =
        useState<google.maps.places.AutocompletePrediction | null>(null);
    const [placeDetails, setPlaceDetails] =
        useState<google.maps.places.PlaceResult | null>(null);

    const debouncedQuery = useDebounce(query, 500);

    const handleSearch = (value: string) => {
        setQuery(value);
    };

    useEffect(() => {
        const fetchResults = async () => {
            if (debouncedQuery.length > 2) {
                setLoading(true);
                const service = new google.maps.places.AutocompleteService();
                service.getPlacePredictions(
                    { input: debouncedQuery },
                    (predictions, status) => {
                        if (
                            status === google.maps.places.PlacesServiceStatus.OK
                        ) {
                            setResults(predictions || []);
                        } else {
                            setResults([]);
                        }
                        setLoading(false);
                    }
                );
            } else {
                setResults([]);
            }
        };

        fetchResults();
    }, [debouncedQuery]);

    useEffect(() => {
        const fetchPlaceDetails = async (placeId: string) => {
            const service = new google.maps.places.PlacesService(
                document.createElement("div")
            );
            service.getDetails({ placeId }, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setPlaceDetails(place);
                } else {
                    setPlaceDetails(null);
                }
            });
        };

        if (selectedItem) {
            fetchPlaceDetails(selectedItem.place_id);
        }
    }, [selectedItem]);

    return {
        query,
        results,
        loading,
        handleSearch,
        selectedItem,
        setSelectedItem,
        placeDetails,
    };
};
