import { Editor } from "@tiptap/react";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";

interface FormatTypeProps {
    editor: Editor;
}

export function FormatType({ editor }: FormatTypeProps) {
    const { t } = useTranslation("richText");
    const value = () => {
        if (editor.isActive("paragraph")) return "paragraph";
        if (editor.isActive("heading", { level: 1 })) return "h1";
        if (editor.isActive("heading", { level: 2 })) return "h2";
        if (editor.isActive("heading", { level: 3 })) return "h3";
        if (editor.isActive("heading", { level: 4 })) return "h4";
        if (editor.isActive("heading", { level: 5 })) return "h5";
        if (editor.isActive("heading", { level: 6 })) return "h6";
    };

    const onChange = (value: string) => {
        switch (value) {
            case "paragraph":
                editor.chain().focus().setParagraph().run();
                break;
            case "h1":
                editor.chain().focus().toggleHeading({ level: 1 }).run();
                break;
            case "h2":
                editor.chain().focus().toggleHeading({ level: 2 }).run();
                break;
            case "h3":
                editor.chain().focus().toggleHeading({ level: 3 }).run();
                break;
            case "h4":
                editor.chain().focus().toggleHeading({ level: 4 }).run();
                break;
            case "h5":
                editor.chain().focus().toggleHeading({ level: 5 }).run();
                break;
            case "h6":
                editor.chain().focus().toggleHeading({ level: 6 }).run();
                break;
        }
    };

    return (
        <Select onValueChange={onChange} defaultValue={value()} value={value()}>
            <SelectTrigger className="h-8 w-[120px] invisible sm:visible">
                <SelectValue placeholder={t("SelectFormat")} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="paragraph">{t("Paragraph")}</SelectItem>
                    <SelectItem value="h1">{t("Heading1")}</SelectItem>
                    <SelectItem value="h2">{t("Heading2")}</SelectItem>
                    <SelectItem value="h3">{t("Heading3")}</SelectItem>
                    <SelectItem value="h4">{t("Heading4")}</SelectItem>
                    <SelectItem value="h5">{t("Heading5")}</SelectItem>
                    <SelectItem value="h6">{t("Heading6")}</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    );
}
