import type { clientResponseType } from "@/schemas/clientsTableSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchClientsProps {
    builder_account_id: number;
    hc_id: number;
    items_per_page: number;
    page: number;
    project_id: number;
    search: string;
    sort_options: {
        sort_by_type: string;
        sort_order: string;
        sort_priority: number;
    }[];
    status_id: number;
}
// TODO: Ticket #6569 refine query
export const useFetchClients = ({
    builder_account_id,
    hc_id,
    items_per_page,
    page,
    project_id,
    search,
    sort_options,
    status_id,
}: FetchClientsProps) => {
    return useQuery({
        queryKey: [
            "clients",
            {
                builder_account_id,
                hc_id,
                items_per_page,
                page,
                project_id,
                search,
                sort_options,
                status_id,
            },
        ],
        queryFn: async () => {
            const { data } = await api.post<clientResponseType>(
                "v1/users/all_customers",
                {
                    builder_account_id,
                    hc_id,
                    items_per_page,
                    page,
                    project_id,
                    search,
                    sort_options,
                    status_id,
                }
            );
            return data;
        },
    });
};
