import { Skeleton } from "@/components/ui/skeleton";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import type { HTMLAttributes } from "react";
import { v4 as uuidv4 } from "uuid";

interface DataTableSkeletonProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * The number of columns in the table.
     * @type number
     */
    columnCount: number;

    /**
     * The number of rows in the table.
     * @default 10
     * @type number | undefined
     */
    rowCount?: number;

    /**
     * The number of searchable columns in the table.
     * @default 0
     * @type number | undefined
     */
    searchableColumnCount?: number;

    /**
     * The number of filterable columns in the table.
     * @default 0
     * @type number | undefined
     */
    filterableColumnCount?: number;

    /**
     * Flag to show the table view options.
     * @default undefined
     * @type boolean | undefined
     */
    showViewOptions?: boolean;

    /**
     * The width of each cell in the table.
     * The length of the array should be equal to the columnCount.
     * Any valid CSS width value is accepted.
     * @default ["auto"]
     * @type string[] | undefined
     */
    cellWidths?: string[];

    /**
     * Flag to show the pagination bar.
     * @default true
     * @type boolean | undefined
     */
    withPagination?: boolean;

    /**
     * Flag to prevent the table cells from shrinking.
     * @default false
     * @type boolean | undefined
     */
    shrinkZero?: boolean;
}

export function DataTableSkeleton(props: DataTableSkeletonProps) {
    const {
        columnCount,
        rowCount = 10,
        searchableColumnCount = 0,
        filterableColumnCount = 0,
        showViewOptions = true,
        cellWidths = ["auto"],
        withPagination = true,
        shrinkZero = false,
        className,
        ...skeletonProps
    } = props;

    return (
        <div
            className={cn("w-full space-y-2.5 overflow-auto", className)}
            {...skeletonProps}
        >
            <div>
                <Table>
                    <TableHeader>
                        {Array.from({ length: 1 }).map((_) => (
                            <TableRow
                                key={uuidv4()}
                                className="hover:bg-transparent"
                            >
                                {Array.from({ length: columnCount }).map(
                                    (_, j) => (
                                        <TableHead
                                            key={uuidv4()}
                                            style={{
                                                width: cellWidths[j],
                                                minWidth: shrinkZero
                                                    ? cellWidths[j]
                                                    : "auto",
                                            }}
                                        >
                                            <Skeleton className="h-6 w-full" />
                                        </TableHead>
                                    )
                                )}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {Array.from({ length: rowCount }).map((_) => (
                            <TableRow
                                key={uuidv4()}
                                className="hover:bg-transparent"
                            >
                                {Array.from({ length: columnCount }).map(
                                    (_, j) => (
                                        <TableCell
                                            key={uuidv4()}
                                            style={{
                                                width: cellWidths[j],
                                                minWidth: shrinkZero
                                                    ? cellWidths[j]
                                                    : "auto",
                                            }}
                                        >
                                            <Skeleton className="h-6 w-full" />
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {withPagination ? (
                <div className="flex w-full  items-center justify-between gap-4 overflow-auto p-1 sm:gap-8">
                    <Skeleton className="h-7 w-40 shrink-0" />
                    <div className="flex items-center gap-4 sm:gap-6 lg:gap-8">
                        <div className="flex items-center space-x-2">
                            <Skeleton className="h-7 w-24" />
                            <Skeleton className="h-7 w-[4.5rem]" />
                        </div>
                        <div className="flex items-center justify-center text-sm font-medium">
                            <Skeleton className="h-7 w-20" />
                        </div>
                        <div className="flex items-center space-x-2">
                            <Skeleton className="hidden size-7 lg:block" />
                            <Skeleton className="size-7" />
                            <Skeleton className="size-7" />
                            <Skeleton className="hidden size-7 lg:block" />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
