import { queryClient } from "@/lib/queryClient";
import { CreatePropertyType } from "@/schemas/createPropertySchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface useUpdateUserPropertyProps {
    id: number;
    user_property_id: number;
    data: CreatePropertyType;
}

export const useUpdateUserProperty = () => {
    return useMutation({
        mutationFn: async ({ id, data }: useUpdateUserPropertyProps) => {
            const {
                user: { email, ...rest },
            } = data;
            const userPropertyId = data.user_properties[0].id!;
            const { data: response } = await api.put(`v1/users/${id}`, {
                user: {
                    ...rest,
                    user_status_id: 1, // TODO: TO DEFAULT TO ACTIVE USER STATUS ON THE API
                    languages: data.user.languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
                    preferred_languages:
                        data.user.preferred_languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
                },

                user_properties: [
                    ...data.user_properties.map((user_property) => ({
                        ...user_property,
                        user_property_id: id,
                        shared_user_ids: data.additional_users
                            ?.filter(
                                (user) =>
                                    user.enabled && user.additional_user_id
                            )
                            .map((user) => user.additional_user_id),
                    })),
                ],
                additional_users: (data.additional_users ?? []).map((user) => {
                    const { enabled, edited, ...rest } = user;
                    return rest;
                }).map(u => {
                    if (!u.additional_user_id) {
                        u.shared_user_properties = [userPropertyId];
                    }
                    return u;
                }),
            });
            queryClient.removeQueries({ queryKey: ["user", id] });
            return response;
        },
    });
};
