import { StaffTable } from "@/components/table/StaffTable/StaffTable";
import { useTranslation } from "react-i18next";

export function StaffPage() {
    const { t } = useTranslation("staff");

    return (
        <div className="m-auto flex flex-col gap-4 w-full">
            <h1>{t("Staff")}</h1>
            <StaffTable />
        </div>
    );
}
