import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useFetchBuilderResourceById } from "@/hooks/builder/useFetchBuilderResourceById";
import { useFetchPhases } from "@/hooks/builder/useFetchPhases";
import { useFetchResourceCategories } from "@/hooks/builder/useFetchResourceCategories";
import { useUpdateResource } from "@/hooks/builder/useUpdateResource";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchCommunities } from "@/hooks/options/useFetchCommunities";
import { useFetchProductTypes } from "@/hooks/options/useFetchProductTypes";
import { useToast } from "@/hooks/utils/useToast";
import {
    CreateVirtuoLibraryResources,
    virtuoLibraryResourcesCreateSchema,
} from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceForm } from "./components/ResourceForm";

export const EditResourcePage = () => {
    const { t } = useTranslation("resource");
    const { resourceId: id } = useParams();
    const navigate = useNavigate();

    const { mutateAsync: updateResourceAsync, isPending } = useUpdateResource();
    const { user } = useAuth();
    const { toast } = useToast();
    const builder = user?.builder_accounts[0];
    const { data: folders, isLoading: isLoadingFolders } =
        useFetchResourceCategories(builder?.id);
    const { data: communities, isLoading: isLoadingCommunities } =
        useFetchCommunities();
    const { data: phases, isLoading: isLoadingPhases } = useFetchPhases();
    const { data: productTypes, isLoading: isLoadingProductTypes } =
        useFetchProductTypes();
    const { data, isLoading: isLoadingResource } = useFetchBuilderResourceById(
        Number(id)
    );
    const [isDelayed, setIsDelayed] = useState(false);

    const isLoading =
        isLoadingFolders ||
        isLoadingCommunities ||
        isLoadingPhases ||
        isLoadingResource ||
        isLoadingProductTypes;

    const form = useForm<CreateVirtuoLibraryResources>({
        resolver: zodResolver(virtuoLibraryResourcesCreateSchema),
        defaultValues: data,
    });

    const { handleSubmit, reset } = form;

    useEffect(() => {
        if (data) {
            reset(data);
            setTimeout(() => {
                setIsDelayed(true);
            }, 1000); //@TODO: FIX THIS // Delay the form rendering for 2 seconds, i had to add this for the moment the cmdk lib is with a bug in async. //https://github.com/pacocoursey/cmdk/issues/267
        }
    }, [data]);

    const handleUpdateResource = async (data: CreateVirtuoLibraryResources) => {
        await updateResourceAsync(
            {
                id: Number(id),
                body: data,
            },
            {
                onSuccess: () => {
                    toast({
                        title: "Resource updated successfully!",
                        description:
                            "Your resource has been updated successfully",
                        duration: 3000,
                    });
                    navigate("/resources");
                },
                onError: (error) => {
                    toast({
                        title: "Error",
                        description: error.message,
                    });
                },
            }
        );
    };

    return (
        <ContentLayout
            title={t("EditResource")}
            subTitle={t("EditResourceDescription")}
        >
            <TwoColumnLayout
                leftContent={
                    isDelayed ? (
                        <FormProvider {...form}>
                            <Form {...form}>
                                <form
                                    onSubmit={handleSubmit(
                                        handleUpdateResource
                                    )}
                                    className="bg-white p-10 rounded-lg flex flex-col"
                                >
                                    <ContentWrapper
                                        isLoading={isLoading}
                                        className="bg-white"
                                    >
                                        <>
                                            {communities && (
                                                <ResourceForm
                                                    folders={folders}
                                                    communities={communities}
                                                    phases={phases}
                                                    productTypes={productTypes}
                                                />
                                            )}
                                            <div className="flex justify-end mt-6">
                                                <Button
                                                    disabled={isPending}
                                                    type="submit"
                                                >
                                                    {t("EditResource")}
                                                    <Check className="ml-2" />
                                                </Button>
                                            </div>
                                        </>
                                    </ContentWrapper>
                                </form>
                            </Form>
                        </FormProvider>
                    ) : (
                        <div className="flex justify-center items-center">
                            <Loader className="animate-spin h-30 w-30 mr-2" />
                        </div>
                    )
                }
                rightContent={<SupportCard />}
            />
        </ContentLayout>
    );
};
