import { BroadcastResponse } from "@/schemas/builders/broadcastSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchBroadcastsProps {
    builder_account_id: number;
}

export const useFetchBroadcasts = ({
    builder_account_id,
}: FetchBroadcastsProps) => {
    return useQuery({
        queryKey: ["broadcast", builder_account_id],
        queryFn: async () => {
            const { data } = await api.get<BroadcastResponse>(
                "v1/broadcasts/get_broadcasts",
                {
                    params: {
                        builder_account_id,
                    },
                }
            );
            return data;
        },
    });
};
