import { CampaignResponse } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderCampaigns = (builder_account_id: number) => {
    return useQuery({
        queryKey: ["builderCampaigns", builder_account_id],
        queryFn: async () => {
            const { data } = await api.get<CampaignResponse>(
                "v1/campaigns/get_campaigns",
                {
                    params: {
                        builder_account_id,
                    },
                }
            );

            return data.campaigns;
        },
    });
};
