import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn, getInitials } from "@/lib/utils";

const Avatar = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn(
            "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
            className
        )}
        {...props}
    />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={cn("aspect-square h-full w-full", className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn(
            "flex h-full w-full items-center justify-center rounded-full bg-muted",
            className
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

interface UserAvatarProps {
    firstName: string;
    lastName: string;
    isCustomer?: boolean;
}

const UserAvatar = ({ firstName, lastName, isCustomer }: UserAvatarProps) => (
    <Avatar>
        <AvatarImage src="#" alt="Avatar" />
        <AvatarFallback
            className={cn(
                "bg-transparent text-primary",
                isCustomer
                    ? "text-[21px] font-medium"
                    : "text-[18px] font-semibold"
            )}
        >
            {getInitials(firstName, lastName)}
        </AvatarFallback>
    </Avatar>
);

export { Avatar, AvatarImage, AvatarFallback, UserAvatar };
