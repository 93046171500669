import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

export const UserDocumentSchemaSchema = z.object({
    file_size: z.number().positive(),
    filename: z.string(),
    object_id: z.number().positive(),
    upload_type: z.enum(["USER_DOCUMENT"]),
    user_document: z.object({
        user_property_id: z.number().positive(),
        category: z.string(),
        title: z.string(),
        description: z.string().optional(),
    }),
});

type UserDocumentType = z.infer<typeof UserDocumentSchemaSchema>;

export const useUploadDocumentFileCabinet = () => {
    return useMutation({
        mutationFn: async (body: UserDocumentType) => {
            const { data } = await api.post<string>(
                "v1/uploads/upload_user_document",
                body
            );
            return data;
        },
    });
};
