import { z } from "zod";
import { AddressSchema } from "./addressSchema";
import { BuilderAccountSchema } from "./builders";
import { ProjectDetailsSchema } from "./projectDetailsSchema";

export const PropertySchema = z.object({
    id: z.number(),
    formatted_address: z.string(),
    project_id: z.number(),
    project_name: z.string(),
    notes_for_mc: z.string().nullable(),
    total_sq_ft: z.number().nullable(),
    above_grade_sq_ft: z.number().nullable(),
    bedrooms: z.number().nullable(),
    bathrooms: z.number().nullable(),
    place_details: AddressSchema,
    project_details: ProjectDetailsSchema,
    builder_account: BuilderAccountSchema,
    property_vertical_id: z.number().nullable(),
    property_vertical: z.string().nullable(),
    unit_no: z.string().nullable(),
    model: z.string().nullable(),
    no_of_stories: z.number().nullable(),
    no_of_furnaces: z.number().nullable(),
    product_type_id: z.number().nullable(),
    product_type: z.string().nullable(),
    lawn_sq_ft: z.number().nullable(),
    driveway_id: z.number().nullable(),
    lot_type_id: z.number().nullable(),
    driveway: z.string().nullable(),
    lot_type: z.string().nullable(),
    lawn_sq_ft_object: z.string().nullable(),
    additional_projects: z.array(z.any()),
});
export type PropertySchemaType = z.infer<typeof PropertySchema>;
