import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { getMenuList } from "@/constants/menuList/menuList";
import { useAuth } from "@/hooks/login/useAuth";
import { cn } from "@/lib/utils";
import { Ellipsis, LogOut } from "lucide-react";
import { startTransition } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CollapseMenuButton } from "./CollapseMenuButton";
interface MenuProps {
    isOpen: boolean | undefined;
}

export function Menu({ isOpen }: MenuProps) {
    const pathname = useLocation().pathname;
    const menuList = getMenuList(pathname);
    const { t } = useTranslation("general");
    const navigate = useNavigate();
    const { handleLogout } = useAuth();

    return (
        <ScrollArea className="[&>div>div[style]]:!block z-40">
            <nav className="mt-3 h-full w-full ">
                <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2 pb-20">
                    {menuList.map(({ groupLabel, menus }, index) => (
                        <li
                            className={cn("w-full", groupLabel ? "pt-5" : "")}
                            key={index}
                        >
                            {(isOpen && groupLabel) || isOpen === undefined ? (
                                <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">
                                    {groupLabel}
                                </p>
                            ) : !isOpen &&
                              isOpen !== undefined &&
                              groupLabel ? (
                                <TooltipProvider>
                                    <Tooltip delayDuration={100}>
                                        <TooltipTrigger className="w-full">
                                            <div className="w-full flex justify-center items-center">
                                                <Ellipsis className="h-5 w-5" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                            <p>{groupLabel}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ) : (
                                <p className="pb-2"></p>
                            )}
                            {menus.map(
                                (
                                    {
                                        href,
                                        label,
                                        icon: Icon,
                                        active,
                                        submenus,
                                    },
                                    index
                                ) =>
                                    submenus.length === 0 ? (
                                        <div className="w-full" key={index}>
                                            <TooltipProvider
                                                disableHoverableContent
                                            >
                                                <Tooltip delayDuration={100}>
                                                    <TooltipTrigger
                                                        asChild
                                                        className="z-50"
                                                    >
                                                        <Button
                                                            variant={
                                                                active
                                                                    ? "default"
                                                                    : "ghost"
                                                            }
                                                            className="w-full justify-start h-10 mb-1"
                                                            asChild
                                                        >
                                                            <Link to={href}>
                                                                <span
                                                                    className={cn(
                                                                        isOpen ===
                                                                            false
                                                                            ? ""
                                                                            : "mr-4"
                                                                    )}
                                                                >
                                                                    <Icon
                                                                        size={
                                                                            18
                                                                        }
                                                                    />
                                                                </span>
                                                                <p
                                                                    className={cn(
                                                                        "max-w-[200px] truncate",
                                                                        isOpen ===
                                                                            false
                                                                            ? "-translate-x-96 opacity-0"
                                                                            : "translate-x-0 opacity-100"
                                                                    )}
                                                                >
                                                                    {label}
                                                                </p>
                                                            </Link>
                                                        </Button>
                                                    </TooltipTrigger>
                                                    {isOpen === false && (
                                                        <TooltipContent side="right">
                                                            {label}
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    ) : (
                                        <div className="w-full" key={index}>
                                            <CollapseMenuButton
                                                icon={Icon}
                                                label={label}
                                                active={active}
                                                submenus={submenus}
                                                isOpen={isOpen}
                                            />
                                        </div>
                                    )
                            )}
                        </li>
                    ))}
                    <li className="w-full grow flex items-end">
                        <TooltipProvider disableHoverableContent>
                            <Tooltip delayDuration={100}>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="ghost"
                                        onClick={() => {
                                            startTransition(() => {
                                                handleLogout();
                                                navigate("/");
                                            });
                                        }}
                                        className="w-full justify-center h-10 mt-5"
                                    >
                                        <span
                                            className={cn(
                                                isOpen === false ? "" : "mr-4"
                                            )}
                                        >
                                            <LogOut size={18} />
                                        </span>
                                        <p
                                            className={cn(
                                                "whitespace-nowrap",
                                                isOpen === false
                                                    ? "opacity-0 hidden"
                                                    : "opacity-100"
                                            )}
                                        >
                                            {t("Login.Logout")}
                                        </p>
                                    </Button>
                                </TooltipTrigger>
                                {isOpen === false && (
                                    <TooltipContent side="right">
                                        {t("Login.Logout")}
                                    </TooltipContent>
                                )}
                            </Tooltip>
                        </TooltipProvider>
                    </li>
                </ul>
            </nav>
        </ScrollArea>
    );
}
