import { ForgotPasswordForm } from "@/components/form/ForgotPasswordForm";
import { SignInForm } from "@/components/form/SignInForm";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/login/useAuth";
import { startTransition, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import LogoNeWhite from "../assets/login/logo_ne_white.svg?react";

export const LoginPage = () => {
    const { t } = useTranslation("login");
    const [ForgotPassword, setForgotpassword] = useState(false);

    const { token } = useAuth();

    if (token) {
        return <Navigate to="/" />;
    }
    const handleForgotPasswordClick = () => {
        startTransition(() => {
            setForgotpassword(true);
        });
    };

    return (
        <div className="flex h-screen bg-white">
            <div className="w-full md:w-1/2 flex flex-col items-start justify-center p-10 mx-auto">
                <div className="w-full flex flex-col items-center justify-center">
                    {ForgotPassword ? (
                        <ForgotPasswordForm
                            setForgotpassword={setForgotpassword}
                            className="w-full max-w-sm"
                        />
                    ) : (
                        <>
                            <SignInForm className="w-full max-w-sm" />
                            <Button
                                variant="ghost"
                                onClick={handleForgotPasswordClick}
                                className="text-blue-500 mt-5"
                            >
                                {t("ForgotPassword")}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className="hidden md:flex w-full md:w-1/2 items-center justify-center bg-blue-500">
                <div className="text-center">
                    <LogoNeWhite className="mb-6" />
                    <p className="text-white text-lg">{t("Message")}</p>
                </div>
            </div>
        </div>
    );
};
