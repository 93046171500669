import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { uploadFileToS3 } from "@/helpers/uploads";
import { useAuth } from "@/hooks/login/useAuth";
import { useCreateCampaigns } from "@/hooks/useCreateCampaigns";
import { useFetchDeals } from "@/hooks/useFetchDeals";
import { useFetchProjects } from "@/hooks/useFetchProjects";
import { useToast } from "@/hooks/utils/useToast";
import { useUploadUrlPresigned } from "@/hooks/utils/useUploadUrlPresigned";
import { queryClient } from "@/lib/queryClient";
import {
    CampaignSchemaType,
    CreateCampaignSchema,
} from "@/schemas/builders/campaignSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { add } from "date-fns";
import { Check, Loader } from "lucide-react";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CampaignForm } from "./components/CampaignForm";
import { CampaignPreview } from "./components/CampaignPreview";

export const CreateCampaignsPage = () => {
    const { data: deals, isLoading: isDealsLoading } = useFetchDeals();
    const { data: projects, isLoading: isProjectsLoading } = useFetchProjects();
    const [isSubmitLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("campaign");
    const { t: gt } = useTranslation("general");
    const { user } = useAuth();
    const { mutateAsync: mutateFetchAsync } = useUploadUrlPresigned();
    const navigation = useNavigate();
    const form = useForm<CreateCampaignSchema>({
        resolver: zodResolver(CreateCampaignSchema),
        defaultValues: {
            builder_account_id: user?.builder_accounts[0].id,
        },
    });
    const { handleSubmit, control } = form;
    const isLoading = isDealsLoading || isProjectsLoading;
    const { toast } = useToast();
    const { mutateAsync, isPending } = useCreateCampaigns();

    const handleCreateCampaign = async (data: CreateCampaignSchema) => {
        setIsLoading(true);
        const link: string[] = [];
        if (data.files && data.files.length > 0) {
            const signedUrl = await mutateFetchAsync({
                file_size: data.files[0].size,
                filename: data.files[0].name,
                object_id: 1,
                upload_type:
                    data.deal_type === "Deal" ? "DEAL_IMAGE" : "EVENT_IMAGE",
            });
            link.push(signedUrl.public_url);
            await uploadFileToS3(data.files[0], signedUrl.presigned_url);
        }
        await mutateAsync(
            {
                ...data,
                display_image: link[0],
            },
            {
                onSuccess: async (response) => {
                    toast({
                        title: t("Toast.CampaignCreated"),
                        description: t("Toast.CampaignCreatedDescription"),
                        duration: 3000,
                    });

                    await queryClient.cancelQueries({
                        queryKey: [
                            "builderCampaigns",
                            user.builder_accounts[0].id,
                        ],
                    });
                    const campaigns = queryClient.getQueryData<
                        CampaignSchemaType[]
                    >(["builderCampaigns", user.builder_accounts[0].id]);

                    queryClient.setQueryData(
                        ["builderCampaigns"],
                        campaigns
                            ? [
                                  ...campaigns,
                                  {
                                      id: response.id,
                                      title: data.title,
                                      deal_type: data.deal_type,
                                      builder_account_id:
                                          user.builder_accounts[0].id,
                                      publish: data.publish,
                                      display_image: data.display_image,
                                      date: add(new Date(data.date), {
                                          days: 1,
                                      }),
                                      until_date: add(
                                          new Date(data.until_date),
                                          { days: 1 }
                                      ),
                                      campaign_project_preference_ids:
                                          data.campaign_project_preference_ids,
                                      campaign_project_community_ids:
                                          data.campaign_project_community_ids,
                                      campaign_product_types_ids:
                                          data.campaign_product_types_ids,
                                  },
                              ]
                            : campaigns
                    );

                    navigation("/campaigns");
                },
                onError: (error) => {
                    toast({
                        title: t("Toast.Error"),
                        description: error.message,
                    });
                },
            }
        );
        setIsLoading(false);
    };

    const formData = useWatch({ control });

    return (
        <ContentLayout
            title={t("CreateCampaigns")}
            subTitle={t("CreateCampaignsDescription")}
        >
            <ContentWrapper isLoading={isLoading}>
                <FormProvider {...form}>
                    <TwoColumnLayout
                        leftContent={
                            <Form {...form}>
                                <form
                                    onSubmit={handleSubmit(
                                        handleCreateCampaign
                                    )}
                                    className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-white px-5 py-5 rounded-lg"
                                >
                                    <CampaignForm
                                        deals={deals}
                                        projects={projects}
                                    />
                                    <div className="col-span-1 md:col-span-2 flex justify-end mt-6">
                                        <Button
                                            disabled={isPending}
                                            variant="ghost"
                                            onClick={() =>
                                                navigation("/campaigns")
                                            }
                                        >
                                            {gt("Cancel")}
                                        </Button>
                                        <Button
                                            disabled={isPending}
                                            type="submit"
                                        >
                                            {isPending || isSubmitLoading ? (
                                                <Loader className="animate-spin h-5 w-5" />
                                            ) : (
                                                <>
                                                    {t("CreateCampaign")}
                                                    <Check className="ml-2" />
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </Form>
                        }
                        rightContent={
                            <CampaignPreview
                                data={{
                                    deal_type: formData.deal_type,
                                    details: formData.details,
                                    files: formData.files,
                                    title: formData.title,
                                    campaign_locations:
                                        formData.campaign_locations,
                                }}
                            />
                        }
                    />
                </FormProvider>
            </ContentWrapper>
        </ContentLayout>
    );
};
