import { PrimaryUserPropertyResponse } from "@/schemas";
import { nestApi } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchPrimaryUserProperty = (isCustomer: boolean) => {
    return useQuery({
        queryKey: ["primaryUserProperty"],
        queryFn: async () => {
            const url = `user-properties/primary`;
            const { data } =
                await nestApi.get<PrimaryUserPropertyResponse>(url);
            return data;
        },
        enabled: !!isCustomer,
    });
};
