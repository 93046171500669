import { DataTableColumnHeader } from "@/components/dataTable/DataTableColumnHeader";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { formatDate } from "@/lib/utils";
import type { clientType } from "@/schemas";
import type { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

interface ClientsColumnsProps {
    selected: clientType[];
    setSelected: (selected: clientType[]) => void;
}
export function getClientsColumns({
    selected,
    setSelected,
}: ClientsColumnsProps): ColumnDef<clientType>[] {
    const { t } = useTranslation("client");
    const { t: ut } = useTranslation("user");
    const { t: gt } = useTranslation("general");
    return [
        {
            accessorKey: "id",
            header: ({ table }) => (
                <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                        <Checkbox
                            className="bg-white bg-opacity-50"
                            checked={table.options.data.every((e) =>
                                selected.includes(e)
                            )}
                            onCheckedChange={(value) => {
                                if (value) {
                                    const all = [
                                        ...selected,
                                        ...table.options.data,
                                    ];
                                    const uniqueById = Array.from(
                                        new Map(
                                            all.map((item) => [item.id, item])
                                        ).values()
                                    );
                                    setSelected(uniqueById);
                                } else {
                                    const resultArray = selected.filter(
                                        (e) => !table.options.data.includes(e)
                                    );
                                    setSelected(resultArray);
                                }
                            }}
                        />
                    </TooltipTrigger>
                    <TooltipContent
                        align="start"
                        className="w-[125px] text-center"
                    >
                        {gt("Select.DeAllOnPage")}
                    </TooltipContent>
                </Tooltip>
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex my-auto mr-3 items-center justify-center">
                        <Checkbox
                            checked={
                                selected.filter((c) => c.id === row.original.id)
                                    .length > 0
                            }
                            onCheckedChange={() => {
                                if (
                                    selected.filter(
                                        (c) => c.id === row.original.id
                                    ).length > 0
                                ) {
                                    setSelected(
                                        selected.filter(
                                            (c) => c.id !== row.original.id
                                        )
                                    );
                                } else {
                                    setSelected([...selected, row.original]);
                                }
                            }}
                            aria-label={gt("Select.Select") + t("Client")}
                        />
                    </div>
                );
            },
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "fullname",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={ut("Name")} />
            ),
            cell: ({ row }) => {
                const fullname = row.getValue("fullname");
                return (
                    <div className="flex space-x-2">
                        {fullname as React.ReactNode}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "email",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={ut("Email")} />
            ),
            cell: ({ row }) => <div>{row.getValue("email")}</div>,
            enableHiding: false,
            enableSorting: false,
        },
        {
            accessorKey: "builder_name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Builder")} />
            ),
            cell: ({ row }) => {
                const fullname = row.getValue("builder_name");
                return (
                    <div className="flex space-x-2">
                        {fullname as React.ReactNode}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "project_name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Project")} />
            ),
            cell: ({ row }) => {
                const fullname = row.getValue("project_name");
                return (
                    <div className="flex space-x-2">
                        {fullname as React.ReactNode}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "created_at",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={t("Initiation")}
                />
            ),
            cell: ({ cell }) => formatDate(cell.getValue() as Date),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "mc_name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("HC")} />
            ),
            cell: ({ row }) => {
                const fullname = row.getValue("mc_name");
                return (
                    <div className="flex space-x-2">
                        {fullname as React.ReactNode}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "status",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={ut("Status")} />
            ),
            cell: ({ row }) => {
                const status = row.getValue<string>("status");
                return (
                    <div className="flex items-center">
                        <span className="capitalize">{status}</span>
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
            filterFn: (row, id, value) => {
                return Array.isArray(value) && value.includes(row.getValue(id));
            },
        },
    ];
}
