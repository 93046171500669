import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { useDeleteFile } from "@/hooks/builder/useDeleteFile";
import { useFetchFileHistory } from "@/hooks/builder/useFetchFileHistory";
import { useToast } from "@/hooks/utils/useToast";

import { getFileIcon } from "@/lib/getFileIcon";
import { FormSection } from "@/pages/PropertyPage/components/FormSection";
import {
    FileDownIcon,
    Folder,
    PackageOpenIcon,
    PlusIcon,
    Search,
    Trash2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../../ContentWrapper";

interface FileCabinetProps {
    userId: number;
    setOpenModal: (open: boolean) => void;
}

export const FileCabinet = ({ userId, setOpenModal }: FileCabinetProps) => {
    const { t: ft } = useTranslation("fileCabinet");
    const { t: gt } = useTranslation("general");
    const { data, refetch, isLoading } = useFetchFileHistory({
        userId: Number(userId),
    });
    const { toast } = useToast();
    const { mutateAsync: deleteFile } = useDeleteFile();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleShortcut = (e: KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === "k") {
            e.preventDefault();
            setDialogOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleShortcut);
        return () => {
            window.removeEventListener("keydown", handleShortcut);
        };
    }, []);

    const handleConfirmDelete = (id: number) => {
        deleteFile(id, {
            onSuccess: () => {
                refetch();
            },
            onError: (error: Error) => {
                toast({
                    title: ft("Delete.error"),
                    description: error.message,
                    variant: "destructive",
                    duration: 2000,
                });
            },
        });
    };
    const handleItemSelect = (url: string) => {
        window.open(url, "_blank");
    };

    return (
        <FormSection
            title="File Cabinet"
            description="Upload files to share with this customer."
            className="bg-white px-10 py-5"
            rightContent={
                <Button onClick={() => setOpenModal(true)}>
                    <PlusIcon className="mr-2" />
                    Add File
                </Button>
            }
        >
            <ContentWrapper
                isEmpty={
                    data?.documents && Object.keys(data.documents).length === 0
                }
                isLoading={isLoading}
                className="bg-white "
                emptyIcon={
                    <PackageOpenIcon className="h-40 w-40  stroke-1 opacity-25" />
                }
                emptyTitle="There are no files for this customer."
                emptyMessage={
                    <div className="flex text-gray-500 text-sm">
                        Click&nbsp;
                        <span
                            className="text-secondary cursor-pointer text-sm"
                            onClick={() => setOpenModal(true)}
                        >
                            add files
                        </span>
                        &nbsp; to share documents with this customer.
                    </div>
                }
            >
                {data?.documents && Object.keys(data.documents).length > 0 ? (
                    <>
                        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                            <DialogTrigger asChild>
                                <Button
                                    variant="ghost"
                                    className="border space-x-4"
                                >
                                    <Search />
                                    <span className="flex-1 text-left mr-4">
                                        {ft("Search Files")}
                                    </span>
                                    <kbd className="flex items-center gap-1 rounded border px-1.5 font-mono text-[10px] font-medium opacity-100">
                                        <span className="text-xs">⌘</span>K
                                    </kbd>
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>{ft("Search")}</DialogTitle>
                                </DialogHeader>
                                <Command className="flex h-full w-full flex-col overflow-hidden bg-popover text-popover-foreground rounded-lg border shadow-md md:min-w-[450px]">
                                    <CommandInput placeholder="Search..." />
                                    <CommandList className="mt-2">
                                        <CommandEmpty>
                                            {gt("NoDataFound")}
                                        </CommandEmpty>
                                        {Object.entries(data.documents).map(
                                            ([category, documents]) => (
                                                <CommandGroup
                                                    heading={category}
                                                    key={category}
                                                >
                                                    {documents.map(
                                                        (document) => (
                                                            <CommandItem
                                                                key={`${document.id}-${document.file_name}`}
                                                                onSelect={() =>
                                                                    handleItemSelect(
                                                                        document.url
                                                                    )
                                                                }
                                                            >
                                                                {`${category}/${decodeURIComponent(document.file_name)}`}
                                                            </CommandItem>
                                                        )
                                                    )}
                                                </CommandGroup>
                                            )
                                        )}
                                    </CommandList>
                                </Command>
                            </DialogContent>
                        </Dialog>
                        <Accordion
                            type="single"
                            collapsible
                            className="min-h-72"
                        >
                            {Object.entries(data.documents).map(
                                ([category, documents], index) => (
                                    <AccordionItem value={category} key={index}>
                                        <AccordionTrigger>
                                            <div className="flex justify-between items-center">
                                                <Folder className="mr-2 h-8 w-8" />
                                                {category}
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent className="flex flex-col gap-3 divide-y divide-gray-200">
                                            {documents.map((document) => (
                                                <div
                                                    key={document.id}
                                                    className="gap-2 ml-6 py-4"
                                                >
                                                    <Link
                                                        target="_blank"
                                                        to={document.url}
                                                    >
                                                        <div className="flex gap-2">
                                                            {getFileIcon(
                                                                document.file_name
                                                            )}
                                                            <span className="font-semibold text-lg">
                                                                {decodeURIComponent(
                                                                    document.file_name
                                                                )}
                                                            </span>
                                                        </div>
                                                    </Link>

                                                    <div className="flex mt-2 ml-10 justify-between">
                                                        <div className="flex flex-col gap-2">
                                                            <div>
                                                                <span className="my-4 mx-2 font-bold">
                                                                    {ft(
                                                                        "Description"
                                                                    )}
                                                                    :
                                                                </span>
                                                                <span className="my-4 mx-3">
                                                                    {
                                                                        document.description
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="my-4 mx-2 font-bold">
                                                                    {ft(
                                                                        "UploadDate"
                                                                    )}
                                                                    :
                                                                </span>
                                                                <span className="my-4 mx-3">
                                                                    {
                                                                        document.updated_at.split(
                                                                            "T"
                                                                        )[0]
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="my-4 mx-2 font-bold">
                                                                    {ft(
                                                                        "UploadBy"
                                                                    )}
                                                                    :
                                                                </span>
                                                                <span className="my-4 mx-3">
                                                                    {
                                                                        document.owner_name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col gap-2">
                                                            <div>
                                                                <span className="my-4 mx-2 font-bold">
                                                                    {ft(
                                                                        "Title"
                                                                    )}
                                                                    :
                                                                </span>
                                                                <span className="my-4 mx-3">
                                                                    {
                                                                        document.title
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="my-4 mx-2 font-bold">
                                                                    {ft(
                                                                        "Property"
                                                                    )}
                                                                    :
                                                                </span>
                                                                <span className="my-4 mx-3">
                                                                    {
                                                                        document.user_property
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="flex gap-2 items-center">
                                                            <Link
                                                                to={
                                                                    document.url
                                                                }
                                                            >
                                                                <FileDownIcon className="mr-2 h-6 w-6" />
                                                            </Link>

                                                            <AlertDialog>
                                                                <AlertDialogTrigger
                                                                    asChild
                                                                >
                                                                    <Trash2 className="stroke-red h-5 cursor-pointer flex m-auto" />
                                                                </AlertDialogTrigger>
                                                                <AlertDialogContent>
                                                                    <AlertDialogHeader>
                                                                        <AlertDialogTitle>
                                                                            {ft(
                                                                                "Delete.title"
                                                                            )}
                                                                        </AlertDialogTitle>
                                                                        <AlertDialogDescription>
                                                                            {ft(
                                                                                "Delete.message"
                                                                            )}
                                                                        </AlertDialogDescription>
                                                                    </AlertDialogHeader>
                                                                    <AlertDialogFooter>
                                                                        <AlertDialogCancel
                                                                            asChild
                                                                        >
                                                                            <Button className="text-gray-500">
                                                                                {gt(
                                                                                    "Cancel"
                                                                                )}
                                                                            </Button>
                                                                        </AlertDialogCancel>
                                                                        <AlertDialogAction
                                                                            asChild
                                                                        >
                                                                            <Button
                                                                                className="bg-red-600 hover:bg-red-600/80"
                                                                                onClick={() =>
                                                                                    handleConfirmDelete(
                                                                                        document.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {gt(
                                                                                    "Confirm"
                                                                                )}
                                                                            </Button>
                                                                        </AlertDialogAction>
                                                                    </AlertDialogFooter>
                                                                </AlertDialogContent>
                                                            </AlertDialog>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </AccordionContent>
                                    </AccordionItem>
                                )
                            )}
                        </Accordion>
                    </>
                ) : (
                    <></>
                )}
            </ContentWrapper>
        </FormSection>
    );
};
