import { FilterBubble } from "@/components/FilterBubble";
import { SearchBox } from "@/components/SearchBox";
import { useFetchSearchedCustomers } from "@/hooks/layout/useFetchSearchedCustomers";
import { useFetchSuggestedCustomers } from "@/hooks/layout/useFetchSuggestedCustomers";
import { useAuth } from "@/hooks/login/useAuth";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function StaffSearch() {
    const { t } = useTranslation("general");
    const { user, isStaff } = useAuth();

    const filterOptions = [
        { label: t("FilterOptions.All"), value: "all" },
        { label: t("FilterOptions.FileId"), value: "file_id" },
        { label: t("FilterOptions.Name"), value: "name" },
        { label: t("FilterOptions.Email"), value: "email" },
        { label: t("FilterOptions.Phone"), value: "phone_number" },
        { label: t("FilterOptions.Address"), value: "address" },
        { label: t("FilterOptions.SoNumber"), value: "so_number" },
    ];

    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState(filterOptions[0]);

    const { data, isLoading } = useFetchSearchedCustomers({
        search_value: searchValue,
        staff_id: user.id,
        search_type: filter.value,
        isEnabled: isStaff,
    });

    const { data: suggestionData, isLoading: isLoadingSuggestion } =
        useFetchSuggestedCustomers({ user_id: user.id, isEnabled: isStaff });

    return (
        <>
            <FilterBubble
                title={t("Search.SearchBy")}
                data={filterOptions}
                selected={filter}
                setFilter={setFilter}
                useIcon={true}
            />
            <SearchBox
                isLoading={isLoading || isLoadingSuggestion}
                data={searchValue ? data : suggestionData}
                setSearchValue={setSearchValue}
                className="w-[150px] sm:w-[250px]"
            />
        </>
    );
}
