import { ProjectDetailsArray } from "@/schemas/projectDetailsSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./login/useAuth";

export const useFetchProjects = () => {
    const { user } = useAuth();

    return useQuery({
        queryKey: ["projects"],
        queryFn: async () => {
            const { data } = await api.get<ProjectDetailsArray>("v1/projects", {
                params: {
                    builder_account_id: user?.builder_accounts[0].id,
                },
            });
            return data;
        },
    });
};
