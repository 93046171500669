import { FileUploader } from "@/components/FileUpload";
import { MultiSelect } from "@/components/MultiSelect";
import { SearchableSelect } from "@/components/ui/createable-select";
import { DatePicker } from "@/components/ui/datePicker";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { buyerTypes } from "@/constants/general";
import { HOME_TRANSITION_CATEGORIES } from "@/constants/homeTransitionCategories";
import { useCreateResourceCategory } from "@/hooks/builder/useCreateResourceCategory";
import type { VirtuoLibraryResourcesSchema } from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import type { CommunitySchemaType } from "@/schemas/communitySchema";
import type { PhaseType } from "@/schemas/phaseSchema";
import type { ProductTypeType } from "@/schemas/productTypeSchema";
import type { Resource } from "@/schemas/resourceSchema";
import { DevTool } from "@hookform/devtools";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ResourceFormProps {
    folders: Resource[] | undefined;
    communities: CommunitySchemaType[] | undefined;
    phases: PhaseType[] | undefined;
    productTypes: ProductTypeType[] | undefined;
}

export const ResourceForm = ({
    folders,
    communities,
    productTypes,
}: ResourceFormProps) => {
    const { t } = useTranslation("resource");
    const { control } = useFormContext<VirtuoLibraryResourcesSchema>();
    const { mutateAsync: createResourceCategoryAsync } =
        useCreateResourceCategory();

    const formattedProductTypes = productTypes?.map((productType) => ({
        label: productType.product_type,
        value: productType.id.toString(),
    }));

    const formattedCommunityOptions = communities?.map((community) => ({
        label: community.community,
        value: community.id.toString(),
    }));

    const formattedFolders = folders?.map((folder) => ({
        label: folder.category_name,
        value: folder.id.toString(),
    }));

    return (
        <div className="space-y-6 max-w-[1200px]">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <FormField
                    control={control}
                    name="title"
                    render={({ field }) => (
                        <FormItem className="flex-1">
                            <FormLabel>{t("Title")}</FormLabel>
                            <FormControl>
                                <Input placeholder={t("Title")} {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name="expiryDate"
                    render={({ field }) => (
                        <FormItem className="flex-1">
                            <FormLabel>{t("ExpiryDate")}</FormLabel>
                            <FormControl>
                                <DatePicker
                                    date={
                                        field.value
                                            ? new Date(field.value)
                                            : undefined
                                    }
                                    setDate={field.onChange}
                                    className="w-full"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name="resourceCategoryId"
                    render={({ field }) => {
                        return (
                            <FormItem className="flex-1">
                                <FormLabel>{t("Folder")}</FormLabel>
                                <FormControl>
                                    <SearchableSelect
                                        options={formattedFolders ?? []}
                                        value={
                                            field.value
                                                ? field.value.toString()
                                                : ""
                                        }
                                        onChange={(selected) => {
                                            field.onChange(
                                                selected
                                                    ? Number(selected)
                                                    : null
                                            );
                                        }}
                                        onOptionCreate={async (newOption) => {
                                            const newResource =
                                                await createResourceCategoryAsync(
                                                    {
                                                        categoryName:
                                                            newOption.label,
                                                    }
                                                );

                                            if (!newResource) return null;
                                            return {
                                                value: newResource.id.toString(),
                                                label:
                                                    newResource.categoryName ??
                                                    "",
                                            };
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />
            </div>

            <div className="space-y-4">
                <FormField
                    control={control}
                    name="projectCommunity"
                    render={({ field, fieldState }) => {
                        return (
                            <FormItem>
                                <FormLabel>{t("Communities")}</FormLabel>
                                <FormControl>
                                    <MultiSelect
                                        options={
                                            formattedCommunityOptions ?? []
                                        }
                                        onValueChange={(selectedIds) => {
                                            const updatedCommunities =
                                                selectedIds.map((id) => ({
                                                    id: Number(id),
                                                    community:
                                                        formattedCommunityOptions?.find(
                                                            (c) =>
                                                                c.value === id
                                                        )?.label || "",
                                                }));
                                            field.onChange(updatedCommunities);
                                        }}
                                        value={
                                            field.value?.map((community) =>
                                                community.id.toString()
                                            ) ?? []
                                        }
                                        defaultValue={
                                            field.value?.map((community) =>
                                                community.id.toString()
                                            ) ?? []
                                        }
                                        placeholder="Select communities"
                                        variant="inverted"
                                    />
                                </FormControl>
                                {fieldState.error && (
                                    <FormMessage>
                                        {fieldState.error.message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={control}
                    name="phase"
                    render={({ field, fieldState }) => {
                        return (
                            <FormItem>
                                <FormLabel>{t("Phases")}</FormLabel>
                                <FormControl>
                                    <MultiSelect
                                        options={HOME_TRANSITION_CATEGORIES}
                                        onValueChange={(selectedIds) => {
                                            const updatedPhases =
                                                selectedIds.map((id) => ({
                                                    id: Number(id),
                                                    phase:
                                                        HOME_TRANSITION_CATEGORIES.find(
                                                            (c) =>
                                                                c.value === id
                                                        )?.label || "",
                                                }));
                                            field.onChange(updatedPhases);
                                        }}
                                        defaultValue={
                                            field.value?.map((phase) =>
                                                phase.id.toString()
                                            ) ?? []
                                        }
                                        value={
                                            field.value?.map((phase) =>
                                                phase.id.toString()
                                            ) ?? []
                                        }
                                        placeholder="Select options"
                                        variant="inverted"
                                    />
                                </FormControl>
                                {fieldState.error && (
                                    <FormMessage>
                                        {fieldState.error.message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        );
                    }}
                />
            </div>

            {/* Product Types and Buyer Types */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormField
                    control={control}
                    name="productType"
                    render={({ field, fieldState }) => {
                        return (
                            <FormItem>
                                <FormLabel>{t("ProductTypes")}</FormLabel>
                                <FormControl>
                                    <MultiSelect
                                        options={formattedProductTypes ?? []}
                                        onValueChange={(selectedIds) => {
                                            const updatedProductTypes =
                                                selectedIds.map((id) => ({
                                                    id: Number(id),
                                                    productType:
                                                        formattedProductTypes?.find(
                                                            (p) =>
                                                                p.value === id
                                                        )?.label || "",
                                                }));
                                            field.onChange(updatedProductTypes);
                                        }}
                                        defaultValue={
                                            field.value?.map((productType) =>
                                                productType.id.toString()
                                            ) ?? []
                                        }
                                        value={
                                            field.value?.map((option) =>
                                                option.id.toString()
                                            ) ?? []
                                        }
                                        placeholder="Select product types"
                                        variant="inverted"
                                    />
                                </FormControl>
                                {fieldState.error && (
                                    <FormMessage>
                                        {fieldState.error.message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={control}
                    name="buyerTypes"
                    render={({ field, fieldState }) => {
                        return (
                            <FormItem>
                                <FormLabel>{t("BuyerTypes")}</FormLabel>
                                <FormControl>
                                    <MultiSelect
                                        options={buyerTypes}
                                        onValueChange={(selectedValues) => {
                                            field.onChange(selectedValues);
                                        }}
                                        value={field.value ?? []}
                                        placeholder="Select buyer types"
                                        defaultValue={field.value ?? []}
                                        variant="inverted"
                                    />
                                </FormControl>
                                {fieldState.error && (
                                    <FormMessage>
                                        {fieldState.error.message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        );
                    }}
                />
            </div>

            {/* Description */}
            <FormField
                control={control}
                name="description"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>{t("Description")}</FormLabel>
                        <FormControl>
                            <Textarea
                                placeholder={t("Description")}
                                {...field}
                                className="h-[135px] resize-none"
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            {/* Resource File and URL */}
            <div className="space-y-4">
                <FormField
                    control={control}
                    name="files"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("ResourceFile")}</FormLabel>
                            <FormControl>
                                <FileUploader
                                    onValueChange={field.onChange}
                                    maxFileCount={1}
                                    maxSize={50 * 1024 * 1024} // 50 MB
                                    accept={{
                                        "application/pdf": [".pdf"],
                                        "image/*": [
                                            ".jpg",
                                            ".jpeg",
                                            ".png",
                                            ".webp",
                                        ],
                                    }}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name="resourceUrl"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("ResourceUrl")}</FormLabel>
                            <FormControl>
                                <Input
                                    {...field}
                                    placeholder={t("ResourceUrl")}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <DevTool control={control} />
        </div>
    );
};
