import { AppUserSpecs } from "@/constants/appUserSpec";
import { useAuth } from "@/hooks/login/useAuth";
import { LucideProps } from "lucide-react";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { accountMenuList } from "./accountMenuList";

type Submenu = {
    href: string;
    label: string;
    active: boolean;
};

type Menu = {
    href: string;
    label: string;
    active: boolean;
    icon: ForwardRefExoticComponent<
        Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>
    >;
    submenus: Submenu[];
};

export type MenuGroup = {
    groupLabel: string;
    menus: Menu[];
};

export function getMenuList(pathname: string): MenuGroup[] {
    const { appUserType } = useAuth();

    const menuFunction = appUserType
        ? AppUserSpecs[appUserType].menuFunction
        : accountMenuList;

    return menuFunction(pathname);
}
