import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface CustomerCareResponse {
    care_name: string | null | undefined;
    care_email: string | null | undefined;
    care_phone: string | null | undefined;
}

export const useFetchBuilderDetails = (builder_account_id: number) => {
    return useQuery({
        queryKey: ["builderDetails", builder_account_id],
        queryFn: async () => {
            const { data } = await api.get<CustomerCareResponse>(
                "v1/builder_accounts/builder_account_details",
                { params: { builder_account_id } }
            );
            return data;
        },
    });
};
