import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useSearchAddress } from "@/hooks/utils/useSearchAddress";
import { cn } from "@/lib/utils";
import { CommandLoading } from "cmdk";
import { Check, ChevronsUpDown } from "lucide-react";
import { HTMLAttributes, forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SearchAddressProps extends HTMLAttributes<HTMLDivElement> {
    onSelectLocation: (
        location: { lat: number; lng: number } | null,
        item: google.maps.places.AutocompletePrediction | null,
        placeDetails: google.maps.places.PlaceResult | null
    ) => void;
    initialValue?: string;
}

export const SearchAddress = forwardRef<HTMLDivElement, SearchAddressProps>(
    ({ onSelectLocation, initialValue, ...rest }, ref) => {
        const [open, setOpen] = useState(false);
        const [value, setValue] = useState("");
        const { t } = useTranslation("general");

        const {
            results,
            loading,
            handleSearch,
            selectedItem,
            setSelectedItem,
        } = useSearchAddress();

        const handleSelect = async (placeId: string) => {
            const service = new google.maps.places.PlacesService(
                document.createElement("div")
            );
            service.getDetails({ placeId }, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place?.geometry?.location
                ) {
                    const location = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    };
                    const selectedItem =
                        results.find((result) => result.place_id === placeId) ||
                        null;
                    setSelectedItem(selectedItem);
                    onSelectLocation(location, selectedItem, place);
                }
            });
        };

        useEffect(() => {
            if (initialValue !== undefined && initialValue !== null) {
                setValue(initialValue);
            }
        }, [initialValue]);

        const message = selectedItem
            ? selectedItem.description
            : initialValue
              ? initialValue
              : `${t("Select.Address")}...`;

        const isPlaceholder = message === `${t("Select.Address")}...`;
        return (
            <div {...rest} ref={ref}>
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            data-testid="SearchAddressPopover"
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className="w-full justify-between truncate"
                        >
                            <p
                                className={cn(
                                    "truncate",
                                    isPlaceholder && "text-grey-text/30"
                                )}
                            >
                                {message}
                            </p>
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80 p-0">
                        <Command>
                            <CommandInput
                                placeholder="Search the place..."
                                onValueChange={(value) => handleSearch(value)}
                                className="w-full"
                            />
                            <CommandList>
                                {loading ? (
                                    <CommandLoading>
                                        <CommandEmpty>Loading...</CommandEmpty>
                                    </CommandLoading>
                                ) : results.length > 0 ? (
                                    <CommandGroup heading="Results">
                                        {results.map((item, index) => (
                                            <CommandItem
                                                key={index}
                                                value={item.description}
                                                onSelect={(
                                                    currentValue: string
                                                ) => {
                                                    const selectedItem =
                                                        results.find(
                                                            (result) =>
                                                                result.description ===
                                                                currentValue
                                                        );
                                                    if (selectedItem) {
                                                        handleSelect(
                                                            selectedItem.place_id
                                                        );
                                                    }
                                                    setValue(
                                                        currentValue === value
                                                            ? ""
                                                            : currentValue
                                                    );
                                                    setOpen(false);
                                                }}
                                            >
                                                <Check
                                                    className={cn(
                                                        "mr-2 h-4 w-4",
                                                        value ===
                                                            item.description
                                                            ? "opacity-100"
                                                            : "opacity-0"
                                                    )}
                                                />
                                                {item.description}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                ) : (
                                    <CommandEmpty>
                                        {t("NoDataFound")}
                                    </CommandEmpty>
                                )}
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }
);

SearchAddress.displayName = "SearchAddress";
