import type { DataTableFilterField } from "@/@types/tableTypes";
import { DataTableFacetedFilter } from "@/components/dataTable/DataTableFacetedFilter";
import { DataTableViewOptions } from "@/components/dataTable/DataTableViewOptions";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Cross2Icon } from "@radix-ui/react-icons";
import type { Table } from "@tanstack/react-table";
import { Search } from "lucide-react";
import { type HTMLAttributes, useMemo } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface DataTableToolbarProps<TData> extends HTMLAttributes<HTMLDivElement> {
    table: Table<TData>;
    filterFields?: DataTableFilterField<TData>[];
}

export function DataTableToolbar<TData>({
    table,
    filterFields = [],
    children,
    className,
    ...props
}: DataTableToolbarProps<TData>) {
    const isFiltered = table.getState().columnFilters.length > 0;

    const { searchableColumns, filterableColumns } = useMemo(() => {
        return {
            searchableColumns: filterFields.filter((field) => !field.options),
            filterableColumns: filterFields.filter((field) => field.options),
        };
    }, [filterFields]);

    return (
        <div
            className={cn(
                "flex w-full items-center justify-between space-x-2 overflow-auto",
                className
            )}
            {...props}
        >
            <div className="flex flex-1 items-center space-x-2">
                {searchableColumns.length > 0 &&
                    searchableColumns.map(
                        (column) =>
                            table.getColumn(
                                column.value ? String(column.value) : ""
                            ) && (
                                <div
                                    key={String(column.value)}
                                    className="relative flex items-center"
                                >
                                    {column.tooltip ? (
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger className="absolute ml-3 text-gray-text">
                                                <Search className=" text-gray-text" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {column.tooltip}
                                            </TooltipContent>
                                        </Tooltip>
                                    ) : (
                                        <Search className="absolute ml-3 text-gray-text" />
                                    )}
                                    <Input
                                        placeholder={column.placeholder}
                                        value={
                                            (table
                                                .getColumn(String(column.value))
                                                ?.getFilterValue() as string) ??
                                            ""
                                        }
                                        onChange={(event) =>
                                            table
                                                .getColumn(String(column.value))
                                                ?.setFilterValue(
                                                    event.target.value
                                                )
                                        }
                                        className={cn(
                                            "h-10 w-40 lg:w-64 pl-10 bg-white placeholder:text-base text-base text-grey-text",
                                            column.className
                                        )}
                                    />
                                </div>
                            )
                    )}
                {filterableColumns.length > 0 &&
                    filterableColumns.map(
                        (column) =>
                            table.getColumn(
                                column.value ? String(column.value) : ""
                            ) && (
                                <DataTableFacetedFilter
                                    key={String(column.value)}
                                    column={table.getColumn(
                                        column.value ? String(column.value) : ""
                                    )}
                                    title={column.label}
                                    options={column.options ?? []}
                                    disableMultiSelect={
                                        column.disableMultiSelect
                                    }
                                />
                            )
                    )}
                {isFiltered && (
                    <Button
                        aria-label="Reset filters"
                        variant="ghost"
                        className="h-8 px-2 lg:px-3"
                        onClick={() => table.resetColumnFilters()}
                    >
                        Reset
                        <Cross2Icon
                            className="ml-2 size-4"
                            aria-hidden="true"
                        />
                    </Button>
                )}
            </div>
            <div className="flex items-center gap-2">
                {children}
                <DataTableViewOptions table={table} />
            </div>
        </div>
    );
}
