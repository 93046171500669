import { cn } from "@/lib/utils";
import { FC, ReactNode } from "react";

interface TwoColumnLayoutProps {
    leftContent: ReactNode;
    rightContent: ReactNode;
    className?: string;
    classNameRight?: string;
}

export const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({
    leftContent,
    rightContent,
    classNameRight,
    className,
}) => {
    return (
        <div className={cn("flex flex-col md:flex-row gap-8", className)}>
            <div className="w-full md:w-2/3">{leftContent}</div>
            <div className={cn("w-full md:w-1/3", classNameRight)}>
                {rightContent}
            </div>
        </div>
    );
};
