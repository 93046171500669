import {
    DropdownMenuContent,
    DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useFetchPrimaryUserProperty } from "@/hooks/useFetchPrimaryUserProperty";
import { LoginResponseSchema } from "@/schemas";
import { LogOut, Settings, UserRound } from "lucide-react";
import { startTransition } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { UserPropertyAddress, WelcomeMessage } from "../CustomerBannerMessage";
import { UserAvatar } from "../ui/avatar";
import { Button } from "../ui/button";

type NavOption = {
    Icon: React.ElementType;
    title: string;
    onClick?: () => void;
};
interface CustomerNavOptionsProps {
    navigate: NavigateFunction;
    handleLogout: () => void;
}

const CustomerNavOptions = ({
    navigate,
    handleLogout,
}: CustomerNavOptionsProps) => {
    const { t } = useTranslation("general");

    const customerNavOptions: NavOption[] = [
        { Icon: UserRound, title: t("Navbar.Profile") },
        { Icon: Settings, title: t("Navbar.Settings") },
        {
            Icon: LogOut,
            title: t("Login.Logout"),
            onClick: () => {
                handleLogout();
                navigate("/");
            },
        },
    ];

    return (
        <div className="flex items-center justify-between h-16 w-full">
            {customerNavOptions.map((option) => {
                const Icon = option.Icon;

                return (
                    <DropdownMenuItem
                        onClick={() => {
                            startTransition(() => {
                                option.onClick && option.onClick();
                            });
                        }}
                        className="hover:cursor-pointer flex flex-col gap-2 p-2.5 w-[74px]"
                        key={option.title}
                    >
                        <Icon className="w-6 h-6 text-primary shrink-0" />
                        <div className="text-primary">{option.title}</div>
                    </DropdownMenuItem>
                );
            })}
        </div>
    );
};

interface CustomerNavDropdownProps {
    user: LoginResponseSchema;
    navigate: NavigateFunction;
    handleLogout: () => void;
}

export function CustomerNavDropdown({
    user,
    navigate,
    handleLogout,
}: CustomerNavDropdownProps) {
    const { data: primaryUserPropertyDetails } =
        useFetchPrimaryUserProperty(true);

    return (
        <DropdownMenuContent
            className="flex flex-col w-72 h-[300px] p-5 gap-4 items-center justify-center rounded-2xl bg-white shadow-[0px_4px_16px_0px_rgba(0,0,0,0.25)] border-none"
            align="end"
            forceMount
        >
            <Button
                variant="outlineNew"
                className="relative rounded-full h-14 w-14 cursor-default"
            >
                <UserAvatar
                    firstName={user.first_name}
                    lastName={user.last_name}
                    isCustomer
                />
            </Button>
            <WelcomeMessage>{`${user.first_name} ${user.last_name}`}</WelcomeMessage>
            {primaryUserPropertyDetails?.address && (
                <UserPropertyAddress
                    formattedAddress={primaryUserPropertyDetails?.address}
                />
            )}
            <CustomerNavOptions
                handleLogout={handleLogout}
                navigate={navigate}
            />
        </DropdownMenuContent>
    );
}
