import { Button } from "@/components/ui/button";
import React from "react";
import { SkeletonMenu } from "./SkeletonMenu";

export const SkeletonSidebar: React.FC = () => {
    return (
        <aside
            data-testid="sidebar"
            className="fixed top-[68px] left-0 h-screen w-62 transition-[width] ease-in-out duration-300 bg-white"
        >
            <div className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800 border-r">
                <Button
                    className="transition-transform ease-in-out duration-300 mb-1"
                    variant="link"
                    asChild
                ></Button>
                <SkeletonMenu />
            </div>
        </aside>
    );
};
