import { CommunitySchemaType } from "@/schemas/communitySchema";
import { nestApi } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchCommunities = () => {
    return useQuery({
        queryKey: ["communities"],
        queryFn: async () => {
            const { data } =
                await nestApi.get<CommunitySchemaType[]>("communities");
            return data;
        },
    });
};
