import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDeleteCampaigns } from "@/hooks/builder/useDeleteCampaigns";
import { cn } from "@/lib/utils";
import { CampaignSchemaType } from "@/schemas/builders/campaignSchema";
import { ColumnDef } from "@tanstack/react-table";
import { Archive, Circle, Edit, Loader } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTableColumnHeader } from "../../dataTable/DataTableColumnHeader";

export function getCampaignsTableColumns(): ColumnDef<CampaignSchemaType>[] {
    const { t } = useTranslation("campaign");
    const { t: gt } = useTranslation("general");
    const navigate = useNavigate();
    const { mutateAsync, isPending } = useDeleteCampaigns();

    const isValid = (campaign: CampaignSchemaType) => {
        if (!campaign.publish || campaign.should_archive) {
            return false;
        } else {
            const today = new Date();
            if (campaign.deal_type === "Deal") {
                const startDate = new Date(campaign.date);
                const endDate = new Date(campaign.until_date);
                return startDate <= today && endDate >= today;
            }
            if (campaign.deal_type === "Event") {
                const date = new Date(campaign.date);
                return date >= today;
            }
        }
        return false;
    };

    return [
        {
            accessorKey: "title",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Title")} />
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("StartDate")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-center">
                        {row.getValue("date") ?? ""}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "until_date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("EndDate")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-center">
                        {row.getValue("until_date") ?? ""}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "deal_type",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Type")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-center">
                        {row.getValue("deal_type") ?? ""}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "conversion",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={t("Conversion")}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-center">
                        {row.getValue("conversion") ?? 0}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "publish",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Status")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-1 items-center">
                        <Circle
                            size={14}
                            className={cn(
                                isValid(row.original)
                                    ? "stroke-green fill-green"
                                    : "stroke-grey fill-grey"
                            )}
                        />
                        {isValid(row.original) ? "Active" : "Inactive"}
                    </div>
                );
            },
            enableSorting: false,
            enableHiding: false,
            filterFn: (row, _, filterValue) => {
                const val = isValid(row.original) ? "Active" : "Inactive";
                return val === filterValue[0];
            },
        },
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Actions" />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex w-12 justify-between">
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                                <Edit
                                    size={18}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigate(`edit/${row.original.id}`)
                                    }
                                />
                            </TooltipTrigger>
                            <TooltipContent>{gt("Edit")}</TooltipContent>
                        </Tooltip>

                        <AlertDialog>
                            <AlertDialogTrigger className="flex items-center">
                                <Tooltip delayDuration={0}>
                                    <TooltipTrigger>
                                        <Archive
                                            size={18}
                                            className="cursor-pointer"
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {gt("Archive")}
                                    </TooltipContent>
                                </Tooltip>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle className="flex flex-row gap-1 items-center flex-wrap leading-[20px] mb-3">
                                        {t("ArchiveTitle")}
                                        <span className="text-primary self-end">
                                            {row.original.title}
                                        </span>
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                        {t("ArchiveDescription")}
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>
                                        {gt("Cancel")}
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                        onClick={() =>
                                            mutateAsync(row.original.id)
                                        }
                                    >
                                        {isPending ? (
                                            <Loader className="animate-spin h-5 w-5" />
                                        ) : (
                                            gt("Active")
                                        )}
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                );
            },
            enableSorting: false,
            enableHiding: false,
        },
    ];
}
