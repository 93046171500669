import axios from "axios";

const { VITE_APP_API, VITE_APP_NEST_API } = import.meta.env;

export const api = axios.create({
    baseURL: VITE_APP_API,
});

api.interceptors.request.use((config) => {
    config.params = {
        ...config.params,
        auth_token: localStorage.getItem("token"),
    };
    return config;
});

export const nestApi = axios.create({
    baseURL: VITE_APP_NEST_API,
});

nestApi.interceptors.request.use((config) => {
    config.headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
    );
    return config;
});
