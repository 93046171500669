import { UserSchemaType } from "@/schemas/userSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchUserByIdProps {
    id: number;
}

export const useFetchUserById = ({ id }: FetchUserByIdProps) => {
    return useQuery({
        queryKey: ["user", id],
        queryFn: async () => {
            const { data } = await api.get<UserSchemaType>(`v2/users/${id}`);
            return data;
        },
        enabled: !!id,
    });
};
