import { DatePickerLink } from "@/components/ui/datePickerLink.tsx";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { BuilderClientType } from "@/schemas/builders/builderClientSchema";
import { ColumnDef } from "@tanstack/react-table";
import { Archive, CalendarCheck, Edit } from "lucide-react";
import { useTranslation } from "react-i18next";
import { DataTableColumnHeader } from "../../dataTable/DataTableColumnHeader";

interface PropertyTableProps {
    handleOpenDrawer: (tab: string, property: BuilderClientType) => void;
    handleChangePossessionDate: (
        val: Date | null,
        property: BuilderClientType
    ) => void;
}

export function getPropertyTableColumns({
    handleOpenDrawer,
    handleChangePossessionDate,
}: PropertyTableProps): ColumnDef<BuilderClientType>[] {
    const { t: gt } = useTranslation("general");

    return [
        {
            accessorKey: "customer_id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={gt("InternalIdentifier")}
                />
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "fullname",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={gt("Name")} />
            ),
            cell: ({ row }) => (
                <div className="max-w-44 truncate">
                    {row.getValue("fullname")}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "property.formatted_address",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={gt("Address")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="max-w-44  truncate">
                        {row.original.property.formatted_address}
                    </div>
                );
            },
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "key_dates.possession_date",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={gt("PossessionDate")}
                />
            ),

            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "initiation_date",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={gt("InitiationDate")}
                />
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={gt("Actions")} />
            ),
            cell: ({ row }) => {
                const posDate = new Date(
                    row.original.key_dates.possession_date
                );
                return (
                    <div className="flex gap-3 max-w-[100px]">
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                                <Edit
                                    size={18}
                                    className="cursor-pointer "
                                    onClick={() =>
                                        handleOpenDrawer(
                                            "property",
                                            row.original
                                        )
                                    }
                                />
                            </TooltipTrigger>
                            <TooltipContent>{gt("Edit")}</TooltipContent>
                        </Tooltip>

                        <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                                <Archive
                                    onClick={() =>
                                        handleOpenDrawer(
                                            "file-cabinet",
                                            row.original
                                        )
                                    }
                                    size={18}
                                    className="cursor-pointer"
                                />
                            </TooltipTrigger>
                            <TooltipContent>{gt("FileCabinet")}</TooltipContent>
                        </Tooltip>
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                                <DatePickerLink
                                    date={posDate}
                                    setDate={(val) => {
                                        handleChangePossessionDate(
                                            val,
                                            row.original
                                        );
                                    }}
                                    clearable={true}
                                    trigger={
                                        <CalendarCheck
                                            className="cursor-pointer"
                                            size={18}
                                        />
                                    }
                                    header={
                                        <div className="flex justify-center pt-2">
                                            {gt("PossessionDateEdit")}
                                        </div>
                                    }
                                />
                            </TooltipTrigger>
                            <TooltipContent>
                                {gt("PossessionDateEdit")}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                );
            },
            enableSorting: false,
            enableHiding: false,
        },
    ];
}
