import { ContentLayout } from "@/components/ContentLayout";
import { ContentWrapper } from "@/components/ContentWrapper";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useCreateResource as useCreateResource } from "@/hooks/builder/useCreateNewBuilderResource";
import { useFetchPhases } from "@/hooks/builder/useFetchPhases";
import { useFetchResourceCategories } from "@/hooks/builder/useFetchResourceCategories";
import { useAuth } from "@/hooks/login/useAuth";
import { useFetchCommunities } from "@/hooks/options/useFetchCommunities";
import { useFetchProductTypes } from "@/hooks/options/useFetchProductTypes";
import { useToast } from "@/hooks/utils/useToast";
import {
    CreateVirtuoLibraryResources,
    virtuoLibraryResourcesCreateSchema,
} from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "lucide-react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResourceForm } from "./components/ResourceForm";

export const CreateResourcePage = () => {
    const { mutateAsync, isPending: isPendingCreate } = useCreateResource();
    const { t } = useTranslation("resource");
    const { toast } = useToast();
    const navigate = useNavigate();

    const { t: gt } = useTranslation("general");
    const { user } = useAuth();
    const builder = user?.builder_accounts[0];
    const { data: folders, isLoading: isLoadingFolders } =
        useFetchResourceCategories(builder?.id);
    const { data: communities, isLoading: isLoadingCommunities } =
        useFetchCommunities();
    const { data: phases, isLoading: isLoadingPhases } = useFetchPhases();
    const { data: productTypes, isLoading: isLoadingProductTypes } =
        useFetchProductTypes();

    const isPending = isPendingCreate;
    const isLoading =
        isLoadingFolders ||
        isLoadingCommunities ||
        isLoadingPhases ||
        isLoadingProductTypes;

    const form = useForm<CreateVirtuoLibraryResources>({
        resolver: zodResolver(virtuoLibraryResourcesCreateSchema),
    });

    useEffect(() => {
        form.reset();
    }, []);

    const handleCreateResource = async (data: CreateVirtuoLibraryResources) => {
        data.builderAccountId = builder.id;
        await mutateAsync(data, {
            onSuccess: () => {
                toast({
                    title: t("Toast.ResourceCreated"),
                    description: t("Toast.ResourceCreatedDescription"),
                    duration: 300,
                });
                navigate("/resources");
            },
            onError: (error) => {
                toast({
                    title: "Error",
                    description: error.message,
                    duration: 3000,
                });
            },
        });
    };

    const { handleSubmit } = form;

    return (
        <ContentLayout title={t("PageTitle")} subTitle={t("PageDescription")}>
            <TwoColumnLayout
                leftContent={
                    <FormProvider {...form}>
                        <Form {...form}>
                            <form
                                onSubmit={handleSubmit(handleCreateResource)}
                                className="bg-white p-10 rounded-lg flex flex-col"
                            >
                                <ContentWrapper
                                    isLoading={isLoading}
                                    className="bg-white"
                                >
                                    <>
                                        <ResourceForm
                                            folders={folders}
                                            communities={communities}
                                            phases={phases}
                                            productTypes={productTypes}
                                        />
                                        <div className="flex justify-end mt-6">
                                            <Button
                                                disabled={isPending}
                                                variant="ghost"
                                                onClick={() => navigate("/")}
                                            >
                                                {gt("Cancel")}
                                            </Button>
                                            <Button
                                                disabled={isPending}
                                                type="submit"
                                            >
                                                {isLoading ? (
                                                    <Loader className="animate-spin h-5 w-5 mr-2" />
                                                ) : (
                                                    t("CreateResource")
                                                )}
                                            </Button>
                                        </div>
                                    </>
                                </ContentWrapper>
                            </form>
                        </Form>
                    </FormProvider>
                }
                rightContent={<SupportCard />}
            />
        </ContentLayout>
    );
};
