import { z } from "zod";
import { BuilderAccountSchema } from "./builders/builderAccountSchema";
import { CommunitySchema } from "./communitySchema";
import { ContactSchema } from "./contactSchema";
import { ProjectBillingSchema } from "./projectBillingSchema";
import { ProjectCreditSchema } from "./projectCreditSchema";
import { ProjectServiceSchema } from "./projectServiceSchema";
import { TemplateSchema } from "./templateSchema";

export const ProjectDetailsSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    builder_account_id: z.number(),
    active: z.boolean(),
    default_tax_rate: z.number().nullable(),
    location_id: z.number().nullable(),
    alias_name: z.string(),
    modified_at: z.string(),
    default_hc: z.number(),
    template_id: z.number(),
    maintenance_template_id: z.number(),
    default_property_id: z.number(),
    project_image: z.string().nullable(),
    is_customer_care_enabled: z.boolean().nullable(),
    is_builder_signal_enabled: z.boolean(),
    notes: z.string().nullable(),
    updated_at: z.string(),
    is_reward_eligible: z.boolean(),
    reward_eligible_at: z.string().nullable(),
    builder_account: BuilderAccountSchema,
    project_services: z.array(ProjectServiceSchema).optional(),
    project_communities: z.array(CommunitySchema).optional(),
    template: TemplateSchema,
    project_billing: ProjectBillingSchema.optional(),
    contacts: z.array(ContactSchema),
    attachments: z.any().nullable(),
    project_credits: z.array(ProjectCreditSchema),
    milestones: z.array(z.any()),
});

export type ProjectDetails = z.infer<typeof ProjectDetailsSchema>;

const projectDetailsArraySchema = z.array(ProjectDetailsSchema);

export type ProjectDetailsArray = z.infer<typeof projectDetailsArraySchema>;
