import { VirtuoLibraryResourcesSchema } from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { nestApi } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderResourceById = (id?: number) => {
    return useQuery({
        queryKey: ["resource", id],
        queryFn: async () => {
            const { data } = await nestApi.get<VirtuoLibraryResourcesSchema>(
                `resources/${id}`
            );

            return {
                ...data,
                resourceUrl: data.objectKey ? undefined : data.resourceUrl,
            };
        },
    });
};
