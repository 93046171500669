import { AppUserType } from "@/constants/appUserSpec";
import {
    builderUserRoles,
    customerUserRoles,
    staffUserRoles,
} from "@/constants/roles";
import { validStagingCustomerUsers } from "@/constants/validCustomerUsers";
import { LoginResponseSchema } from "@/schemas";

type AppUserTypeInfo = {
    appUserType: AppUserType | null;
    isStaff: boolean;
    isCustomer: boolean;
    isBuilder: boolean;
};

export const hasAnyRole = (
    userRoles: string[],
    targetRoles: string[]
): boolean => userRoles.some((role) => targetRoles.includes(role));

const getAppUserType = (isStaff: boolean, isCustomer: boolean): AppUserType => {
    if (isStaff) {
        return AppUserType.Staff;
    }
    if (isCustomer) {
        return AppUserType.Customer;
    }

    return AppUserType.Builder;
};

const getIsValidCustomerUser = (email: string): boolean => {
    const isProd = import.meta.env.VITE_APP_API?.includes("api.virtuo.com");
    const validEmails = isProd ? [] : validStagingCustomerUsers;

    return validEmails.includes(email);
};

export const getAppUserTypeInfo = (
    user: LoginResponseSchema
): AppUserTypeInfo => {
    const userRoles = user?.roles || [];

    const isStaff = hasAnyRole(userRoles, staffUserRoles);
    const isCustomerRole = hasAnyRole(userRoles, customerUserRoles);
    const isCustomer =
        isCustomerRole && getIsValidCustomerUser(user?.email ?? "");

    const appUserType = getAppUserType(isStaff, isCustomer);

    return {
        isBuilder: hasAnyRole(userRoles, builderUserRoles),
        isStaff,
        isCustomer,
        appUserType,
    };
};
