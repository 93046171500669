interface PageHeaderProps {
    title: string;
    subTitle: string;
}
export const PageHeader = ({ title, subTitle }: PageHeaderProps) => {
    return (
        <div className="flex flex-col gap-1 mb-4">
            <h1 className="text-3xl font-semibold text-gray-text/2">{title}</h1>
            <h2 className="text-sm text-muted-foreground">{subTitle}</h2>
        </div>
    );
};
