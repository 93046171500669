import { z } from "zod";

export const AddressSchema = z.object({
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    locality: z.string().optional().nullable(),
    place_id: z.string().optional().nullable(),
    postal_code: z.string().optional().nullable(),
    province: z.string().optional().nullable(),
    street_number: z.string().optional().nullable(),
    route: z.string().optional().nullable(),
    city: z.string().optional(),
    administrative_area_level_1: z.string().optional().nullable(),
    administrative_area_level_2: z.string().optional().nullable(),
    country: z.string().optional(),
});

export type AddressSchemaType = z.infer<typeof AddressSchema>;
