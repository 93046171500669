import { Loading } from "@/components/Loading";
import { Shell } from "@/components/Shell";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { PageHeader } from "@/components/ui/page-header";
import { userTypes } from "@/constants/general";
import { useUpdateBuilderUser } from "@/hooks/builder/useUpdateBuilderUser";
import { useToast } from "@/hooks/utils/useToast";
import {
    CreateBuilderUserType,
    EditBuilderUserType,
    UpdateBuilderUserSchema,
} from "@/schemas/builders/createBuilderUserSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BuilderUserForm } from "./components/BuilderUserForm";

interface LocationState {
    state: {
        user: EditBuilderUserType;
    };
}

export const EditUserPage = () => {
    const { t: ut } = useTranslation("users");
    const { t: gt } = useTranslation("general");
    const { mutateAsync: updateBuilderUserAsync, isPending } =
        useUpdateBuilderUser();
    const navigate = useNavigate();
    const { toast } = useToast();
    const { state } = useLocation() as LocationState;
    const form = useForm<CreateBuilderUserType>({
        resolver: zodResolver(UpdateBuilderUserSchema),
        defaultValues: {
            additional_user: {
                builder_role_id: userTypes.find(
                    (type) => type.key === state.user.builder_role
                )?.value,
                id: state.user.id,
                email: state.user.email,
                first_name: state.user.first_name,
                last_name: state.user.last_name,
                phone_number: state.user.phone_number,
                number_type: state.user.number_type,
            },
        },
    });

    const { handleSubmit } = form;
    const handleUpdateBuilderUser = async (data: CreateBuilderUserType) => {
        await updateBuilderUserAsync(data, {
            onSuccess: () => {
                toast({
                    title: ut("Builder.UserUpdatedSuccess"),
                    description: ut("Builder.UserUpdatedSucessDesc"),
                    duration: 3000,
                });
            },
            onError: (error: Error) => {
                toast({
                    title: "Error",
                    description: error.message,
                });
            },
        });
        navigate("/users");
    };

    return (
        <Shell>
            <PageHeader
                title={ut("Builder.CreateBuilderUser")}
                subTitle={ut("CreatePageSubTitle")}
            />
            <FormProvider {...form}>
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit(handleUpdateBuilderUser)}
                        className="bg-white p-10 rounded-lg flex flex-col"
                    >
                        {isPending && <Loading />}
                        <BuilderUserForm />
                        <div className="flex justify-end mt-6 gap-3">
                            <Button
                                disabled={isPending}
                                variant="ghost"
                                onClick={() => navigate("/users")}
                            >
                                {gt("Cancel")}
                            </Button>
                            <Button disabled={isPending} type="submit">
                                <>
                                    {ut("Builder.CreateBuilderUser")}
                                    <Check className="ml-2" />
                                </>
                            </Button>
                        </div>
                    </form>
                </Form>
            </FormProvider>
        </Shell>
    );
};
