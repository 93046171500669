import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/login/useAuth";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";

export const NotAuthorizedPage = () => {
    const { handleLogout } = useAuth();

    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <header className="p-4">
                <h1 className="text-2xl font-light text-gray-600">Virtuo</h1>
            </header>
            <main className="flex-grow flex items-center justify-center">
                <div className="text-center">
                    <div className="mb-6">
                        <svg
                            className="mx-auto h-16 w-16 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                        </svg>
                    </div>
                    <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                        Sorry, access denied.
                    </h2>
                    <p className="text-gray-600 mb-8">
                        You don't have permissions to access this platform.
                    </p>
                    <Button
                        variant="default"
                        className="bg-gray-900 hover:bg-gray-800"
                        onClick={() => {
                            startTransition(() => {
                                handleLogout();
                                navigate("/");
                            });
                        }}
                    >
                        Log Out
                    </Button>
                </div>
            </main>
            <footer className="p-4 text-right">
                <p className="text-sm text-gray-500">Version 1.0.0</p>
            </footer>
        </div>
    );
};
