import { useSearchParams } from "react-router-dom";

export const useBuilderClientSearchParams = () => {
    const [searchParams] = useSearchParams();

    return {
        page: Number(searchParams.get("page")) || 1,
        perPage: Number(searchParams.get("per_page")) || 10,
        sortBy: searchParams.get("sort"),
        search: searchParams.get("fullname") || "",
    };
};
