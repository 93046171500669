import { z } from "zod";
import { BillingContactSchema } from "./billingContactSchema";

export const ProjectBillingSchema = z.object({
    id: z.number(),
    project_id: z.number(),
    billing_contact_id: z.number(),
    billing_contact: BillingContactSchema,
});

export type ProjectBillingSchemaType = z.infer<typeof ProjectBillingSchema>;
