import { CreateCampaignSchema } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface UpdateCampaignsByIdProps {
    id: number;
}

export const useUpdateCampaignsById = ({ id }: UpdateCampaignsByIdProps) => {
    return useMutation({
        mutationFn: async (body: CreateCampaignSchema) => {
            const { data } = await api.put(`v1/campaigns/${id}/update`, {
                ...body,
                campaign_locations: {
                    place_details: body.place_details,
                },
            });
            return data;
        },

        retry: false,
    });
};
