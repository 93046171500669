import { FilterOption } from "@/components/FilterBubble";
import { MultiSelect } from "@/components/MultiSelect";
import { PhoneInput } from "@/components/PhoneInput";
import { SearchAddress } from "@/components/SearchAddress";
import { Combobox } from "@/components/ui/Combobox";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datePicker";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { phoneTypes, staffRolesList } from "@/constants/general";
import { useFetchOptions } from "@/hooks/options/useFetchOptions";
import { useFetchStatuses } from "@/hooks/options/useFetchStatuses";
import { useCreateStaff } from "@/hooks/staff/useCreateStaff";
import { useUpdateStaff } from "@/hooks/staff/useUpdateStaff";
import { extractAddressComponents } from "@/lib/countriesHelpers";
import { cn } from "@/lib/utils";
import {
    StaffFormSchema,
    StaffFormSchemaType,
} from "@/schemas/staff/staffFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import type { DetailedHTMLProps, FormHTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface FormProps
    extends DetailedHTMLProps<
        FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    > {}

interface Option {
    label: string;
    value: string;
}

export const StaffForm = ({ className, ...rest }: FormProps) => {
    const location = useLocation();

    const user = location.state;
    const editting = user ? true : false;

    const [isMe, setIsMe] = useState(false);
    if (user && user.roles && user.roles.includes("move_expert")) {
        setIsMe(true);
    }

    const { t: ut } = useTranslation("user");
    const { t: gt } = useTranslation("general");
    const { t: st } = useTranslation("staff");
    const { t: consts } = useTranslation("consts");
    const { data: statuses } = useFetchStatuses();

    const { data } = useFetchOptions();
    const [languageOptions, setLanguageOptions] = useState<Option[]>([]);

    const { mutateAsync: updateStaffAsync } = useUpdateStaff();
    const { mutateAsync: createStaffAsync } = useCreateStaff();

    useEffect(() => {
        //TODO create language api to get languages with their code values
        if (data && data.languages) {
            const formattedLanguages: FilterOption[] = [];
            data?.languages.forEach((value: string) => {
                formattedLanguages.push({ label: value, value: value });
            });
            setLanguageOptions(
                formattedLanguages.map((option) => ({
                    ...option,
                    disable: false,
                }))
            );
        }
    }, [data]);

    const birthdayBreaker = () => {
        //TODO modify staff/user api to send birthday as a date rather than string
        const birthdayArray = user.birthday.split("/");
        const birthday = new Date();
        birthday.setFullYear(
            birthdayArray[2],
            birthdayArray[1] - 1,
            birthdayArray[0]
        );
        return birthday;
    };

    const languageFormatter = () => {
        //TODO modify staff/user api to send languages in an array rather than string
        const languages = user.languages.split(",");
        return languages.map((language: string) => {
            return language.trim();
        });
    };

    const form = useForm<StaffFormSchemaType>({
        resolver: zodResolver(StaffFormSchema),
        defaultValues: {
            first_name: user?.first_name ?? "",
            last_name: user?.last_name ?? "",
            birthday: user?.birthday ? birthdayBreaker() : undefined,
            languages: user?.languages ? languageFormatter() : [],
            email: user?.email ?? "",
            phone_number: {
                number: user?.phone_number,
                type: user?.phone_type ?? "mobile",
            },
            me_capacity: user?.me_capacity ?? 0,
            mc_booking_link: user?.mc_booking_link ?? "",
            user_status_id: user?.status ?? "2",
            roles: user?.roles ?? [],
            work_location: user?.staff_details?.work_locations[0] ?? {},
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = form;

    const onSubmit = (data: StaffFormSchemaType) => {
        const userBody = {
            ...data,
            languages: data.languages.join(", "),
            phone_number: data.phone_number.number,
            number_type: data.phone_number.type,
            user_status_id: Number(data.user_status_id),
        };
        delete userBody.work_location;

        const submitBody = {
            user: userBody,
            staff_details: {
                work_locations: data.work_location ? [data.work_location] : [],
            },
        };

        if (editting) {
            updateStaffAsync({
                body: submitBody,
                id: user.id,
            });
        } else {
            createStaffAsync(submitBody);
        }
    };

    return (
        <div className="bg-white flex flex-col gap-5 items-center rounded-md border py-10 px-5 sm:px-10 lg:px-20 m-auto">
            <h1>
                {editting
                    ? `${st("EditStaff")} - ${user.first_name} ${user.last_name}`
                    : st("NewStaffMember")}
            </h1>
            <Form {...form}>
                <form
                    className={cn(
                        "grid grid-cols-1 gap-x-8 gap-y-7 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full",
                        className
                    )}
                    {...rest}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormField
                        control={control}
                        name="first_name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{ut("FirstName")}</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={ut("FirstName")}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="last_name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{ut("LastName")}</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={ut("LastName")}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="birthday"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <FormLabel className="leading-normal">
                                    {ut("Birthday")}
                                </FormLabel>
                                <DatePicker
                                    {...field}
                                    date={field.value}
                                    setDate={field.onChange}
                                    className="w-full"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{ut("Email")}</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={ut("Email")}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="phone_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1 md:col-span-2">
                                <FormLabel>{ut("Phone")}</FormLabel>
                                <FormControl>
                                    <div className="flex items-center gap-2 flex-wrap md:flex-nowrap">
                                        <PhoneInput
                                            className="md:w-full"
                                            value={field.value?.number}
                                            placeholder="8555692582"
                                            defaultCountry="CA"
                                            onChange={(e) => {
                                                field.onChange({
                                                    ...field.value,
                                                    number: e.target?.value,
                                                });
                                            }}
                                        />
                                        <Combobox
                                            options={phoneTypes.map(
                                                (phoneType) => {
                                                    return {
                                                        label: consts(
                                                            phoneType.key
                                                        ),
                                                        value: phoneType.value,
                                                    };
                                                }
                                            )}
                                            value={
                                                field.value?.type ?? "mobile"
                                            }
                                            setValue={(value) =>
                                                field.onChange({
                                                    ...field.value,
                                                    type: value,
                                                })
                                            }
                                            searchable={false}
                                            className="w-[110px]"
                                        />
                                    </div>
                                </FormControl>
                                {errors.phone_number &&
                                    errors.phone_number.number && (
                                        <FormMessage>
                                            Phone number is required. Please
                                            enter a valid phone number.
                                        </FormMessage>
                                    )}
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="languages"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{ut("Languages")}</FormLabel>
                                <FormControl>
                                    {languageOptions &&
                                        languageOptions.length > 0 && (
                                            <MultiSelect
                                                onValueChange={field.onChange}
                                                options={languageOptions || []}
                                                placeholder={gt(
                                                    "Select.Languages"
                                                )}
                                            />
                                        )}
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="roles"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{st("Roles")}</FormLabel>
                                {staffRolesList && (
                                    <MultiSelect
                                        onValueChange={field.onChange}
                                        options={staffRolesList.map(
                                            (staffRole) => {
                                                return {
                                                    label: consts(
                                                        staffRole.key
                                                    ),
                                                    value: staffRole.value,
                                                };
                                            }
                                        )}
                                        placeholder={gt("Select.Roles")}
                                    />
                                )}
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="me_capacity"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel
                                    className={`${!isMe ? "text-grey-20" : "text-grey-text"}`}
                                >
                                    {st("MeCapacity")}
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder="0-100"
                                        {...field}
                                        inputMode="numeric"
                                        type="number"
                                        min={0}
                                        max={100}
                                        disabled={!isMe}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="user_status_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{ut("Status")}</FormLabel>
                                <FormControl>
                                    <Combobox
                                        value={field?.value?.toString()}
                                        setValue={field.onChange}
                                        options={statuses?.staff_status_list.map(
                                            (status) => ({
                                                label: status.status,
                                                value: status.id.toString(),
                                            })
                                        )}
                                        className="w-full justify-between"
                                        placeholder={gt("Select.Status")}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="mc_booking_link"
                        render={({ field }) => (
                            <FormItem className="col-span-1 md:col-span-2">
                                <FormLabel>{st("BookingLink")}</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={st("BookingLink")}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="work_location"
                        render={({ field }) => (
                            <FormItem className="col-span-1 md:col-span-2">
                                <FormLabel>{st("WorkLocation")}</FormLabel>
                                <FormControl>
                                    <SearchAddress
                                        initialValue={user?.locations}
                                        onSelectLocation={(
                                            location,
                                            _,
                                            placeDetails
                                        ) => {
                                            if (placeDetails) {
                                                const address =
                                                    extractAddressComponents(
                                                        placeDetails
                                                    );
                                                const locationBody = {
                                                    latitude: location?.lat,
                                                    longitude: location?.lng,
                                                    place_id:
                                                        placeDetails.place_id,
                                                    street_number:
                                                        address.streetNumber,
                                                    route: address.streetName,
                                                    locality: address.city,
                                                    city: address.city,
                                                    administrative_area_level_1:
                                                        address.province,
                                                    province: address.province,
                                                    country: address.country,
                                                    postal_code:
                                                        address.postalCode,
                                                };
                                                field.onChange(locationBody);
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button
                        className="w-full text-lg mt-8 font-bold"
                        type="submit"
                    >
                        {gt("Submit")}
                    </Button>
                </form>
            </Form>
        </div>
    );
};
