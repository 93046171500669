import z from "zod";

export const ProjectCommunitiesSchema = z.object({
    id: z.number().int(),
    projectId: z.number().int().optional(),
    community: z.string(),
    timestamp: z.coerce.date().optional(),
    defaultPropertyId: z.number().int().optional(),
});

export const ProjectCommunitiesCreateSchema = ProjectCommunitiesSchema.omit({
    id: true,
});

export type CreateProjectCommunities = z.infer<
    typeof ProjectCommunitiesCreateSchema
>;
