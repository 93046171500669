export const Loading = () => {
    return (
        <div
            data-testid="Loader"
            className="flex space-x-2 bg-primary justify-center  p-4 rounded-full max-w-[111px] items-center dark:invert"
        >
            <div className="size-3 bg-white rounded-full animate-bounce [animation-delay:-0.3s]" />
            <div className="size-3 bg-white rounded-full animate-bounce [animation-delay:-0.15s]" />
            <div className="size-3 bg-white rounded-full animate-bounce" />
        </div>
    );
};
