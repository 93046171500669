import { CommunitySchemaType } from "@/schemas/communitySchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchProjectCommunitiesProps {
    id: number[];
}

export const useFetchProjectCommunities = ({
    id,
}: FetchProjectCommunitiesProps) => {
    return useQuery({
        queryKey: ["project-communities", id],
        queryFn: async () => {
            const results = await Promise.all(
                id.map(async (projectId) => {
                    const { data } = await api.get<CommunitySchemaType[]>(
                        "v1/projects/project_communities",
                        {
                            params: {
                                project_id: projectId,
                            },
                        }
                    );
                    return data;
                })
            );
            return results.flat();
        },
        enabled: !!id && id.length > 0,
    });
};
