import type { StatusResponseType } from "@/schemas/statusListSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchStatuses = () => {
    return useQuery({
        queryKey: ["statuses"],
        queryFn: async () => {
            const { data } = await api.get<StatusResponseType>(
                "v1/common/status_list"
            );
            return data;
        },
    });
};
