import { z } from "zod";

export const AddNewFileSchema = z.object({
    object_id: z.number(),
    upload_type: z.string(),
    user_document: z
        .object({
            user_property_id: z.number(),
            category: z.string(),
            title: z.string(),
            description: z.string().optional(),
        })
        .optional(),
    files: z.array(z.instanceof(File)).optional(),
});

export type AddNewFileSchemaType = z.infer<typeof AddNewFileSchema>;
