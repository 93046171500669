import { CreatePropertyType } from "@/schemas/createPropertySchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useCreateAccount = () => {
    return useMutation({
        mutationFn: async (body: CreatePropertyType) => {
            const { data } = await api.post("v1/users/create_user_refactored", {
                user: {
                    ...body.user,
                    user_status_id: 1, // TODO: TO DEFAULT TO ACTIVE USER STATUS ON THE API
                    languages: body.user.languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
                    preferred_languages:
                        body.user.preferred_languages?.join(","), // TODO: THIS THIS ON THE API TO ACCEPT A STRING ARRAY
                },

                user_properties: body.user_properties,
                additional_users: (body.additional_users ?? []).map((user) => {
                    const { enabled, edited, ...rest } = user;
                    return rest;
                }),
            });
            return data;
        },
    });
};
