// TODO: REMOVE ALL THE MULTIPLE SCHEMAS WHEN IT IS FIXED ON THE BACKEND

import { AddressSchema } from "@/schemas/addressSchema";
import { z } from "zod";

const UserSchema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    alias_name: z.string().optional().nullable(),
    customer_id: z.string(),
    email: z.string().email(),
    phone_number: z.string(),
    no_of_adults: z.coerce.number().default(0),
    no_of_children: z.coerce.number().default(0),
    buyer_type: z.string().optional().nullable(),
    languages: z.string().array().optional(),
    preferred_languages: z.string().array().optional(),
    community: z.string().optional(),
    project: z.string(),
    product_type: z.string(),
});

const PropertySchema = z.object({
    formatted_address: z.string(),
    unit_no: z.string().optional(),
    place_details: AddressSchema,
    project_id: z.number(),
    product_type_id: z.number(),
    is_manual_address: z.boolean().optional(),
});

const KeyDatesSchema = z.object({
    possession_date: z.coerce.date().optional(),
});

const UserPropertyStatusSchema = z.object({
    phase_id: z.number(),
    status_id: z.number(),
});

const UserPropertiesSchema = z.object({
    id: z.number().optional(),
    shared_user_ids: z.array(z.number()).optional(),
    key_dates: KeyDatesSchema,
    property: PropertySchema,
    user_property_status: z.array(UserPropertyStatusSchema),
});

const AdditionalUserSchema = z.object({
    additional_user_id: z.number().optional(),
    enabled: z.boolean().default(true),
    edited: z.boolean().default(false),
    first_name: z.string(),
    last_name: z.string(),
    alias_name: z.string().optional().nullable(),
    email: z.string().email(),
    phone_number: z.string(),
    shared_user_properties: z.array(z.number()).optional(),
});

export const CreatePropertySchema = z.object({
    user: UserSchema,
    user_properties: z.array(UserPropertiesSchema),
    additional_users: z.array(AdditionalUserSchema).optional(),
});

export type CreatePropertyType = z.infer<typeof CreatePropertySchema>;
