import { ResourceCategoriesCreateSchema } from "@/hooks/builder/ResourceCategorySchema";
import z from "zod";
import { buyerTypeSchema } from "./BuyerTypeSchema";
import { ProductTypesSchema } from "./ProductTypesSchema";
import { ProjectCommunitiesSchema } from "./ProjectCommunitiesSchema";
import { PhaseSchema } from "./phaseSchema";

export const virtuoLibraryResourcesSchema = z.object({
    id: z.number().int(),
    title: z.string(),
    description: z.string().optional(),
    resourceUrl: z.string().optional(),
    createdBy: z.number().int().optional(),
    createdAt: z.date(),
    builderAccountId: z.number().int().optional(),
    buyerTypes: buyerTypeSchema.array().optional(),
    expiryDate: z.coerce.date().optional().nullable(),
    files: z.array(z.instanceof(File)).optional(),
    objectKey: z.string().optional().nullable(),
    deletedAt: z.coerce.date().optional(),
    resourceCategoryId: z.number().int().optional(),
    updatedAt: z.date(),
    openaiBlobFileId: z.number().int().optional().nullable(),
    phase: z.array(PhaseSchema).optional(),
    productType: z.array(ProductTypesSchema).optional(),
    projectCommunity: z.array(ProjectCommunitiesSchema).optional(),
    resourceCategories: ResourceCategoriesCreateSchema.optional(),
});

export type VirtuoLibraryResourcesSchema = z.infer<
    typeof virtuoLibraryResourcesSchema
>;

export const virtuoLibraryResourcesCreateSchema =
    virtuoLibraryResourcesSchema.omit({
        id: true,
        createdAt: true,
        updatedAt: true,
    });

export type CreateVirtuoLibraryResources = z.infer<
    typeof virtuoLibraryResourcesCreateSchema
>;
