import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useControllableState } from "@/hooks/useControllableState";
import { useToast } from "@/hooks/utils/useToast";
import { cn, formatBytes } from "@/lib/utils";
import { Cross2Icon, FileTextIcon, UploadIcon } from "@radix-ui/react-icons";
import * as React from "react";
import Dropzone, {
    type DropzoneProps,
    type FileRejection,
} from "react-dropzone";
import { useTranslation } from "react-i18next";

interface FileUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
    value?: File[];
    onValueChange?: (files: File[]) => void;
    onUpload?: (files: File[]) => Promise<void>;
    progresses?: Record<string, number>;
    accept?: DropzoneProps["accept"];
    maxSize?: DropzoneProps["maxSize"];
    maxFileCount?: DropzoneProps["maxFiles"];
    multiple?: boolean;
    disabled?: boolean;
    description?: string;
    title?: string;
}

export const FileUploader = React.forwardRef<HTMLDivElement, FileUploaderProps>(
    (props, ref) => {
        const { toast } = useToast();
        const { t } = useTranslation("uploadFile");
        const {
            value: valueProp,
            onValueChange,
            onUpload,
            description,
            title,
            progresses,
            accept = { "image/*": [] },
            maxSize = 1024 * 1024 * 50, // 50 MB
            maxFileCount = 1,
            multiple = false,
            disabled = false,
            className,
            ...dropzoneProps
        } = props;

        const [files, setFiles] = useControllableState<File[]>({
            prop: valueProp ?? [],
            onChange: onValueChange,
        });

        const onDrop = React.useCallback(
            (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
                if (
                    !multiple &&
                    maxFileCount === 1 &&
                    acceptedFiles.length > 1
                ) {
                    toast({
                        title: t("UploadFileMoreThenOne"),
                        description: t("UploadFileMoreThenOneDescription"),
                        duration: 3000,
                    });
                    return;
                }

                if (
                    (files?.length ?? 0) + acceptedFiles.length >
                    maxFileCount
                ) {
                    toast({
                        title: t("UploadFileMoreThenOne"),
                        description: `${maxFileCount} ${t("File")}`,
                        duration: 3000,
                    });
                    return;
                }

                const newFiles = acceptedFiles.map((file) =>
                    Object.assign(file, { preview: URL.createObjectURL(file) })
                );

                const updatedFiles = Array.isArray(files)
                    ? [...files, ...newFiles]
                    : newFiles;
                setFiles(updatedFiles);

                if (rejectedFiles.length > 0) {
                    rejectedFiles.forEach(({ file }) => {
                        toast({
                            title: t("File"),
                            description: `${file.name} ${t("UploadFileError")}`,
                            duration: 3000,
                        });
                    });
                }

                if (
                    onUpload &&
                    updatedFiles.length > 0 &&
                    updatedFiles.length <= maxFileCount
                ) {
                    toast({
                        title: t("Uploading"),
                        description: `${updatedFiles.length} ${t("File")}...`,
                        duration: 3000,
                    });
                }
            },
            [files, maxFileCount, multiple, onUpload, setFiles]
        );

        function onRemove(index: number) {
            if (!files) return;
            const newFiles = files.filter((_, i: number) => i !== index);
            setFiles(newFiles);
            onValueChange?.(newFiles);
        }

        React.useEffect(() => {
            return () => {
                if (!Array.isArray(files)) return;
                files.forEach((file) => {
                    if (isFileWithPreview(file) && file.preview) {
                        URL.revokeObjectURL(file.preview);
                    }
                });
            };
        }, [files]);

        const isDisabled = disabled || (files?.length ?? 0) >= maxFileCount;

        return (
            <div
                ref={ref}
                className="relative flex flex-col gap-6 overflow-hidden"
            >
                <Dropzone
                    onDrop={onDrop}
                    accept={accept}
                    maxSize={maxSize}
                    maxFiles={maxFileCount}
                    multiple={maxFileCount > 1 || multiple}
                    disabled={isDisabled}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                            {...getRootProps()}
                            className={cn(
                                "group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25",
                                "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
                                isDragActive && "border-muted-foreground/50",
                                isDisabled && "pointer-events-none opacity-60",
                                className
                            )}
                            {...dropzoneProps}
                        >
                            <input {...getInputProps()} />
                            <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                                <div className="rounded-full border border-dashed p-3">
                                    <UploadIcon
                                        className="size-7 text-muted-foreground"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="flex flex-col gap-px">
                                    <p className="font-medium text-muted-foreground">
                                        {title ? title : t("DragAndDropFiles")}
                                    </p>
                                    <p className="text-sm text-muted-foreground/70">
                                        {description ??
                                            `${maxFileCount} files max, ${formatBytes(maxSize)}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>

                {Array.isArray(files) && files.length > 0 && (
                    <ScrollArea className="h-fit w-full px-3">
                        <div className="flex max-h-48 flex-col gap-4">
                            {files.map((file, index) => (
                                <FileCard
                                    key={index}
                                    file={file as FileWithUrl}
                                    onRemove={() => onRemove(index)}
                                    progress={progresses?.[file.name]}
                                />
                            ))}
                        </div>
                    </ScrollArea>
                )}
            </div>
        );
    }
);

interface FileWithUrl extends File {
    preview?: string;
}

interface FileCardProps {
    file: FileWithUrl;
    onRemove: () => void;
    progress?: number;
}

function FileCard({ file, progress, onRemove }: FileCardProps) {
    const { t } = useTranslation("uploadFile");
    return (
        <div className="relative flex items-center gap-2.5">
            <div className="flex flex-1 gap-2.5">
                {isFileWithPreview(file) ? <FilePreview /> : null}
                <div className="flex w-full flex-col gap-2">
                    <div className="flex flex-col gap-px">
                        <p className="line-clamp-1 text-sm font-medium text-grey-text/80">
                            <a
                                className="text-blue"
                                href={file.preview}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {file.name}
                            </a>
                        </p>
                        <p className="text-xs text-muted-foreground">
                            {formatBytes(file.size)}
                        </p>
                    </div>
                    {progress ? <Progress value={progress} /> : null}
                </div>
            </div>
            <Button
                type="button"
                variant="outline"
                size="icon"
                className="size-7"
                onClick={onRemove}
            >
                <Cross2Icon className="size-4" aria-hidden="true" />
                <span className="sr-only">{t("RemoveFile")}</span>
            </Button>
        </div>
    );
}

function isFileWithPreview(file: FileWithUrl): file is FileWithUrl {
    return Boolean(file.preview);
}

function FilePreview() {
    return (
        <FileTextIcon
            className="size-10 text-muted-foreground"
            aria-hidden="true"
        />
    );
}
