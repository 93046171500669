import PrivateRoute from "@/components/PrivateRoute";
import { AppLayout } from "@/components/layout/AppLayout";
import { customerUserRoles } from "@/constants/roles";
import { CustomerHomePage, ErrorPage } from "@/pages";

export const getCustomerRoutes = () => [
    {
        path: "/",
        element: <PrivateRoute element={AppLayout} roles={customerUserRoles} />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <CustomerHomePage />,
            },
        ],
    },
];
