import i18n from "i18next";
import { z } from "zod";
import { phoneNumberSchema } from "./phoneNumberSchema";

export const phoneNumberTypeEnum = z.enum(["Mobile", "Home", "Work"]);

export const ContactSchema = z.object({
    first_name: z.string().min(1, i18n.t("validation:FirstName")),
    last_name: z.string().min(1, i18n.t("validation:LastName")),
    email: z.string().email(i18n.t("validation:Email")),
    second_number_type: phoneNumberTypeEnum.default("Mobile"),
    builder_role_id: z.number().default(1), //@TODO: what is this?
    third_number_type: phoneNumberTypeEnum.default("Mobile"),
    number_type: phoneNumberTypeEnum.default("Mobile"),
    phone_number: phoneNumberSchema,
    additional_emails: z.array(z.string().email()).optional(),
    additional_phones: z
        .array(
            z.object({
                type: z.enum(["Mobile", "Home", "Work"]).default("Mobile"),
                number: z.string().min(1, i18n.t("validation:PhoneNumber")),
            })
        )
        .optional(),
});

export type ContactType = z.infer<typeof ContactSchema>;

export type phoneNumberType = z.infer<typeof phoneNumberTypeEnum>;
