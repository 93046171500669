import { BuilderAccountResponseType } from "@/schemas/builders/builderAccountSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
interface BuilderAccountDetailsType {
    builderId?: number;
}

export const useFetchBuilderAccountDetails = ({
    builderId,
}: BuilderAccountDetailsType) => {
    return useQuery({
        queryKey: ["builderAccountDetails-v2", builderId],
        queryFn: async () => {
            const url = `v2/accounts/${builderId}`;
            const { data } = await api.get<BuilderAccountResponseType>(url, {
                params: {
                    "attributes[]": ["branding_info", "logo"],
                },
            });
            return data;
        },
        enabled: !!builderId,
    });
};
