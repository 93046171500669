import { z } from "zod";

export const ResourceCategoriesSchema = z.object({
    id: z.number().int(),
    categoryName: z.string(),
    builderAccountId: z.number().int().optional(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.date().optional(),
});

export type ResourceCategoriesSchema = z.infer<typeof ResourceCategoriesSchema>;

export const ResourceCategoriesCreateSchema = ResourceCategoriesSchema.omit({
    id: true,
    updatedAt: true,
    deletedAt: true,
    createdAt: true,
    builderAccountId: true,
});

export type CreateResourceCategories = z.infer<
    typeof ResourceCategoriesCreateSchema
>;
