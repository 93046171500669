"use client";

import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Plus } from "lucide-react";
import * as React from "react";

export type Option = {
    value: string;
    label: string;
};

interface SearchableSelectProps {
    options: Option[];
    onOptionCreate?: (
        newOption: Option
    ) => Promise<Option | null | undefined> | Option | null | undefined;
    onChange?: (value: string) => void;
    value?: string;
    placeholder?: string;
    searchPlaceholder?: string;
    createTitle?: string;
    className?: string;
    disabled?: boolean;
}

export function SearchableSelect({
    options: initialOptions,
    onOptionCreate,
    onChange,
    value = "",
    placeholder = "Select an option...",
    searchPlaceholder = "Search...",
    createTitle = "Create new item",
    className = "",
    disabled = false,
}: SearchableSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [options, setOptions] = React.useState<Option[]>(initialOptions);
    const [newItemValue, setNewItemValue] = React.useState("");
    const [selectedValue, setSelectedValue] = React.useState(value);
    const [isCreating, setIsCreating] = React.useState(false);

    // Update internal state when external value changes
    React.useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    // Update internal options when initialOptions change
    React.useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const createNewItem = async () => {
        if (newItemValue.trim()) {
            const newItem: Option = {
                value: newItemValue.toLowerCase().replace(/\s+/g, "-"),
                label: newItemValue.trim(),
            };

            try {
                setIsCreating(true);

                // If there's an external handler, call it
                if (onOptionCreate) {
                    const result = await Promise.resolve(
                        onOptionCreate(newItem)
                    );

                    // Only proceed if we got a valid result back
                    if (result) {
                        // Use the returned item instead of our local one
                        // This allows the parent to modify the item if needed
                        setOptions((prev) => [...prev, result]);
                        setSelectedValue(result.value);
                        onChange?.(result.value);
                    }
                } else {
                    // If no external handler, just use the local item
                    setOptions((prev) => [...prev, newItem]);
                    setSelectedValue(newItem.value);
                    onChange?.(newItem.value);
                }

                // Reset states
                setNewItemValue("");
                setDialogOpen(false);
                setOpen(false);
                setSearch("");
            } catch (error) {
                console.error("Failed to create new item:", error);
            } finally {
                setIsCreating(false);
            }
        }
    };

    const handleSelect = (currentValue: string) => {
        setSelectedValue(currentValue);
        onChange?.(currentValue);
        setOpen(false);
        setSearch("");
    };

    return (
        <div className={`grid w-full gap-2 ${className}`}>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-full justify-between"
                        disabled={disabled}
                    >
                        {selectedValue
                            ? options.find(
                                  (option) => option.value === selectedValue
                              )?.label
                            : placeholder}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0">
                    <Command>
                        <CommandInput
                            placeholder={searchPlaceholder}
                            value={search}
                            onValueChange={setSearch}
                        />
                        <CommandList>
                            <CommandEmpty className="p-2">
                                <p className="mb-2 text-sm text-muted-foreground">
                                    No items found.
                                </p>
                                {onOptionCreate && (
                                    <Dialog
                                        open={dialogOpen}
                                        onOpenChange={setDialogOpen}
                                    >
                                        <DialogTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                className="w-full"
                                            >
                                                <Plus className="mr-2 h-4 w-4" />
                                                Create "{search}"
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle>
                                                    {createTitle}
                                                </DialogTitle>
                                            </DialogHeader>
                                            <div className="grid gap-4 py-4">
                                                <div className="grid gap-2">
                                                    <Label htmlFor="name">
                                                        Name
                                                    </Label>
                                                    <Input
                                                        id="name"
                                                        value={
                                                            newItemValue ||
                                                            search
                                                        }
                                                        onChange={(e) =>
                                                            setNewItemValue(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <DialogFooter>
                                                <Button
                                                    onClick={createNewItem}
                                                    disabled={isCreating}
                                                >
                                                    {isCreating
                                                        ? "Creating..."
                                                        : "Create"}
                                                </Button>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog>
                                )}
                            </CommandEmpty>
                            <CommandGroup>
                                {options
                                    .filter((option) =>
                                        option.label
                                            .toLowerCase()
                                            .includes(search.toLowerCase())
                                    )
                                    .map((option) => (
                                        <CommandItem
                                            key={option.value}
                                            value={option.value}
                                            onSelect={handleSelect}
                                        >
                                            {option.label}
                                        </CommandItem>
                                    ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
}
