import type { DataTableFilterField } from "@/@types/tableTypes";
import { useDebounce } from "@/hooks/utils/useDebounce";
import {
    type ColumnDef,
    type ColumnFiltersState,
    type PaginationState,
    type SortingState,
    type VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

interface UseDataTableProps<TData, TValue> {
    data: TData[];
    columns: ColumnDef<TData, TValue>[];
    pageCount?: number;
    totalItems?: number;
    defaultPerPage?: number;
    defaultSort?: `${Extract<keyof TData, string | number>}.${"asc" | "desc"}`;
    filterFields?: DataTableFilterField<TData>[];
    enableAdvancedFilter?: boolean;
    manual?: boolean;
}

const schema = z.object({
    page: z.coerce.number().default(1),
    per_page: z.coerce.number().optional(),
    sort: z.string().optional(),
});

export function useDataTable<TData, TValue>({
    data,
    columns,
    pageCount,
    totalItems,
    defaultPerPage = 10,
    defaultSort,
    filterFields = [],
    enableAdvancedFilter = false,
    manual = true,
}: UseDataTableProps<TData, TValue>) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const search = schema.parse(Object.fromEntries(searchParams));
    const page = search.page;
    const perPage = search.per_page ?? defaultPerPage;
    const sort = search.sort ?? defaultSort;
    const [column, order] = sort?.split(".") ?? [];

    const { searchableColumns, filterableColumns } = useMemo(() => {
        return {
            searchableColumns: filterFields.filter((field) => !field.options),
            filterableColumns: filterFields.filter((field) => field.options),
        };
    }, [filterFields]);

    const createQueryString = useCallback(
        (params: Record<string, string | number | null>) => {
            const newSearchParams = new URLSearchParams(
                searchParams.toString()
            );

            for (const [key, value] of Object.entries(params)) {
                if (value === null) {
                    newSearchParams.delete(key);
                } else {
                    newSearchParams.set(key, String(value));
                }
            }

            return newSearchParams.toString();
        },
        [searchParams]
    );

    const initialColumnFilters: ColumnFiltersState = useMemo(() => {
        return Array.from(searchParams.entries()).reduce<ColumnFiltersState>(
            (filters, [key, value]) => {
                const filterableColumn = filterableColumns.find(
                    (column) => column.value === key
                );
                const searchableColumn = searchableColumns.find(
                    (column) => column.value === key
                );

                if (filterableColumn) {
                    filters.push({
                        id: key,
                        value: value.split("."),
                    });
                } else if (searchableColumn) {
                    filters.push({
                        id: key,
                        value: [value],
                    });
                }

                return filters;
            },
            []
        );
    }, [filterableColumns, searchableColumns, searchParams]);

    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        {}
    );
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>(initialColumnFilters);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: page - 1,
        pageSize: perPage,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    useEffect(() => {
        navigate(
            `${location.pathname}?${createQueryString({
                page: pageIndex + 1,
                per_page: pageSize,
            })}`,
            {
                replace: true,
                state: { scroll: false },
            }
        );
    }, [pageIndex, pageSize]);

    const [sorting, setSorting] = useState<SortingState>([
        {
            id: column ?? "",
            desc: order === "desc",
        },
    ]);

    useEffect(() => {
        navigate(
            `${location.pathname}?${createQueryString({
                page,
                sort: sorting[0]?.id
                    ? `${sorting[0]?.id}.${sorting[0]?.desc ? "desc" : "asc"}`
                    : null,
            })}`
        );
    }, [sorting]);

    const debouncedSearchableColumnFilters = JSON.parse(
        useDebounce(
            JSON.stringify(
                columnFilters.filter((filter) => {
                    return searchableColumns.find(
                        (column) => column.value === filter.id
                    );
                })
            ),
            500
        )
    ) as ColumnFiltersState;

    const filterableColumnFilters = columnFilters.filter((filter) => {
        return filterableColumns.find((column) => column.value === filter.id);
    });

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (enableAdvancedFilter) return;

        if (!mounted) {
            setMounted(true);
            return;
        }

        const newParamsObject = {
            page: 1,
        };
        for (const column of debouncedSearchableColumnFilters) {
            if (typeof column.value === "string") {
                Object.assign(newParamsObject, {
                    [column.id]:
                        typeof column.value === "string" ? column.value : null,
                });
            }
        }

        for (const column of filterableColumnFilters) {
            if (
                typeof column.value === "object" &&
                Array.isArray(column.value)
            ) {
                Object.assign(newParamsObject, {
                    [column.id]: column.value.join("."),
                });
            }
        }

        for (const key of searchParams.keys()) {
            if (
                (searchableColumns.find((column) => column.value === key) &&
                    !debouncedSearchableColumnFilters.find(
                        (column) => column.id === key
                    )) ||
                (filterableColumns.find((column) => column.value === key) &&
                    !filterableColumnFilters.find(
                        (column) => column.id === key
                    ))
            ) {
                Object.assign(newParamsObject, { [key]: null });
            }
        }

        navigate(`${location.pathname}?${createQueryString(newParamsObject)}`);

        table.setPageIndex(0);
    }, [
        JSON.stringify(debouncedSearchableColumnFilters),
        JSON.stringify(filterableColumnFilters),
    ]);

    const table = useReactTable({
        data,
        columns,
        pageCount: pageCount ? pageCount : totalItems ? undefined : 1, // If totalItems is provided, pageCount is undefined
        rowCount: totalItems ? totalItems : pageCount ? undefined : 0, // If pageCount is provided, rowCount is undefined
        // Setting both breaks the pagination, please only set one. The other will be calculated automatically
        state: {
            pagination,
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: manual,
        manualSorting: manual,
        manualFiltering: manual,
    });

    return { table };
}
