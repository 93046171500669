import { Shell } from "@/components/Shell";
import { CampaignsTable } from "@/components/table/CampaignTables/CampaignsTable";
import { PageHeader } from "@/components/ui/page-header";

import { useTranslation } from "react-i18next";

export const CampaignsPage = () => {
    const { t } = useTranslation("campaign");

    return (
        <Shell>
            <PageHeader title={t("PageTitle")} subTitle={t("PageSubTitle")} />
            <CampaignsTable />
        </Shell>
    );
};
