import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

export const useSendInviteToAccountUser = () => {
    return useMutation({
        mutationFn: async (user_id: number) => {
            await api.post("v1/users/send_invite_to_partner", {
                user_id,
            });
        },
    });
};
