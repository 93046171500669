import { FileQuestion } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
    const { t } = useTranslation("general");
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <FileQuestion className="h-40 w-40  stroke-1 opacity-25" />
            <h3>{t("NotFoundTitle")}</h3>
            <div className="flex text-gray-500 text-sm">
                <span>{t("NotFoundDescription")}</span>
                <span
                    className="text-secondary cursor-pointer text-sm ml-1"
                    onClick={() => navigate("/")}
                >
                    {t("HomePage")}
                </span>
            </div>
        </div>
    );
};
