import { BroadcastFormSchemaType } from "@/schemas/builders/broadcastFormSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface UpdateBroadcastByIdProps {
    id: number;
}

export const useUpdateBroadcastById = ({ id }: UpdateBroadcastByIdProps) => {
    return useMutation({
        mutationFn: async (body: BroadcastFormSchemaType) => {
            const { data } = await api.put(`v1/broadcasts/${id}/update`, body);
            return data;
        },
        retry: false,
    });
};
