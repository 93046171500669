import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { forwardRef } from "react";
import EditorToolbar from "./toolbar/EditorToolbar";

interface EditorProps {
    content: string;
    placeholder?: string;
    onChange: (value: string) => void;
    className?: string;
}

const Editor = forwardRef<HTMLDivElement, EditorProps>(
    ({ content, placeholder, onChange, className }, ref) => {
        const editor = useEditor({
            extensions: [StarterKit],
            content: content,
            onUpdate: ({ editor }) => {
                onChange(editor.getHTML());
            },
        });

        if (!editor) return <></>;

        return (
            <div
                ref={ref}
                className={`prose max-w-none w-full border border-input bg-background dark:prose-invert ${className}`}
            >
                <EditorToolbar editor={editor} />
                <div className="editor">
                    <EditorContent editor={editor} placeholder={placeholder} />
                </div>
            </div>
        );
    }
);

export default Editor;
