import PrivateRoute from "@/components/PrivateRoute";
import { AppLayout } from "@/components/layout/AppLayout";
import { builderUserRoles } from "@/constants/roles";
import {
    AccountReportsPage,
    BroadcastsPage,
    CampaignsPage,
    CreateBroadcastPage,
    CreateCampaignsPage,
    CreatePropertyPage,
    CreateResourcePage,
    CreateUserPage,
    CustomerCarePage,
    EditBroadcastPage,
    EditCampaignPage,
    EditResourcePage,
    EditUserPage,
    ErrorPage,
    ResourcesPage,
    UsersPage,
} from "@/pages";
import PropertyPage from "@/pages/PropertyPage/PropertyPage";
import { UserProfilePage } from "@/pages/builders/UserProfilePage/UserProfilePage";

export const getAccountRoutes = () => [
    {
        path: "/",
        element: <PrivateRoute element={AppLayout} roles={builderUserRoles} />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <PropertyPage />,
            },
            {
                path: "report",
                element: <AccountReportsPage />,
            },
            {
                path: "create-property/builder/:builderId",
                element: <CreatePropertyPage />,
            },

            {
                path: "reports",
                element: <AccountReportsPage />,
            },
            {
                path: "campaigns",

                children: [
                    {
                        index: true,
                        element: <CampaignsPage />,
                    },
                    {
                        path: "create",
                        element: <CreateCampaignsPage />,
                    },
                    {
                        path: "edit/:campaignId",
                        element: <EditCampaignPage />,
                    },
                ],
            },
            {
                path: "broadcasts",
                children: [
                    {
                        index: true,
                        element: <BroadcastsPage />,
                    },
                    {
                        path: "create",
                        element: <CreateBroadcastPage />,
                    },
                    {
                        path: "edit/:broadcastId",
                        element: <EditBroadcastPage />,
                    },
                ],
            },

            {
                path: "resources",
                children: [
                    {
                        index: true,
                        element: <ResourcesPage />,
                    },
                    {
                        path: "create",
                        element: <CreateResourcePage />,
                    },
                    {
                        path: "edit/:resourceId",
                        element: <EditResourcePage />,
                    },
                ],
            },
            {
                path: "customer-care",
                element: <CustomerCarePage />,
            },
            {
                path: "users",
                children: [
                    {
                        index: true,
                        element: <UsersPage />,
                    },
                    {
                        path: "create",
                        element: <CreateUserPage />,
                    },
                    {
                        path: "edit/:userId",
                        element: <EditUserPage />,
                    },
                ],
            },

            {
                path: "broadcasts",
                element: <BroadcastsPage />,
            },
            {
                path: "profile",
                element: <UserProfilePage />,
            },
        ],
    },
];
