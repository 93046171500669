import { cn } from "@/lib/utils";
import { MapPinHouse } from "lucide-react";
import { PropsWithChildren } from "react";

export const WelcomeMessage = (props: PropsWithChildren) => (
    <div className="text-grey-faded text-base font-medium opacity-50 text-center">
        {props.children}
    </div>
);

interface UserPropertyAddressBaseProps {
    formattedAddress: string;
    containerStyle?: string;
    pinStyle?: string;
    addressStyle?: string;
}

const UserPropertyAddressBase = ({
    formattedAddress,
    containerStyle,
    pinStyle,
    addressStyle,
}: UserPropertyAddressBaseProps) => (
    <div className={cn("flex", containerStyle)}>
        <MapPinHouse className={cn("h-5 w-5 text-secondary", pinStyle)} />
        <div
            className={cn(
                "text-sm font-medium text-grey-faded opacity-75 overflow-x-hidden flex-shrink min-w-0 max-w-lg",
                addressStyle
            )}
        >
            {formattedAddress}
        </div>
    </div>
);

interface UserPropertyAddressProps {
    formattedAddress: string;
    isHorizontal?: boolean;
}

export const UserPropertyAddress = ({
    formattedAddress,
    isHorizontal,
}: UserPropertyAddressProps) => {
    const containerStyle = isHorizontal
        ? "gap-2 text-right flex-shrink overflow-hidden w-full"
        : "gap-1 justify-center flex-col items-center";
    const pinStyle = isHorizontal ? "size-5 shrink-0" : "size-6";
    const addressStyle = isHorizontal
        ? "flex-grow text-ellipsis whitespace-nowrap overflow-hidden flex-shrink"
        : "max-w-full text-center max-h-10";

    return (
        <UserPropertyAddressBase
            formattedAddress={formattedAddress}
            containerStyle={containerStyle}
            pinStyle={pinStyle}
            addressStyle={addressStyle}
        />
    );
};

interface CustomerBannerMessageProps {
    firstName: string;
    lastName: string;
    formattedAddress?: string;
}

export const CustomerBannerMessage = (props: CustomerBannerMessageProps) => {
    return (
        <div className="flex flex-col items-end flex-shrink min-w-0 overflow-hidden">
            <WelcomeMessage>{`Welcome, ${props.firstName} ${props.lastName}`}</WelcomeMessage>
            {props.formattedAddress && (
                <UserPropertyAddress
                    formattedAddress={props.formattedAddress}
                    isHorizontal
                />
            )}
        </div>
    );
};
