import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
} from "@/components/ui/dialog";
import React, { type SyntheticEvent } from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    type Crop,
    type PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useToast } from "@/hooks/utils/useToast";
import { CropIcon, Trash2Icon } from "lucide-react";
import { FileWithPath } from "react-dropzone";
import { useTranslation } from "react-i18next";

export type FileWithPreview = FileWithPath & {
    preview: string;
};

interface ImageCropperProps {
    dialogOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedFile: FileWithPreview | null;
    setSelectedFile: React.Dispatch<
        React.SetStateAction<FileWithPreview | null>
    >;
    onCropComplete: (croppedFile: File) => void;
}

export function ImageCropper({
    dialogOpen,
    setDialogOpen,
    selectedFile,
    setSelectedFile,
    onCropComplete,
}: ImageCropperProps) {
    const aspect = 1;
    const imgRef = React.useRef<HTMLImageElement | null>(null);
    const [crop, setCrop] = React.useState<Crop>();
    const [croppedImageUrl, setCroppedImageUrl] = React.useState<string>("");
    const { toast } = useToast();
    const { t } = useTranslation("general");

    function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function handleCropComplete(crop: PixelCrop) {
        if (imgRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imgRef.current, crop);
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    async function getCroppedImg(
        image: HTMLImageElement,
        crop: PixelCrop
    ): Promise<string> {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext("2d");

        if (ctx) {
            ctx.imageSmoothingEnabled = false;

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );
        }
        const obj = await canvas.toDataURL("image/png", 1.0);

        return obj;
    }

    async function handleCrop() {
        try {
            if (croppedImageUrl && selectedFile) {
                const blob = await fetch(croppedImageUrl).then((r) => r.blob());
                const fileName = selectedFile.name;
                const croppedFile = new File([blob], fileName, {
                    type: "image/png",
                });
                onCropComplete(croppedFile);
            }
            setDialogOpen(false);
        } catch {
            toast({
                title: t("Error"),
                description: t("SomethingWentWrong"),
            });
        }
    }

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="p-0 gap-0">
                <div className="p-6 size-full">
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => handleCropComplete(c)}
                        aspect={aspect}
                        className="w-full"
                    >
                        <Avatar className="size-full rounded-none">
                            <AvatarImage
                                ref={imgRef}
                                className="size-full rounded-none"
                                alt="Image Cropper Shell"
                                src={selectedFile?.preview}
                                onLoad={onImageLoad}
                            />
                            <AvatarFallback className="size-full min-h-[460px] rounded-none"></AvatarFallback>
                        </Avatar>
                    </ReactCrop>
                </div>
                <DialogFooter className="p-6 pt-0 flex justify-center">
                    <DialogClose asChild>
                        <Button
                            size={"sm"}
                            type="reset"
                            className="w-fit"
                            variant={"outline"}
                            onClick={() => {
                                setSelectedFile(null);
                            }}
                        >
                            <Trash2Icon className="mr-1.5 size-4" />
                            {t("Cancel")}
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        size={"sm"}
                        className="w-fit"
                        onClick={handleCrop}
                    >
                        <CropIcon className="mr-1.5 size-4" />
                        {t("Crop")}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
): Crop {
    if (mediaWidth === 0 || mediaHeight === 0) {
        console.warn("Invalid image dimensions: width or height is 0.");
        return {
            unit: "%",
            width: 50,
            height: 50,
            x: 0,
            y: 0,
        };
    }

    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 50,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}
