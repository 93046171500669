import { useAuth } from "@/hooks/login/useAuth";
import { useFetchBuilderAccountDetails } from "@/hooks/useFetchBuilderAccountDetails";
import { convertHexToCssHsl } from "@/lib/convertHexToCssHsl";
import { useEffect, useState } from "react";
import { useFetchPrimaryUserProperty } from "./useFetchPrimaryUserProperty";

interface Builder {
    builderName: string;
    logo: string;
}

export const useSetupColors = () => {
    const { user, isCustomer } = useAuth();
    const [builder, setBuilder] = useState<Builder | undefined>(undefined);

    let builderId = undefined;

    if (!isCustomer && user.builder_accounts[0]) {
        builderId = user.builder_accounts[0].id;
    }

    const { data: primaryUserPropertyDetails } =
        useFetchPrimaryUserProperty(isCustomer);

    if (isCustomer) {
        builderId = primaryUserPropertyDetails?.builderAccountId;
    }

    const { data: builderData, isLoading } = useFetchBuilderAccountDetails({
        builderId: builderId,
    });

    useEffect(() => {
        const account = builderData?.accounts[0];
        const brandingInfo = account?.branding_info;

        if (!brandingInfo) {
            return;
        }

        const root = document.documentElement;

        if (brandingInfo.primary) {
            const primary = convertHexToCssHsl(brandingInfo.primary, true);
            root.style.setProperty("--primary", primary);
            root.style.setProperty("--muted", primary);
        }

        if (brandingInfo.secondary) {
            const secondary = convertHexToCssHsl(brandingInfo.secondary, true);
            root.style.setProperty("--secondary", secondary);
        }

        const logo = (isCustomer ? account.logo : brandingInfo.web_logo) ?? "";

        setBuilder({
            builderName: account.builder_name,
            logo: logo,
        });
    }, [builderData]);

    return { isLoading, builder };
};
