import { z } from "zod";
import { BrandingInfoSchema } from "../BrandingInfoSchema";
import { CompanySchema } from "../company";

export const BuilderAccountSchema = z.object({
    active: z.boolean().default(true),
    alias_account_credits: z.string(),
    builder_name: z.string(),
    care_email: z.string().nullable(),
    care_name: z.string().nullable(),
    care_phone: z.string().nullable(),
    care_script: z.string().nullable(),
    company_id: z.coerce.number(),
    company: CompanySchema,
    customer_care_url: z.string().optional(),
    facebook_review_link: z.string().optional(),
    google_review_link: z.string().optional(),
    id: z.number(),
    logo: z.string().url().optional(),
    modified_at: z.string().nullable(),
    modified_by: z.number().optional(),
    notes: z.string().nullable(),
    branding_info: BrandingInfoSchema.optional(),
    property_vertical_id: z.coerce.number(),
    referral_description: z.string().nullable(),
    referral_script: z.string().nullable(),
    updated_at: z.string().nullable(),
    user_id: z.number(),
});

export type BuilderAccountType = z.infer<typeof BuilderAccountSchema>;

export const builderAccountArraySchema = z.array(BuilderAccountSchema);

export type BuilderAccountArraySchemaType = z.infer<
    typeof builderAccountArraySchema
>;

export const BuilderAccountResponseSchema = z.object({
    total_items: z.number(),
    accounts: builderAccountArraySchema,
});
export type BuilderAccountResponseType = z.infer<
    typeof BuilderAccountResponseSchema
>;
