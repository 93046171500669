import { BuilderAccountDetailsType } from "@/schemas/builders/builderAccountDetailsSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

interface FetchBuilderAccountDetailsProps {
    sort_order?: string;
    sort_by?: string;
    builder_account_id: number;
}

export const useFetchBuilderAccountDetails = ({
    builder_account_id,
}: FetchBuilderAccountDetailsProps) => {
    const [searchParams] = useSearchParams();
    const perPage = Number(searchParams.get("per_page")) || 10;
    const page = Number(searchParams.get("page")) || 1;
    return useQuery({
        queryKey: ["builderAccountDetails", builder_account_id],
        queryFn: async () => {
            const { data } = await api.get<BuilderAccountDetailsType>(
                "v1/builder_accounts/builder_account_details",
                {
                    params: {
                        builder_account_id,
                    },
                }
            );
            return data;
        },
        select(data) {
            const combinedUsers = data
                ? [data.user.primary_user, ...data.user.additional_users]
                : [];
            const totalItems = combinedUsers.length;
            const paginatedUsers = combinedUsers.slice(page - 1, perPage);
            return { paginatedUsers, totalItems: totalItems };
        },
    });
};
