import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface UpdateStaffRestrictionBody {
    hc_id: string | number;
}

export const useUpdateStaffRestriction = () => {
    return useMutation({
        mutationFn: async (body: UpdateStaffRestrictionBody) => {
            const { data } = await api.put("v1/users/update_restriction", body);
            return data;
        },
        retry: false,
    });
};
