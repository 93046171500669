import { queryClient } from "@/lib/queryClient";
import { Resource } from "@/schemas/resourceSchema";
import { nestApi } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../login/useAuth";
import {
    CreateResourceCategories,
    ResourceCategoriesSchema,
} from "./ResourceCategorySchema";

export const useCreateResourceCategory = () => {
    const { user } = useAuth();
    return useMutation({
        mutationFn: async (body: CreateResourceCategories) => {
            const { data } = await nestApi.post<ResourceCategoriesSchema>(
                "resource-categories",
                {
                    ...body,
                }
            );
            await queryClient.cancelQueries({
                queryKey: ["builderResourceFolders", body],
            });

            const updatedResource: Resource = {
                id: data.id,
                category_name: data.categoryName ?? "",
            };

            queryClient.setQueryData(
                ["builderResourceFolders", user.builder_accounts[0].id],
                (oldResources: Resource[] = []) => [
                    ...oldResources,
                    updatedResource,
                ]
            );

            return data;
        },
    });
};
