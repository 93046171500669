import type { BuildersArraySchemaType } from "@/schemas";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchBuilderProjects = (builder_account_id?: number) => {
    return useQuery({
        queryKey: ["builderProjects"],
        queryFn: async () => {
            const { data } = await api.get<BuildersArraySchemaType>(
                "v1/projects/active_projects",
                {
                    params: {
                        builder_account_id,
                    },
                }
            );
            return data;
        },
    });
};
