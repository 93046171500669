import { HomeIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export const customerMenuList = (pathname: string) => {
    const { t } = useTranslation("menu");

    return [
        {
            groupLabel: "",
            menus: [
                {
                    href: "/",
                    label: t("Menu.Home"),
                    active: pathname === "/",
                    icon: HomeIcon,
                    submenus: [],
                },
            ],
        },
    ];
};
