interface ContentLayoutProps {
    title?: string;
    className?: string;
    subTitle?: string;
    children: React.ReactNode;
}

export function ContentLayout({
    children,
    title,
    subTitle,
}: ContentLayoutProps) {
    return (
        <div className="w-full p-8">
            <div className="flex flex-col gap-1 mb-4">
                {title && (
                    <h1 className="text-3xl font-semibold text-gray-text/2">
                        {title}
                    </h1>
                )}
                {subTitle && (
                    <h2 className="text-sm text-muted-foreground">
                        {subTitle}
                    </h2>
                )}
            </div>
            {children}
        </div>
    );
}
