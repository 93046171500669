import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDeleteBuilderResource } from "@/hooks/builder/useDeleteBuilderResource";
import { VirtuoLibraryResourcesSchema } from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Edit2, Eye, Loader, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTableColumnHeader } from "../../dataTable/DataTableColumnHeader";
export function getResourceTableColumns(): ColumnDef<VirtuoLibraryResourcesSchema>[] {
    const { t } = useTranslation("resource");
    const { t: gt } = useTranslation("general");
    const { mutateAsync, isPending } = useDeleteBuilderResource();
    const navigate = useNavigate();

    return [
        {
            accessorKey: "title",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Title")} />
            ),
            cell: ({ row }) => (
                <span className="line-clamp-1  text-ellipsis   overflow-hidden max-w-60">
                    {row.getValue("title")}
                </span>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: "projectCommunity",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Community")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className=" line-clamp-1  text-ellipsis   overflow-hidden max-w-60  ">
                        {row.original.projectCommunity
                            ?.map((community) => community.community)
                            .join(", ")}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
            filterFn: (row, _, value) => {
                const searchValue = value[0]?.toLowerCase().trim();
                const communities = row.original.projectCommunity || [];

                return communities.some((community) =>
                    community.community
                        ?.toLowerCase()
                        .trim()
                        .includes(searchValue)
                );
            },
        },
        {
            accessorKey: "resourceCategories",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("Folder")} />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex">
                        {row.original.resourceCategories?.categoryName}
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
            filterFn: (row, _, value) => {
                const categoryName =
                    row.original.resourceCategories?.categoryName || "";
                return categoryName
                    .toLowerCase()
                    .trim()
                    .includes(value[0].toLowerCase().trim());
            },
        },
        {
            accessorKey: "createdAt",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={t("CreatedAt")} />
            ),
            cell: ({ row }) => (
                <div className="flex">
                    {format(row.original.createdAt, "LLL d, yyyy")}
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "expiryDate",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={t("ExpiryDate")}
                />
            ),
            cell: ({ row }) => (
                <div className="flex">
                    {row.original.expiryDate
                        ? format(row.original.expiryDate, "LLL d, yyyy")
                        : ""}
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Actions" />
            ),
            cell: ({ row }) => (
                <div className="flex flex-row gap-2 justify-evenly items-center w-12">
                    <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                            <a
                                href={row.original.resourceUrl}
                                target="_blank"
                                className="w-min h-min"
                            >
                                <Eye size={18} className="cursor-pointer" />
                            </a>
                        </TooltipTrigger>
                        <TooltipContent>{gt("View")}</TooltipContent>
                    </Tooltip>
                    <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                            <Edit2
                                size={18}
                                className="cursor-pointer "
                                onClick={() =>
                                    navigate(`edit/${row.original.id}`)
                                }
                            />
                        </TooltipTrigger>
                        <TooltipContent>{gt("Edit")}</TooltipContent>
                    </Tooltip>

                    <AlertDialog>
                        <AlertDialogTrigger className="flex items-center">
                            <Tooltip delayDuration={0}>
                                <TooltipTrigger
                                    className="flex items-center"
                                    asChild
                                >
                                    <Trash2
                                        size={18}
                                        className="cursor-pointer stroke-red"
                                    />
                                </TooltipTrigger>
                                <TooltipContent>{gt("Delete")}</TooltipContent>
                            </Tooltip>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle className="flex flex-row gap-1 items-center flex-wrap leading-[20px] mb-3">
                                    {t("PermDeleteResource")}
                                    <span className="text-primary self-end">
                                        {row.original.title}
                                    </span>
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                    {t("DeleteResourceDesc")}
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>
                                    {gt("Cancel")}
                                </AlertDialogCancel>
                                <AlertDialogAction
                                    onClick={() => mutateAsync(row.original.id)}
                                >
                                    {isPending ? (
                                        <Loader className="animate-spin h-5 w-5" />
                                    ) : (
                                        gt("Delete")
                                    )}
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
    ];
}
