import { AppUserSpecs } from "@/constants/appUserSpec";
import { ErrorPage, LoginPage, NotAuthorizedPage } from "@/pages";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../hooks/login/useAuth";

export const Routes = () => {
    const { token, appUserType } = useAuth();

    const privateRoutes =
        token && appUserType ? AppUserSpecs[appUserType].routesFunction() : [];

    const routesForPublic = [
        {
            path: "/",
            element: <LoginPage />,
        },
        {
            path: "/not-authorized",
            element: <NotAuthorizedPage />,
            errorElement: <ErrorPage />,
        },
    ];

    const router = createBrowserRouter([...routesForPublic, ...privateRoutes]);

    return (
        <RouterProvider
            future={{
                v7_startTransition: true,
            }}
            router={router}
        />
    );
};
