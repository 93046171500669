import { AppUserType } from "@/constants/appUserSpec";
import { getAppUserTypeInfo } from "@/helpers/getAppUserType";
import { LoginResponseSchema } from "@/schemas";
import React, { createContext, useEffect, useMemo, useState } from "react";

export interface AuthContextType {
    token: string | null;
    setToken: (newToken: string) => void;
    user: LoginResponseSchema;
    setUser: (user: LoginResponseSchema) => void;
    handleLogout: () => void;
    isStaff: boolean;
    isCustomer: boolean;
    appUserType: AppUserType | null;
}

export const defaultUser: LoginResponseSchema = {
    id: -1,
    alias_name: "",
    avatar: "",
    first_name: "",
    email: "",
    roles: [],
    authentication_token: "",
    fullname: "",
    last_name: "",
    builder_accounts: [],
    associated_builder_accounts: [],
};

export const AuthContext = createContext<AuthContextType>({
    token: null,
    setToken: (token: string) => token,
    user: defaultUser,
    setUser: (user: LoginResponseSchema) => user,
    isStaff: false,
    isCustomer: false,
    appUserType: null,
    handleLogout: () => {
        return;
    },
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken_] = useState<string | null>(
        localStorage.getItem("token")
    );

    const [user, setUser_] = useState<LoginResponseSchema>(() => {
        const storedUser = localStorage.getItem("user");
        return storedUser
            ? (JSON.parse(storedUser) as LoginResponseSchema)
            : (defaultUser as LoginResponseSchema);
    });

    const handleLogout = () => {
        setToken_(null);
        setUser_(defaultUser);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    };

    const setUser = (user: LoginResponseSchema) => {
        setUser_(user);
        localStorage.setItem("user", JSON.stringify(user));
    };

    const { appUserType, isStaff, isCustomer } = getAppUserTypeInfo(user);

    const setToken = (newToken: string) => {
        setToken_(newToken);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
        }
    }, [token]);

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            user,
            isStaff,
            isCustomer,
            appUserType,
            setUser,
            handleLogout,
        }),
        [token, user]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
