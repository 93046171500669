import { queryClient } from "@/lib/queryClient";
import {
    CreateVirtuoLibraryResources,
    VirtuoLibraryResourcesSchema,
} from "@/schemas/VirtuoLibraryResourceLocationsSchema";
import { nestApi } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
export const useUpdateResource = () => {
    return useMutation({
        mutationFn: async ({
            id,
            body,
        }: { id: number; body: CreateVirtuoLibraryResources }) => {
            const { files, ...rest } = body;
            const formData = new FormData();
            if (files) {
                formData.append("file", files[0]);
            }

            formData.append("data", JSON.stringify(rest));
            const { data } = await nestApi.put<VirtuoLibraryResourcesSchema>(
                `resources/${id}`,
                formData
            );
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["resources"],
            });
        },
    });
};
