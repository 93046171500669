import { BroadcastFormSchemaType } from "@/schemas/builders/broadcastFormSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchBroadcastByIdProps {
    id: number;
}

export const useFetchBroadcastById = ({ id }: FetchBroadcastByIdProps) => {
    return useQuery({
        queryKey: ["broadcast", id],
        queryFn: async () => {
            const { data } = await api.get<BroadcastFormSchemaType>(
                "v1/broadcasts/broadcast_detail",
                {
                    params: {
                        broadcast_id: id,
                    },
                }
            );
            return data;
        },
    });
};
