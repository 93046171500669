import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";

interface CareDetails {
    builder_account_id: number;
    care_name: string;
    care_email: string;
    care_phone: string;
}

//TODO: remove care_script from api submission options, variable no longer in use (2024-07-19). Ticket #6759

export const useUpdateCareDetails = () => {
    return useMutation({
        mutationFn: async (body: CareDetails) => {
            const { data } = await api.post(
                "v1/builder_accounts/add_care_details",
                body
            );
            return data;
        },
        retry: false,
    });
};
