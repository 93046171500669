import z from "zod";
export const buyerTypeSchema = z.enum([
    "firstTime",
    "moveDown",
    "moveUp",
    "renter",
    "investment",
    "senior",
    "other",
]);

export type buyerTypeSchema = z.infer<typeof buyerTypeSchema>;
