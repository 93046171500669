import { staffRolesList } from "@/constants/general";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchStaffParams {
    per_page: string | number;
    page: string | number;
    search?: string;
    role?: string;
}
export interface WorkLocation {
    street_number: string;
    route: string;
    city: string;
    administrative_area_level_1: string;
    country: string;
}

export type Staff = {
    //diferent from StaffUser for data display
    id: number;
    first_name: string;
    last_name: string;
    staff_details: {
        work_locations: WorkLocation[];
    };
    include_in_rotation: boolean;
    include_in_rotation_date: string;
    include_in_me_rotation: boolean;
    include_in_me_rotation_date: string;
    me_capacity: number;
    is_restricted: boolean;
    locations?: string;
    roles: string[];
    role_string?: string;
};

const formatData = (data: Staff[]) => {
    for (const staff of data) {
        if (
            staff.staff_details?.work_locations &&
            staff.staff_details.work_locations.length > 0
        ) {
            staff.locations = staff.staff_details.work_locations
                .map((location) => {
                    return `${location.street_number} ${location.route}, ${location.city}, ${location.administrative_area_level_1}, ${location.country}`;
                })
                .join(" | ");
        }
        if (staff && staff.roles.length > 0) {
            staff.role_string = staff.roles
                .map((role) => {
                    return staffRolesList.find(
                        (staffRole) => staffRole.value === role
                    )?.key;
                })
                .join(", ");
        }
    }
    return data.map((staff) => ({
        ...staff,
        name: staff.first_name + " " + staff.last_name,
        locations: staff.locations,
        isMe: staff.roles.includes("move_expert"),
        isCsr: staff.roles.includes("csr"),
    }));
};

// TODO: Ticket #6568 refine query
export const useFetchStaff = (params: FetchStaffParams) => {
    if (params.role === undefined || params.role === "") {
        delete params.role;
    }
    return useQuery({
        queryKey: ["staff", params],
        queryFn: async () => {
            const { data } = await api.get("v2/staff", { params });
            const formattedData = formatData(data.users);

            return { data: formattedData, total: Number(data.total_items) };
        },
        retry: false,
    });
};
