import { CampaignSchemaType } from "@/schemas/builders/campaignSchema";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

interface FetchCampaignByIdProps {
    id: number;
}

export const useFetchCampaignById = ({ id }: FetchCampaignByIdProps) => {
    return useQuery({
        queryKey: ["campaign", id],
        queryFn: async () => {
            const { data } = await api.get<CampaignSchemaType>(
                "v1/campaigns/campaign_detail",
                {
                    params: {
                        campaign_id: id,
                    },
                }
            );
            return data;
        },
    });
};
