import { AddressSchemaType } from "@/schemas/addressSchema";
import { StaffUserSchemaType } from "@/schemas/staff/staffUserSchema";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "../utils/useToast";

interface UpdateStaffParams {
    body: {
        user: StaffUserSchemaType;
        staff_details: {
            work_locations: AddressSchemaType[];
        };
    };
    id: string | number;
}

export const useUpdateStaff = () => {
    const { toast } = useToast();
    const { t } = useTranslation("hooks");

    return useMutation({
        mutationFn: async ({ body, id }: UpdateStaffParams) => {
            const { data } = await api.put(`v1/users/${id}/staff`, body);
            return data;
        },
        onSuccess() {
            toast({ title: t("Success.Update.Staff"), variant: "success" });
        },
        onError(error) {
            toast({
                title: t("Error.Update.Staff"),
                description: error.message,
                variant: "destructive",
            });
        },
        retry: false,
    });
};
