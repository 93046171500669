import type { DataTableFilterField } from "@/@types/tableTypes";
import { DataTable } from "@/components/dataTable/DataTable";
import { DataTableSkeleton } from "@/components/dataTable/DataTableSkeleton";
import { DataTableToolbar } from "@/components/dataTable/DataTableToolbar";
import { Button } from "@/components/ui/button";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useBuilderClientSearchParams } from "@/hooks/builder/useBuilderClientSearchParams";
import { useEditKeyDates } from "@/hooks/builder/useEditKeyDates.ts";
import { UseFetchBuilderClients as useFetchBuilderClients } from "@/hooks/builder/useFetchBuilderClients";
import { useAuth } from "@/hooks/login/useAuth";
import { useDataTable } from "@/hooks/utils/useDataTable";
import { BuilderClientType } from "@/schemas/builders/builderClientSchema";
import { Archive, CirclePlus, UserCheck, X } from "lucide-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddNewFileModal } from "../../AddNewFileModal";
import { getPropertyTableColumns } from "./PropertyTableColumns";
import { EditProperty } from "./components/EditProperty";
import { FileCabinet } from "./components/FileCabinet";

export const PropertyTable = () => {
    const { page, perPage, search, sortBy } = useBuilderClientSearchParams();
    const { t: gt } = useTranslation("general");
    const { t: ft } = useTranslation("file");
    const { t: pt } = useTranslation("property");
    const { user } = useAuth();
    const [tab, setTab] = useState("property");
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProperty, setSelectedProperty] =
        useState<BuilderClientType>();
    const navigate = useNavigate();
    const { mutateAsync } = useEditKeyDates();

    const [sort_by_type, sort_order] = useMemo(
        () => sortBy?.split(".") || ["initiation_date", "desc"],
        [sortBy]
    );

    const { data, refetch, isLoading } = useFetchBuilderClients({
        id: user.builder_accounts[0].id,
        page: page,
        per_page: perPage,
        search: search,
        sort_order: sort_order,
        sort_by:
            sort_by_type === "fullname"
                ? "first_name"
                : sort_by_type === "key_dates_possession_date"
                  ? "possession_date"
                  : sort_by_type,
    });

    const filterFields: DataTableFilterField<BuilderClientType>[] = [
        {
            label: gt("Search.Search"),
            tooltip: pt("SearchTooltip"),
            value: "fullname",
            placeholder: ft("Search"),
        },
    ];
    const columns = getPropertyTableColumns({
        handleOpenDrawer(tab, property) {
            setSelectedProperty(property);
            setTab(tab);
            setOpen(true);
        },
        async handleChangePossessionDate(val, client) {
            await mutateAsync({
                user_property_id: client.id,
                possession_date: val ?? null,
            });
            await refetch();
        },
    });
    const { table } = useDataTable({
        data: data?.clients ?? [],
        columns,
        totalItems: data?.total_items ?? 0,
        defaultPerPage: 10,
        filterFields,
    });
    const handleClose = async () => {
        await setOpen(false);
    };
    const handleUpdate = async () => {
        await refetch();
    }

    return (
        <div className="space-y-5 overflow-hidden">
            <DataTableToolbar table={table} filterFields={filterFields}>
                <Button
                    className="flex flex-row gap-1 "
                    onClick={() => {
                        navigate(
                            `create-property/builder/${user.builder_accounts[0].id}`
                        );
                    }}
                >
                    <CirclePlus color="white" size={18} />
                    {pt("CreateClient")}
                </Button>
            </DataTableToolbar>
            {isLoading ? (
                <DataTableSkeleton
                    columnCount={8}
                    cellWidths={[
                        "11rem",
                        "8rem",
                        "16rem",
                        "5rem",
                        "5rem",
                        "5rem",
                        "4rem",
                        "2rem",
                    ]}
                    shrinkZero
                />
            ) : (
                <Drawer
                    setBackgroundColorOnScale={false}
                    open={open}
                    dismissible={false}
                    onOpenChange={setOpen}
                >
                    <DataTable table={table} />
                    <DrawerContent
                        hidden
                        className="h-screen top-3 right-0 left-auto mt-0 w-screen rounded-none "
                    >
                        <DrawerHeader className="px-10">
                            <div>
                                <DrawerTitle className="font-semibold text-2xl">{`Customer Details: ${selectedProperty?.fullname}`}</DrawerTitle>
                                <DrawerDescription className="text-gray-500">
                                    Description the feature and or provide
                                    helpful instructions.
                                </DrawerDescription>
                            </div>

                            <Tooltip delayDuration={0}>
                                <TooltipTrigger asChild>
                                    <X
                                        className=" cursor-pointer size-7 absolute top-4 right-10 text-gray-500 hover:text-red-500 "
                                        onClick={() => setOpen(false)}
                                    />
                                </TooltipTrigger>
                                <TooltipContent
                                    align="start"
                                    className="w-[125px] text-center"
                                >
                                    {gt("Close")}
                                </TooltipContent>
                            </Tooltip>
                        </DrawerHeader>
                        <ScrollArea className="p-10">
                            <Tabs defaultValue={tab}>
                                <TabsList className="w-full justify-start py-3 h-12">
                                    <TabsTrigger
                                        className="h-9"
                                        value="property"
                                        defaultChecked
                                    >
                                        <UserCheck className="h-5 w-5 mr-3 " />
                                        Property
                                    </TabsTrigger>
                                    <TabsTrigger
                                        className="h-9"
                                        value="file-cabinet"
                                    >
                                        <Archive className="h-5 w-5 mr-3" />
                                        File Cabinet
                                    </TabsTrigger>
                                </TabsList>

                                <TabsContent value="property">
                                    {selectedProperty && (
                                        <EditProperty
                                            onUpdate={handleUpdate}
                                            onClose={handleClose}
                                            propertyId={selectedProperty.id}
                                            userId={selectedProperty.client_id}
                                        />
                                    )}
                                </TabsContent>
                                <TabsContent value="file-cabinet">
                                    {selectedProperty && (
                                        <FileCabinet
                                            setOpenModal={setOpenModal}
                                            userId={selectedProperty.client_id}
                                        />
                                    )}
                                </TabsContent>
                            </Tabs>
                        </ScrollArea>
                    </DrawerContent>
                    {selectedProperty && (
                        <AddNewFileModal
                            open={openModal}
                            setIsOpen={setOpenModal}
                            propertyId={Number(selectedProperty.id)}
                            userId={Number(selectedProperty.client_id)}
                        />
                    )}
                </Drawer>
            )}
        </div>
    );
};
