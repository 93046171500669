import {
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { LoginResponseSchema } from "@/schemas";
import { LogOut, User } from "lucide-react";
import { startTransition } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavigateFunction } from "react-router-dom";

interface UserNavDropdownProps {
    user: LoginResponseSchema;
    isCustomer?: boolean;
    navigate: NavigateFunction;
    handleLogout: () => void;
}

export function UserNavDropdown(props: UserNavDropdownProps) {
    const { t } = useTranslation("general");

    return (
        <DropdownMenuContent className="w-56" align="end" forceMount>
            <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">
                        {props.user?.fullname}
                    </p>
                    <p className="text-xs leading-none text-muted-foreground">
                        {props.user?.email}
                    </p>
                </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {!props.isCustomer && (
                <>
                    <DropdownMenuGroup>
                        <DropdownMenuItem
                            className="hover:cursor-pointer"
                            asChild
                        >
                            <Link to="/profile" className="flex items-center">
                                <User className="w-4 h-4 mr-3 text-muted-foreground" />
                                {t("Navbar.MyProfile")}
                            </Link>
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                </>
            )}
            <DropdownMenuItem
                onClick={() => {
                    startTransition(() => {
                        props.handleLogout();
                        props.navigate("/");
                    });
                }}
                className="hover:cursor-pointer"
            >
                <LogOut className="w-4 h-4 mr-3 text-muted-foreground" />
                {t("Login.Logout")}
            </DropdownMenuItem>
        </DropdownMenuContent>
    );
}
