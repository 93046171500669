import { CompanySchemaType } from "@/schemas";
import { api } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

export const useFetchCompanies = () => {
    return useQuery({
        queryKey: ["companies"],
        queryFn: async () => {
            const { data } = await api.get<CompanySchemaType[]>("v1/companies");

            return data;
        },
    });
};
