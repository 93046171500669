export const convertHexToCssHsl = (hex: string, valuesOnly = false): string => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) throw new Error("Failed to parse hex");

    let r = Number.parseInt(result[1], 16);
    let g = Number.parseInt(result[2], 16);
    let b = Number.parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 1,
        s,
        l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    h = Number.parseFloat((h * 360).toFixed(1));
    s = Number.parseFloat((s * 100).toFixed(1));
    l = Number.parseFloat((l * 100).toFixed(1));

    let cssString = `${h} ${s}% ${l}%`;
    if (!valuesOnly) {
        cssString = `hsl(${cssString})`;
    }

    return cssString;
};
