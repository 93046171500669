import { PhoneInput } from "@/components/PhoneInput";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { BuilderCreateAccountSchemaType } from "@/schemas/builders/builderCreateAccountSchema";
import { phoneNumberType } from "@/schemas/contactSchema";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const ContactSection = () => {
    const { control, setValue, getValues, register } =
        useFormContext<BuilderCreateAccountSchemaType>();
    const { t } = useTranslation("account");
    const { t: ut } = useTranslation("user");

    return (
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-5">
            <FormField
                control={control}
                name="user.first_name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>{ut("FirstName")}</FormLabel>
                        <FormControl>
                            <Input placeholder={ut("FirstName")} {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={control}
                name="user.last_name"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>{ut("LastName")}</FormLabel>
                        <FormControl>
                            <Input placeholder={ut("LastName")} {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={control}
                name="user.email"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>{ut("Email")}</FormLabel>
                        <FormControl>
                            <Input placeholder={ut("Email")} {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                name="user.phone_number"
                render={() => (
                    <FormItem className="col-span-1">
                        <FormLabel>{ut("Phone")}</FormLabel>
                        <FormControl>
                            <div className="flex items-center gap-2">
                                <PhoneInput
                                    className="w-full"
                                    value={getValues("user.phone_number")}
                                    placeholder="8555692582"
                                    defaultCountry="CA"
                                    onChange={({ nativeEvent }) => {
                                        const target =
                                            nativeEvent.target as HTMLInputElement;
                                        setValue(
                                            "billing_contact.phone_number",
                                            target.value
                                        );
                                    }}
                                />

                                <Select
                                    defaultValue="Mobile"
                                    onValueChange={(value: phoneNumberType) =>
                                        setValue("user.number_type", value)
                                    }
                                >
                                    <SelectTrigger className="max-w-24">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="Mobile">
                                            {t("PhoneNumberType.Mobile")}
                                        </SelectItem>
                                        <SelectItem value="Home">
                                            {t("PhoneNumberType.Home")}
                                        </SelectItem>
                                        <SelectItem value="Office">
                                            {t("PhoneNumberType.Office")}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={control}
                name="user.additional_emails"
                render={() => (
                    <FormItem className="col-span-2">
                        <FormLabel>{t("AdditionalEmails")}</FormLabel>
                        <FormControl>
                            <Input
                                placeholder={t("AdditionalEmails")}
                                {...register(`user.additional_emails.${0}`)}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    );
};
